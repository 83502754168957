<template>
  <div v-if="value" class="p-2 pre-container rounded">
    <div class="expand-block d-flex flex-row align-items-baseline"
         @click="toggle"
         data-toggle="collapse"
         data-target="#statInqReq">
      <i :class="`mr-2 fa ${icon}`"/>
      <div class="expand-text">
        Read default branch privacy policy
        <template v-if="language">
          ({{language}})
        </template>
      </div>
    </div>

    <div id="statInqReq" class="ml-3 mt-2 w-100 collapse" ref="container">
      <p class="mb-1"><strong>Slug:</strong> {{value.slug}}</p>
      <p class="mb-1"><strong>Title:</strong> {{value.title}}</p>
      <p class="mb-1"><strong>Content:</strong></p>
      <div class="p-2 border content d-block mr-3" v-html="value.html"/>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    language: {
      required: false,
      type: String,
    },
    value: {
      required: true,
      type: [Object, undefined, null],
    }
  },
  data() {
    return {
      icon: 'fa-plus'
    }
  },
  methods: {
    toggle() {
      this.icon =  this.icon === 'fa-plus' ? 'fa-minus' : 'fa-plus';
    },
  }
}
</script>

<style scoped lang="scss">
  .content {
    max-width: 100%;
    max-height: 400px;
    overflow: auto;
    word-break: break-word;
  }
</style>
