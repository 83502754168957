<template>
  <div :class="`form-group mr-0 ${flat ? 'mt-1' : 'row mr-0'}`">
    <div :class="`${flat ? 'mb-1' : 'col col-3 pt-2'}`">
      <label :for="params.id" class="mb-0 text-muted align-self-center"><span v-html="params.label"/><template v-if="params.required">*</template>: </label>
    </div>

    <div :class="`${flat ? 'w-100 d-flex flex-row mt-1' : 'col col-9 pr-0'}`">
      <div class="flex-grow-1">
        <template v-if="simpleField">
          <input :type="params.type"
                 :name="params.id"
                 :disabled="params.disabled"
                 :id="params.id"
                 :step="params.step"
                 :minlength="params.minLength"
                 :maxLength="params.maxLength"
                 :placeholder="params.placeholder"
                 v-model="model"
                 class="form-control"
                 @change="change"
                 :required="params.required"/>
        </template>
        <template v-else-if="params.type=== 'textarea'">
          <textarea :name="params.id"
                    :id="params.id"
                    :required="params.required"
                    :disabled="params.disabled"
                    :placeholder="params.placeholder"
                    cols="30"
                    rows="4"
                    class="form-control"
                    @change="change"
                    v-model="model"/>
        </template>
        <template v-else-if="params.type=== 'file'">
          <input type="file"
                 accept="image/*, application/pdf, application/rtf"
                 :name="params.id"
                 :id="params.id"
                 :required="params.required"
                 :disabled="params.disabled"
                 :placeholder="params.placeholder"
                 class="form-control"
                 @change="uploadFile"/>
        </template>
        <template v-else-if="params.type==='select'">
          <select
              :name="params.id"
              :id="params.id"
              class="form-control"
              :required="params.required"
              :disabled="params.disabled"
              :placeholder="params.placeholder"
              v-model="model"
              @change="change"
          >
            <option :value="undefined">- Any -</option>
            <option v-for="option in params.options"
                    :key="option"
                    :value="option">
              {{option}}
            </option>
          </select>
        </template>
        <template v-else>
          <em>Undefined field type</em>
        </template>
        <div v-if="params.hint" class="hint small text-muted">{{params.hint}}</div>
      </div>

      <slot name="append"/>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    value: {
      required: true,
      type: [String, Object, null]
    },
    params: {
      required: false,
      type: Object,
      default: () => ({
        id: 'field',
        disabled: false,
        label: 'Label',
        helper: '',
        type: 'text', // text, number, textarea, email, tel, etc
        placeholder: '',
        required: false,
      })
    },
    flat: {
      required: false,
      type: Boolean,
    }
  },

  data() {
    return {
      fileSizeLimit: 8, // Mb

      model: undefined,
      fileReader: null,
    }
  },

  computed: {
    simpleField() {
      return ['email', 'number', 'text', 'url'].includes(this.params.type);
    },
  },

  created() {
    if(this.params.type === 'file') {
      this.initFileReader();
    }
  },

  mounted() {
    if (this.value) {
      this.model = this.value;
    }
  },

  methods: {
    change() {
      this.$emit('change', this.model);
    },

    initFileReader() {
      this.fileReader = new FileReader();
    },

    isFileSizeLimitOk(file) {
      return file && (file.size <= this.fileSizeLimit * 1024 * 1024);
    },

    uploadFile(e) {
      const file = e.target.files[0];

      if (!this.isFileSizeLimitOk(file)) {
        this.$emit('error',`The file is too big. The max file size is ${this.fileSizeLimit}Mb`);
      } else {
        this.fileReader.onloadend = () => {
          this.model = file;
          this.$emit('change', file);
        };

        this.fileReader.onerror = error => {
          this.$emit('error', 'Unable read attached file');
        };

        this.fileReader.readAsBinaryString(file);
      }
    },
  },

  watch: {
    value(v, old) {
      if (v !== old) {
        this.model = v;
      }
    }
  }
}
</script>

<style scoped>
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  input[type=number] {
    -moz-appearance: textfield;
  }
</style>
