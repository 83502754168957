const config = {
  tinySettings: {
    height: 400,
    menubar: false,
    skin: false,
    deprecation_warnings: false,
    content_style: '*, .mce-content-body * {font-family: "Open Sans", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", sans-serif;} code { font-size: 87.5%; color: rgb(232, 62, 140); overflow-wrap: break-word; font-family: monospace; }',
    content_css: [
      '//fonts.googleapis.com/css?family=Montserrat:400,400i,700,700i|Open+Sans:400,400i,700,700i|Roboto:400,400i,700,700i'
    ],
    font_formats:  'Default=\'Open Sans\', -apple-system, BlinkMacSystemFont, \'Segoe UI\', Roboto, Oxygen, Ubuntu, Cantarell, \'Helvetica Neue\', sans-serif;' +
      'Arial=arial,helvetica,sans-serif;' +
      'Courier New=courier new,courier,monospace;' +
      'Georgia=georgia,palatino;' +
      'Montserrat=\'Montserrat\', sans-serif;' +
      'Open Sans=\'Open Sans\', sans-serif;' +
      'Roboto=\'Roboto\', roboto, sans-serif;' +
      'Tahoma=tahoma,arial,helvetica,sans-serif; ' +
      'Times New Roman=\'Times New Roman\', times new roman,times;',

    valid_classes: 'js-click, js-popup-toggle, t1, t2, h1, h2, h3, h4, h5, h6, spa-main, secondary-content, secondary-content__boxes, list-without-bullet, boxes, box, left-box, cabelas, bonus-code-text, card-front-section, code-section',
    plugins: [
      'autolink lists link textcolor',
      'fullscreen',
      'table paste code help wordcount'
    ],
    toolbar: 'undo redo  | ' +
      ' removeformat | formatselect | fontselect | fontsizeselect | bold italic forecolor backcolor |' +
      ' alignleft aligncenter alignright alignjustify |' +
      ' bullist numlist outdent indent |' +
      ' link table |' +
      ' code fullscreen help',
    convert_urls: false,
    relative_urls: false,
    urlconverter_callback: function(url) {
      return url.replace(/(\.\.)?\/\.\./gi, '');
    }
  }
};

export default config;
