<template>

  <div class="dashboard-modal modal show">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header d-flex justify-content-between">
          <h4 class="mb-0">Project Report</h4>
          <button type="button" class="close" data-dismiss="alert" aria-label="Close" @click="hideModal">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <div class="col">
            <div class="row">
              <h5>Project</h5>
              <select class="custom-select" v-model="projectId" @change="changeProject">
                <option v-for="project in projects" :value="project.id">{{project.name}}</option>
              </select>
            </div>
            <div class="row mt-4" v-show="projectId != null">
              <h5>Promotions</h5>
              <label class="ml-3" style="font-size: 18px; line-height: 1.2;"><input class="mr-1" type="checkbox" @change="toggleAll" :checked="allChecked"/>All</label>
              <select multiple data-width="100%" @change="setSelectedPromotions" data-live-search="true">
                <option v-for="promotion in promotions">
                  {{promotion}}
                </option>
              </select>
            </div>
            <div v-if="showDatesFilter">
              <h5 class="row mt-4">Promotions' Activation Start Date Range</h5>
              <div class="row">
                <div class="col-md-6 pl-0">
                  <h7 class="mb-1">Start Date</h7>
                  <datepicker v-model="startDate" @input="startDateChange" :disabledDates="disabledStartDates" input-class="form-control date-picker-input"></datepicker>
                </div>
                <div class="col-md-6 pr-0">
                  <h7 class="mb-1">End Date</h7>
                  <datepicker v-model="endDate" @input="endDateChange" :disabledDates="disabledEndDates" input-class="form-control date-picker-input"></datepicker>
                </div>
              </div>
            </div>
          </div>
          <br>
          <h5>Report Columns</h5>
          <div class="row">
            <div v-for="column in columns" class="column col-sm-12 col-md-4"><span><input class="report-column" type="checkbox" checked="true" :value="column" @change="columnSelectionChange" /> {{column}}</span></div>
          </div>
        </div>
        <div class="modal-footer">
          <button :disabled="isDownloadButtonDisabled" class="btn btn-success download" @click="downloadReport">Download</button>
        </div>
      </div>
    </div>
  </div>

</template>

<script>
import axios from "axios";
import Datepicker from 'vuejs-datepicker';

export default {
  name: "reportModal",
  components: {
    Datepicker
  },
  data: function () {
    return {
      downloadButtonDisabled: false,
      projects: [],
      promotionsByProject: [],
      promotions: [],
      selectedPromotions: [],
      projectId: null,
      startDate: new Date(new Date().getFullYear(), 0, 1), // First day of the current year
      endDate: new Date(),
      disabledStartDates: {
        from: new Date()
      },
      disabledEndDates: {
        to: new Date(new Date().getFullYear(), 0, 1)
      },
      columns: [
        'Slug',
        'Description',
        'Merchants',
        'Branch',
        'Sponsoring Partners',
        'Bonus Partners',
        'Redemptions',
        'Failure',
        'Start',
        'End',
        'Claim',
        'Value',
        'Currency'
      ]
    }
  },
  mounted: function() {
    this.loadProjectsAndPromotions();
    $('select[multiple]').selectpicker();
  },
  computed: {
    isDownloadButtonDisabled: function() {
      return this.downloadButtonDisabled;
    },

    allChecked: function() {
      $('button[class="btn dropdown-toggle btn-outline-secondary"]').removeAttr('title');

      return this.selectedPromotions.length == this.promotions.length;
    },

    showDatesFilter: function() {
      return this.projectId == null || (this.projectId != null && this.selectedPromotions.length == this.promotions.length)
    }
  },
  methods: {
    changeProject: function() {
      this.downloadButtonDisabled = false;

      if (this.projectId == null) {
        this.promotions = [];
      } else {
        let selectedProject = this.projects.find(project => {
          return project.id == this.projectId;
        });

        this.promotions = this.promotionsByProject[selectedProject.name];
      }

      this.selectedPromotions = this.promotions;

      setTimeout(() => {
        $('select[multiple]').selectpicker('refresh');
        $('select[multiple]').selectpicker('selectAll');
        $('button[class="btn dropdown-toggle btn-outline-secondary"]').removeAttr('title');
      }, 200);
    },

    setSelectedPromotions: function() {
      this.selectedPromotions = [];
      for (var i = 0; i < event.target.selectedOptions.length; i++) {
        this.selectedPromotions.push(event.target.selectedOptions[i].value);
      }
      if (this.selectedPromotions.length > 0)
        this.downloadButtonDisabled = false;
      else
        this.downloadButtonDisabled = true;
    },

    hideModal: function() {
      this.$emit('hideModal')
    },

    columnSelectionChange: function() {
      let button = $('button.download');
      if ($('input.report-column:checked').length <= 0)
        button.attr('disabled', true);
      else
        button.attr('disabled', false);
    },

    toggleAll: function() {
      if (event.target.checked)
        $('select[multiple]').selectpicker('selectAll');
      else
        $('select[multiple]').selectpicker('deselectAll');
    },

    downloadReport: function() {
      let reportUrl = window.location.href + '/project_report.json';
      let token = document.querySelector("meta[name='csrf-token']").content;
      let headers = {'X-CSRF-Token': token};
      let columns = $('input.report-column:checked').map((i, e) => { return e.value; }).toArray();
      let data = {
        project_id: this.projectId,
        start:      this.showDatesFilter ? this.startDate.toDateString() : null,
        end:        this.showDatesFilter ? this.endDate.toDateString() : null,
        columns:    columns,
        promotions: this.selectedPromotions
      };

      axios.post(reportUrl, data, {headers: headers}).then(response => {
        downloadFile(response.data.url);
        this.$emit('hideModal')
      });
    },

    loadProjectsAndPromotions: function() {
      let proejctsUrl = '/admin/projects.json';
      axios.get(proejctsUrl).then(response => {
        let projects = response.data.projects;
        // Add All project filter
        projects.unshift({id: null, name: "All"});
        this.projects = projects;
        this.projectId = this.projects[0].id;
      });

      let promotionsUrl = '/admin/projects/promotions.json';
      axios.get(promotionsUrl).then(response => {
        this.promotionsByProject = response.data;
      });
    },

    startDateChange: function() {
      this.disabledEndDates = {
        to: this.startDate
      }
    },

    endDateChange: function() {
      this.disabledStartDates = {
        from: this.endDate
      }
    }
  }
};
</script>

<style>
.date-picker-input:not(.bg-white) {
  background-color: initial !important;
}

.date-picker-input:hover{
  cursor: pointer;
}

.dashboard-modal .modal-dialog{
  max-width: 800px;
}
.dashboard-modal.modal.show {
  background-color: rgba(0, 0, 0, 0.8);
  overflow-y: scroll;
}
</style>
