<template>
  <div class="mb-4 pr-4">
    <h4>Choose Preset</h4>
    <div class="row col justify-content-between">
      <div class="col-md-10 pl-0">
        <v-select :options="presetsNames" v-model="selectedPreset"></v-select>
      </div>
      <button class="btn btn-sm btn-light mr-1" @click="resetStyles" v-if="currentPromotion !== null">Reset Styles
      </button>
    </div>
  </div>
</template>

<script>

  export default {
    name: 'presetsSelector',
    props: ['presets', 'initialPreset'],
    data: function () {
      return {
        selectedPreset: '',
        selectedProject: '',
        selectedPromo: '',
        presets: this.presets
      }
    },
    mounted: function () {
      this.selectedPreset = this.initialPreset
    },
    computed: {
      presetsNames: function () {
        let projectsItems = [];
        let promotionsItems = [];
        this.presets.forEach(preset => {
          preset.promotionSlug == null || !preset.promotionSlug.length ? projectsItems.push(preset.projectName) : promotionsItems.push(`${preset.promotionSlug}, project: ${preset.projectName}`)
        });
        return [...projectsItems, ...promotionsItems]
      },
      currentProject: function () {
        return this.selectedPreset.includes('project') ? this.selectedPreset.split(', project: ')[1] : this.selectedPreset
      },
      currentPromotion: function () {
        return this.selectedPreset.includes('project') ? this.selectedPreset.split(', project: ')[0] : null
      }
    },
    watch: {
      initialPreset: function () {
        this.selectedPreset = this.initialPreset
      },
      selectedPreset: function () {
        this.selectedProject = this.currentProject;
        this.selectedPromo = this.currentPromotion;
        let selectedPresetInfo = {
          currentProject: this.currentProject,
          currentPromotion: this.currentPromotion
        };

        this.$emit('setCurrentPresetInfo', selectedPresetInfo)
      }
    },
    methods: {
      resetStyles: function () {
        this.selectedPreset = this.currentProject;
        this.$forceUpdate();
        this.$emit('resetStyles');
      }
    }
  }
</script>

<style scoped>
  button.btn.btn-sm.btn-light.mr-1 {
    width: 15%;
  }
</style>
