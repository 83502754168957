<template>
  <div class="alert alert-danger alert_permanent mb-3" v-if="threshold.percentage">
    <div class="d-flex flex-row items-center">
      <div class="mr-2">
        <i class="fa fa-exclamation-triangle"></i>
      </div>
      <div class="flex-grow-1">
        <h6 class="mb-0 font-weight-bold">High percentage of errors alert {{ ratePercentage }}</h6>

        <template v-if="threshold.from && threshold.to">
          <p class="mb-0">{{ ratePercentage }} of errors during {{ getDate(threshold.from) }} to
            {{ getDate(threshold.to) }}</p>
        </template>
        <div v-if="threshold.errors.length" class="w-50 mt-3 mb-2">
          <promotion-errors
              :errors="threshold.errors"
              :promotionProjectId="null"
              :promotionSlug="null"
              :redemptionsTotal="null"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import PromotionErrors from "./promotionErrors.vue";

export default {
  components: {PromotionErrors},
  props: {
    threshold: {
      required: false,
      type: Object,
      default: () => ({}),
    },
  },

  data() {
    return {}
  },

  computed: {
    ratePercentage() {
      const {percentage} = this.threshold;
      return `${percentage}%`;
    },
  },

  methods: {
    getDate(date) {
      return `${this.$moment.utc(date).format('YYYY-MM-DD HH:mm')}UTC`;
    }
  }
}
</script>
