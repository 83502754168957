<template>
  <div class="col-lg-7" id="externalLink">
  </div>
</template>

<script>
  export default{
    name: 'externalLink',
    props: ['stepsObj', 'stepOptions', 'blockClass'],
    data: function() {
        return {
            stepName: 'External Link',
            newOptions: {}
        }
    },
    computed: {
        step: function() {
            if (this.blockClass.length > 0) {
                return {
                    blockClass: this.blockClass,
                    options: {}
                }
            }
        },
        initialStepData: function(){
          return this.stepsObj.filter(step => { return step.blockClass == this.blockClass })[0];
        }
    }
  }
</script>
