<template>
  <div class="col-lg-7" id="userForm">
    <div class="descContainer" v-if="stepName === 'User Form'">
      <div class="row field-row mb-2 align-items-center">
        <div class="col-lg-4 d-flex align-items-center justify-content-between">
          <div class="field-title">Main Fields</div>
          <i class="fa fa-arrow-left" @click="returnToMainFields"></i>
        </div>
        <div class="col-lg-8">
          <select data-width="100%" @change="setMain" class="mainf">
            <option v-for="fieldOption in mainOptionsWithoutPin"
                    :key="fieldOption"
                    :value="fieldOption">
              &nbsp;{{ fieldTitle(fieldOption) }}
            </option>
            <option class="pin-field">PIN</option>
          </select>
          <select data-width="100%" @change="setMain" class="selectpicker pinOptions"
                  data-none-selected-text="Choose PIN type">
            <option data-hidden="true"></option>
            <option v-for="fieldOption in pinOptions"
                    :key="fieldOption"
                    :value="fieldOption">
              {{fieldTitle(fieldOption)}}
            </option>
          </select>
        </div>
      </div>
      <div class="row field-row mb-2 align-items-center justify-content-end">
        <ul class="fields-list col-lg-8">
          <li class="main-field d-flex justify-content-between align-items-center mb-2"
              v-for="field in newOptions.mainFields" :key="field">
            {{ fieldTitle(field) }}
            <i class="fa fa-times" @click="removeField"></i>
          </li>
        </ul>
      </div>
      <div class="row field-row mb-2 align-items-center">
        <div class="col-lg-4">
          <div class="field-title">Additional Fields</div>
        </div>
        <div class="col-lg-8">
          <select data-width="100%" @change="setAdditional" class="addf" multiple>
            <option v-for="fieldOption in initialStepData.stepOptions.additionalFields" :key="fieldOption">
              {{fieldOption}}
            </option>
          </select>
        </div>
      </div>
      <template v-if="ddpSumBased">
        <div stepOptions.class="row field-row mb-2 align-items-center">
          <div class="col barcode-container field-title d-flex align-items-center">
            <input name="sumBased" id="sumBased" type="checkbox" v-model="newOptions.sumBased"/>
            <label for="sumBased" class="mb-0 ml-2">Sum-based SN fields</label>
          </div>
        </div>
      </template>
      <template v-if="ocrAvailable">
        <div stepOptions.class="row field-row mb-2 align-items-center">
          <div class="col barcode-container field-title d-flex align-items-center">
            <input name="ocr" id="ocr" type="checkbox" v-model="newOptions.ocr"/>
            <label for="ocr" class="mb-0 ml-2">Barcode recognition</label>
          </div>
        </div>

        <div v-if="newOptions.ocr" stepOptions.class="row field-row mb-2 align-items-center">
          <div class="col barcode-container field-title d-flex align-items-center">
            <input name="ocrFallback" id="ocrFallback" type="checkbox" v-model="newOptions.ocrFallback" :disabled="!newOptions.ocr"/>
            <label for="ocrFallback" class="mb-0 ml-2">OCR Fallback</label>
          </div>
          <div class="col barcode-container field-title d-flex align-items-center">
            <input name="sumBased" id="sumBased" type="checkbox" v-model="newOptions.sumBased"/>
            <label for="sumBased" class="mb-0 ml-2">Sum-based SN fields</label>
          </div>
        </div>
      </template>
    </div>
  </div>
</template>
<script>
  import {getLabel} from "./fieldsDescription";

  export default {
    name: 'userForm',
    props: ['stepsObj', 'stepOptions', 'blockClass', 'readOnly'],
    data() {
      return {
        showPinField: false,
        stepName: 'User Form',
        newOptions: {
          mainFields: [],
          additionalFields: [],
          ocr: false,
          ocrFallback: true,
          sumBased: false
        }
      }
    },
    mounted: function () {
      if (Object.keys(this.stepOptions).length > 0) {
        this.newOptions = {
          ...this.stepOptions,
          ocr: this.stepOptions.ocr || false,
          ocrFallback: this.stepOptions.hasOwnProperty('ocrFallback') ? this.stepOptions.ocrFallback : true,
        };
      }

      $(this.$el).find('select.mainf').selectpicker();
      $(this.$el).find('select.pinOptions').selectpicker();
      $('.pinOptions, i.fa-arrow-left').hide();
      $(this.$el).find('select.addf').selectpicker('val', this.newOptions.additionalFields);

      $('.pin-field').click(function (event) {
        event.preventDefault();
        event.stopPropagation();
        $('.mainf').hide();
        $('.pinOptions, i.fa-arrow-left').show();
        $('.pinOptions, .pinOptions .dropdown-menu').addClass('show');
      });
    },
    computed: {
      fieldTitle: () => (value) => {
        return getLabel(value);
      },

      additionalFields: function () {
        return this.newOptions.additionalFields
      },
      initialStepData: function () {
        return this.stepsObj.filter(step => {
          return step.blockClass == this.blockClass
        })[0];
      },
      mainOptionsWithoutPin: function () {
        return this.initialStepData.stepOptions.mainFields.filter(option => {
          return !option.includes('PIN')
        })
      },
      pinOptions: function () {
        return this.initialStepData.stepOptions.mainFields.filter(option => {
          return option.includes('PIN')
        })
      },
      step: function () {
        if (this.blockClass.length > 0) {
          return {
            blockClass: this.blockClass,
            options: {
              additionalFields: this.newOptions.additionalFields,
              mainFields: this.newOptions.mainFields,
              ocr: this.ocrAvailable ? this.newOptions.ocr : false,
              ocrFallback: (this.ocrAvailable && this.newOptions.ocr) ? this.newOptions.ocrFallback : false,
              sumBased: ((this.ocrAvailable && this.newOptions.ocr) || this.ddpSumBased) ? this.newOptions.sumBased : false
            }
          }
        }
      },
      ocrAvailable() {
        const availableOn = ['CODE128', 'CostcoVAN', 'IABWithCheckDigit'];
        return this.newOptions.mainFields.some(field => availableOn.includes(field));
      },
      ddpSumBased() {
        return location.pathname.includes('CA1124ANY_DDP') || location.pathname.includes('CA1124DD_DDP');
      }
    },
    methods: {
      returnToMainFields: function (event) {
        $('.pinOptions, i.fa-arrow-left').hide();
        $('.mainf').show();
      },

      setMain: function (event) {
        $('.pinOptions, i.fa-arrow-left').hide();
        $('.mainf').show();
        this.newOptions.mainFields.push($(event.target).val());
        $(event.target).val('')
      },
      setAdditional: function (event) {
        this.newOptions.additionalFields = [];
        for (var i = 0; i < event.target.selectedOptions.length; i++) {
          this.newOptions.additionalFields.push(event.target.selectedOptions[i].value);
        }
      },
      removeField: function (event) {
        if (!this.readOnly)
          this.newOptions.mainFields.splice($('li.main-field').index(event.target.parentElement), 1);
      }
    }
  }
</script>

<style scoped>
  .main-field {
    list-style: none;
    font-size: 16px;
  }

  .main-field .fa-times {
    color: lightgray;
    font-size: 12px;
  }

  i.fa-arrow-left {
    color: gray;
    font-size: 18px;
    margin-right: -20px;
    padding: 5px;
  }

  i.fa-arrow-left:hover {
    color: lightgray;
  }
</style>
