<template>
  <div v-if="list.length">
    <h4 class="mb-0 pb-0">Bonus Product Details</h4>
    <p class="mb-2">
      May be accessed by the user at
      <a v-if="bonusURL" :href="bonusURL" v-text="bonusURL" target="_blank" rel="noopener nofollow"/>
      <template v-else>Project's Redemption URL </template>
      using the Confirmation Number and Email below.
    </p>
    <table class="table table-sm table-hover bg-white">
      <thead>
        <tr>
          <th>Confirmation Number</th>
          <th>Sent To (Customer)</th>
          <th>Product</th>
          <th>Status</th>
          <th>Date/Time</th>
        </tr>
      </thead>
      <tbody>
      <tr v-for="(item) in list" :key="`${item.customer_order_number}__${item.updated_at}`">
        <td class="text-nowrap">
          {{item.customer_order_number}}
        </td>
        <td class="text-nowrap">
          {{item.delivery_detail}}
        </td>
        <td>
          {{item.product_description}}
        </td>
        <td>
          {{item.status}}
        </td>
        <td>
          <span class="text-nowrap">
            {{date(item.updated_at, 'YYYY-MM-DD')}}
          </span>
          <span class="text-nowrap">
            {{date(item.updated_at, 'HH:mm:ss')}} UTC
          </span>
        </td>
      </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
export default {
  props: {
    list: {
      required: true,
      type: Array, // of order entity
      default: () => ([]),
    },
  },

  computed: {
    bonusURL() {
      //todo: replace hardcode with data from DDS response if it's possible
      return 'https://orders.getbonusrewards.com';
    },
  },

  methods: {
    date(dateTime, format) {
      return this.$moment(dateTime).utc().format(format);
    },
  }
};
</script>

<style scoped lang="scss">
  table.table {
    thead th {
      vertical-align: inherit !important;
      padding: 0.5rem !important;
      text-align: left;
    }

    tbody tr td {
      padding: 0.5rem !important;
      text-align: left;
    }
  }
</style>
