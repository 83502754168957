<template>
  <div class="col-lg-7" id="emailDelivery"></div>
</template>

<script>
export default {
  name: 'emailDelivery',
  props: ['stepsObj', 'stepOptions', 'blockClass'],
    data: function() {
        return {
            stepName: 'Email Delivery',
            newOptions: {}
        }
    },
    computed: {
        step: function() {
            if (this.blockClass.length > 0) {
                return {
                    blockClass: this.blockClass,
                    options: {}
                }
            }
        }
    },
    mounted: function() {

        if (this.stepOptions) {
            this.newOptions = this.stepOptions;
        }
    }
}
</script>
