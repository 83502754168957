<template>
  <div class="mt-4 align-items-baseline col-md-6 px-0">
    <input type="checkbox"
           :id="id"
           :disabled="componentProperties.readonly"
           v-model="componentProperties.options.value"
           @change="$emit('change', componentProperties.options.value)"
           ref="checkbox"/>
    <label :for="id" class="ml-2">{{componentProperties.options.label}}</label>
  </div>
</template>

<script>
export default {
  name: 'labeledCheckbox',
  components:{},
  props: ['options', 'locale', 'id', 'name', 'languageId', 'language', 'type', 'value', 'readonly'],
  computed: {
    opt: {
      get() {
        return {
          label: this.options.label || '',
          value: this.value || 0
        };
      },
      set(v) {},
    },

    componentProperties() {
      return {
        type: this.type,
        is: this.$options._componentTag,
        id: this.id || '',
        locale: this.locale,
        name: this.name,
        languageId: this.languageId,
        readonly: this.readonly,
        options: this.opt,
      };
    },
  },
};
</script>

<style scoped>
</style>
