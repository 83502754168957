<template>
  <a id="promotionRatio" class="col-md-4 ratio-container" v-if="failedRedemptions || successfulRedemptions"  :href="filteredRedemptionsGridLink()" target="_blank">
    <div class="progress-container">
      <div class="progress mb-2 d-flex justify-content-between align-items-center">
        <div class="progress-bar h-100 c1 success" aria-valuemax="100" aria-valuemin="0" :aria-valuenow="successfulPercentage"
             role="progressbar" v-bind:style="successfulStyleObject ">
          <div v-if="successfulPercentage > 25">Success {{ successfulPercentage }}%
            <span class="mr-2 text-body float-right"
                  v-if="successfulPercentage == 100">{{ successfulRedemptions }}</span>
          </div>
        </div>
        <span v-if="successfulPercentage <= 25">Success {{ successfulPercentage }}%</span>
        <span class="mr-2 text-body" v-if="successfulPercentage < 100">{{ successfulRedemptions }}</span>
      </div>
    </div>
    <div class="progress-container">
      <div class="progress mb-2 d-flex justify-content-between align-items-center ">
        <div class="progress-bar h-100 c1 fail " aria-valuemax="100 " aria-valuemin="0 " aria-valuenow="failedPercentage "
             role="progressbar" v-bind:style="failedStyleObject ">
          <div v-if="failedPercentage > 25">Failure {{ failedPercentage }}%
            <span class="mr-2 text-body float-right" v-if="failedPercentage == 100">{{ failedRedemptions }}</span>
          </div>
        </div>
        <div class="d-flex col justify-content-between mr-0" v-if="failedPercentage <= 25">
          <span class="mx-auto">Failure {{ failedPercentage }}%</span>
        </div>
        <span class="mr-2 text-body" v-if="failedPercentage < 100">{{ failedRedemptions }}</span>
      </div>
    </div>
  </a>
  <div class="col-md-8 d-flex justify-content-start" v-else-if="redemptionsTotal == 0">
    <h5>No Redemptions for selected period</h5>
  </div>
</template>

<script>
import qs from "qs";

export default {
  props: ['redemptionsTotal', 'successfulRedemptions', 'promotionProjectId', 'promotionSlug'],
  computed: {
    failedRedemptions() {
      return this.redemptionsTotal - this.successfulRedemptions
    },
    successfulPercentage() {
      if (this.successfulRedemptions > 0) {
        return ((this.successfulRedemptions / this.redemptionsTotal) * 100).toFixed(2)
      } else {
        return 0;
      }
    },
    failedPercentage() {
      if (this.failedRedemptions == 0) {
        return 0;
      } else {
        return (100 - this.successfulPercentage).toFixed(2)
      }
    },
    successfulStyleObject: function () {
      return {
        width: this.successfulPercentage + '%'
      }
    },
    failedStyleObject() {
      return {
        width: this.failedPercentage + '%'
      }
    },
  },

  methods: {
    filteredRedemptionsGridLink(status_type) {
      const params = {
        filter: {
          promotion_slug: {
            filter: this.promotionSlug,
            filterType: "text",
            type: "contains",
          }
        },
      };

      if (status_type) {
        params.status_type = status_type;
      }

      return `/admin/projects/${this.promotionProjectId}/redemptions#${qs.stringify(params)}`
    }
  },
}
</script>

<style scoped>
a {
  text-decoration: none;
}
</style>
