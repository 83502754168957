<template>
  <div class="setting-section card card-bordered p-0 pt-3 pl-3 pb-2 mt-3">
    <h5>Text</h5>
    <div class="form-group row d-flex align-items-center">
      <label for="textFontType" class="col-sm-4 col-form-label">Font Type</label>
      <div class="col-sm-8">
        <v-select taggable :options="fontsList" v-model="fontType" id="fontSelector" data-element="text"></v-select>
      </div>
    </div>
    <div class="row">
      <label for="defaultFontColor" class="col-sm-4 col-form-label">Default Text Color</label>
      <chrome-picker v-model="settings.defaultColor" @input="settings.defaultColor = `rgba(${settings.defaultColor.rgba.r}, ${settings.defaultColor.rgba.g}, ${settings.defaultColor.rgba.b},${settings.defaultColor.rgba.a})`"></chrome-picker>
    </div>
    <div class="row mt-5">
      <label for="textPrimaryColor" class="col-sm-4 col-form-label">Primary Text Color</label>
      <chrome-picker v-model="settings.primaryColor" @input="settings.primaryColor = `rgba(${settings.primaryColor.rgba.r}, ${settings.primaryColor.rgba.g}, ${settings.primaryColor.rgba.b},${settings.primaryColor.rgba.a})`"></chrome-picker>
    </div>
    <div class="row mt-5">
      <label for="textSecondaryColor" class="col-sm-4 col-form-label">Secondary Text Color</label>
      <chrome-picker v-model="settings.secondaryColor" @input="settings.secondaryColor = `rgba(${settings.secondaryColor.rgba.r}, ${settings.secondaryColor.rgba.g}, ${settings.secondaryColor.rgba.b},${settings.secondaryColor.rgba.a})`"></chrome-picker>
    </div>
  </div>
</template>

<script>
import { Chrome } from 'vue-color'
export default {
  name: 'textSettings',
  props: ['settings'],
  components: { 'chrome-picker' : Chrome },
  data: function(){
    return {
      fontType: '',
      fontsList: ['Montserrat', 'Open Sans', 'Roboto']
    }
  },
  methods: {
    pickColor: function(element, property, color){ this.$emit('pickColor', { element: element, property: property, color: color }) }
  },
  created: function(){ this.fontType = this.settings.font },
  watch: {
    settings: function(){ this.fontType = this.settings.font },
    fontType: function(){ this.$parent.$emit('stylesChanged', this.fontType) }
  }
}
</script>

<style scoped>
  .vc-chrome{
    width: 60%;
    display: inherit;
  }

  .vc-chrome:first-child{
    padding-bottom: 40% !important;
  }
</style>
