<template>
  <ul class="promotionsList pl-0 ml-0">
    <promotionItem v-for="promotion in promotions" :key="promotion.name" :promotionData="promotion"/>
  </ul>
</template>

<script>
import promotionItem from './promotionItem'
export default {
  components: { promotionItem },
  props: ['promotions']
}
</script>

<style scoped></style>
