<template>
    <div class="col-lg-7" id="confirmation">
        <div class="descContainer">
            <div class="row field-row mb-4 align-items-center">
                <div class="col-lg-4">
                    <div class="field-title">Delivery Options</div>
                </div>
                <div class="col-lg-8">
                    <select multiple data-width="100%" @change="setOptions" class="delivery selectortype selectpicker">
                        <option v-for="deliveryOption in initialStepData.stepOptions.deliveryOptions">{{deliveryOption}}</option>
                    </select>
                </div>
            </div>
            <div v-if="this.projectName != 'JapanPosa'" class="col barcode-container field-title d-flex align-items-center">
                <input name="barcode" type="checkbox" v-model="newOptions.barcode" id="enableBarcodeCheckbox"/>
                <label for="enableBarcodeCheckbox" class="mb-0 ml-2">Barcode</label>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'confirmation',
    props: ['stepsObj', 'stepOptions', 'blockClass', 'projectName'],
    data: function() {
        return {
            stepName: 'Confirmation',
            newOptions: {
                deliveryOptions: [],
                barcode: false,
                barcodeFormat: ''
            }
        }
    },
    computed: {
        step: function() {
            var barcodeCheckbox = $('input[name="barcode"]');

            if (this.blockClass.length > 0) {
                return {
                    blockClass: this.blockClass,
                    options: {
                        deliveryOptions: this.newOptions.deliveryOptions,
                        barcode: (this.projectName === 'JapanPosa' ?  true : barcodeCheckbox[0].checked),
                    }
                }
            }
        },
        initialStepData: function(){
          return this.stepsObj.filter(step => { return step.blockClass === this.blockClass })[0];
        }
    },
    mounted: function() {
        if (Object.keys(this.stepOptions).length > 0){
          this.newOptions = this.stepOptions;
        }

        $(this.$el).find('select.delivery').selectpicker('val', this.newOptions.deliveryOptions);

        if(this.newOptions.barcode === 'false'){
          this.newOptions.barcode = false
        } else if(this.newOptions.barcode === 'true'){
          this.newOptions.barcode = true;
        }
    },
    methods: {
        setOptions: function(event) {
            this.newOptions.deliveryOptions = [];
            for (var i = 0; i < event.target.selectedOptions.length; i++) {
                this.newOptions.deliveryOptions.push(event.target.selectedOptions[i].value);
            }
        }
    }
};
</script>
