<template>
  <thead class="thead">
    <tr>
      <th v-for="(title, index) in headers.length" :key="title" :width="headersWidth(index)">{{headers[title-1]}}</th>
    </tr>
  </thead>
</template>

<script>


export default {
  name: 'infoTable',
  props: ['titles', 'draggableTableItems'],
  computed: {
    headers() {
      return this.draggableTableItems ? ['', ...this.titles] : this.titles
    },
  },
  methods: {
    headersWidth(index) {
      let res = '10%';

      if (!index) {
        if (this.draggableTableItems) {
          res = '3%'
        }
      } else if (index === this.headers.length - 1) {
        res = '7%';
      }

      return res;
    }
  }
}
</script>

<style scoped>
  .thead{
    background: #ebebeb;
  }
</style>
