<template>
<div class="setting-section card card-bordered p-0 pt-3 pl-3 pb-2 mt-3">
  <h5>Button</h5>
  <div class="row">
    <label for="buttonColor" class="col-sm-4 col-form-label">Color</label>
    <chrome-picker v-model="settings.backgroundColor" @input="settings.backgroundColor = `rgba(${settings.backgroundColor.rgba.r}, ${settings.backgroundColor.rgba.g}, ${settings.backgroundColor.rgba.b},${settings.backgroundColor.rgba.a})`"></chrome-picker>
  </div>
  <div class="row mt-5">
    <label for="buttonTextColor" class="col-sm-4 col-form-label">Text Color</label>
    <chrome-picker v-model="settings.textColor" @input="settings.textColor = `rgba(${settings.textColor.rgba.r}, ${settings.textColor.rgba.g}, ${settings.textColor.rgba.b},${settings.textColor.rgba.a})`"></chrome-picker>
  </div>
</div>
</template>

<script>
import { Chrome } from 'vue-color'

export default {
  name: 'buttonSettings',
  props: ['settings'],
  components: { 'chrome-picker' : Chrome }
}
</script>

<style scoped>
  .col-sm-8 .input-group{
    width: 80%
  }

  .color-bar{
    width: 15%;
  }

  label.color-bar{
    margin: 0 0 0 10px;
  }

  input[type="color"]{
    visibility: hidden;
    width: 0;
    padding: 0;
    margin: 0;
  }

  .vc-chrome{
    width: 60%;
    display: inherit;
  }

  .vc-chrome-saturation-wrap{
    padding-bottom: 40%;
  }

  .vc-chrome-toggle-btn{
    display: none;
  }
</style>
