<template>
  <div class="row">
    <div class="col-3">
      <label class="form-label" :for="`source_${value.rid}`">Code Source</label>
      <select class="form-control"
              :id="`source_${value.rid}`"
              :name="`product[product_settings_attributes][${index}][source]`"
              v-model="source"
      >
        <template v-for="opt in options">
          <option :value="opt">{{opt}}</option>
        </template>
      </select>
      <error-hint field-name="source" :value="value" />
    </div>
    <div class="col-9 align-self-end">
      <div class="form-check mb-2">
        <input class="form-check-input"
               type="checkbox"
               :name="`product[product_settings_attributes][${index}][no_label]`"
               :id="`label_${value.rid}`"
               v-model="no_label"
               :value="true">
        <label class="form-check-label text-nowrap" :for="`label_${value.rid}`">
          Do not show label{{languages.length > 1 ? 's' : ''}} (Display code only)
        </label>
      </div>

      <div class="row mt-3" v-if="!no_label">
        <input type="hidden" :name="`product[product_settings_attributes][${index}][no_label]`" value="false">
        <template v-for="(lang, idx) in languages">
          <div class="col-4 mb-3">
            <label class="form-label" :for="`label_${idx}_${value.rid}`">Label ({{lang.name}})</label>
            <input type="text"
                   class="form-control"
                   :id="`label_${idx}_${value.rid}`"
                   :name="`${propertyNameSuffix}[${idx}][value]`"
                   v-model="labels[lang.id]"/>
            <input type="hidden" :name="`${propertyNameSuffix}[${idx}][id]`" :value="getPropsByLanguageId(lang.id).id || ''" />
            <input type="hidden" :name="`${propertyNameSuffix}[${idx}][language_id]`" :value="lang.id" />
            <input type="hidden" :name="`${propertyNameSuffix}[${idx}][name]`" value="code.label" />
            <small>
              Leave empty to use default label.
            </small>
          </div>
        </template>
      </div>
    </div>
  </div>
</template>

<script>
import ErrorHint from "./ErrorHint.vue";

export default {
  components: {
    ErrorHint
  },
  props: {
    languages: {
      required: true,
      type: Array, // of languages
    },
    options: {
      required: true,
      type: Array // of source options
    },
    index: {
      required: true,
      type: Number,
    },
    value: {
      required: false,
      type: Object,
      default: () => ({}),
    }
  },
  data() {
    return {
      source: this.options && this.options[0],
      labels: {},
      no_label: false,
    };
  },

  mounted() {
    this.setValues();
  },

  watch: {
    value: {
      handler() {
        this.setValues();
      },
      deep: true,
    },
  },

  methods: {
    getPropsByLanguageId(languageId) {
      return this.value.properties.find(prop => prop.language_id == languageId) || {};
    },

    setValues() {
      const labels = {};
      this.languages.forEach((lang) => {
        const prop = this.getPropsByLanguageId(lang.id);
        labels[lang.id] = prop.value || '';
      });

      this.labels = labels;
      this.source = this.value.source || this.options && this.options[0];
      this.no_label = this.value.no_label || false;
    },
  },

  computed: {
    propertyNameSuffix() {
      return `product[product_settings_attributes][${this.index}][properties_attributes]`;
    }
  }
}
</script>
