<template>
  <div v-change-outside.jquery="{ name: 'changedBranch', handler: changedBranch }" class="mb-3">
    <template v-if="merchantOptions.length">
      <v-multiselect
          :title="label"
          :field-name="fieldName"
          :value="model"
          :options="merchantOptions"/>
    </template>
    <template v-else>
      <div class="d-flex flex-column mb-3">
        <label class="col-form-label d-block" v-text="label"/>
        <span class="text-warning">No Merchants in selected branch</span>
      </div>
    </template>
    <template v-if="displayMessage">
      <small class="invalid-feedback">{{ displayMessage }}</small>
    </template>
  </div>
</template>

<script>

import MultiSelector from '../../shared_components/MultiSelector';

export default {
  components: {
    'v-multiselect': MultiSelector,
  },

  props: {
    label: {
      required: true,
      type: String,
    },
    fieldName: {
      required: true,
      type: String,
    },
    selectedMerchants: {
      required: true,
      type: Array,
      default: () => ([]),
    },
    merchants: {
      required: true,
      type: Array,
      default: () => ([]),
    },
    displayMessage: {
      required: false,
      type: String,
    },
  },
  data() {
    return {
      list: JSON.parse(this.merchants),
      value: JSON.parse(this.selectedMerchants),
      model: [],
      selectedBranch: null,
    };
  },

  mounted() {
    this.updateMerchantOptions();
  },

  watch: {
    merchantOptions() {
      const newMerchantIds = this.merchantOptions.map(merchant => merchant.id);
      this.model = [...this.value].filter(item => newMerchantIds.includes(item));
    },
  },

  computed: {
    merchantOptions() {
      return (this.selectedBranch ? this.list.filter(item => item.branch_id == this.selectedBranch) : this.list);
    }
  },

  methods: {
    updateMerchantOptions() {
      const s = [...promotion_branch_id.options].find(item => item.selected);
      this.selectedBranch = s.value || null;
    },

    changedBranch(e) {
      const targetId = 'promotion_branch_id';

      if (e.target.id === targetId) {
        this.updateMerchantOptions();
      }
    },
  }
}
</script>
