<template>
  <div class="form-group row">
		<div class="row align-items-center ml-2">
      <i class="fa fa-times mr-2 ml-2 text-danger"></i>
    	<div class="text-secondary"><b>{{upc}}</b> <span class="text-secondary" v-if="upcInfo.amountType.length > 0">Variable Product</span></div>
  	</div>
  </div>
</template>

<script>
export default {
  name: 'wrongUpc',
  props: ['upcInfo'],
  data: function(){
    return{
      upc: this.upcInfo.upc
    }
  }
}
</script>

<style scoped></style>
