<template>
  <div class="col-lg-7" id="selectBonus">
        <div class="row field-row mb-2 align-items-center">
            <div class="col-lg-4">
                <div class="field-title">Selector Type:</div>
            </div>
            <div class="col-lg-8">
                <select class="selectortype selectpicker" data-width="100%" @change="setSelectorType">
                    <option v-for="fieldOption in stepsObj[4].stepOptions.selectorType">{{fieldOption}}</option>
                </select>
            </div>
        </div>
    </div>
</template>

<script>
  export default{
    name: 'selectBonus',
    props: ['stepsObj', 'stepOptions', 'blockClass'],
    data: function() {
        return {
            stepName: 'Select Partner',
            newBlockClass: '',
            newOptions: {
                selectorType: ''
            }
        }
    },
    computed: {
        step: function() {
            if (this.blockClass.length > 0) {
                return {
                    blockClass: this.blockClass,
                    options: {
                        selectorType: this.newOptions.selectorType || "Clickable"
                    }
                }
            }
        }
    },
    mounted: function() {

        if (this.stepOptions) {
            this.newOptions = this.stepOptions;
        }

        $(this.$el).find('select.selectpicker.selectortype').selectpicker();
        $(this.$el).find('select.selectpicker.selectortype').selectpicker('val', this.newOptions.selectorType);
    },
    methods: {
        setSelectorType: function(event) {
            this.newOptions.selectorType = event.target.selectedOptions[0].text;
            // console.log(this.newOptions.selectorType);
        }
    }
  }
</script>
