<template>
  <div class="col-lg-7" id="redeemer">
    <div class="row field-row mb-2 align-items-center">
        <div class="col-sm-4">
            <div class="field-title">Codes Source:</div>
        </div>
        <div class="col-sm-8">
            <select v-model="newOptions.codesSource" data-width="100%" class="codes-source selectortype selectpicker">
                <option v-for="source in initialStepData.stepOptions.codesSource">{{source}}</option>
            </select>
        </div>
    </div>
    <div v-if="showBonusCodesLink" class="row field-row mb-2 align-items-center">
        <div class="col">
            <a :href="bonusCodesLink">Bonus codes</a>
        </div>
    </div>
    <div v-if="showBonusCodesLinkHint" class="row field-row mb-2 align-items-center">
        <div class="col mt-1">
            <small>Save workflow to access "Bonus codes" page</small>
        </div>
    </div>
  </div>
</template>

<script>
  export default{
    name: 'redeemer',
    props: ['stepsObj', 'stepOptions', 'blockClass', 'showLinkToBonusCodes', 'enableBonusCodesLink'],
    data: function() {
        return {
            stepName: 'Redeemer',
            newOptions: {
                codesSource: 'RTG'
            }
        }
    },
    computed: {
        step: function() {
            if (this.blockClass.length > 0) {
                return {
                    blockClass: this.blockClass,
                    options: {
                        codesSource: this.newOptions.codesSource || 'RTG'
                    }
                }
            }
        },
        initialStepData: function(){
          return this.stepsObj.filter(step => { return step.blockClass == this.blockClass })[0];
        },
        bonusCodesLink: function() {
          return window.location.href.replace('rules', 'bonus_codes');
        },
        showBonusCodesLink: function() {
          return this.showLinkToBonusCodes && this.newOptions.codesSource == 'Local Storage' && this.enableBonusCodesLink;
        },
        showBonusCodesLinkHint: function() {
          return !this.showLinkToBonusCodes && this.newOptions.codesSource == 'Local Storage' && this.enableBonusCodesLink;
        }
    },
    mounted: function() {

        if (this.stepOptions) {
            this.newOptions = this.stepOptions;
        }

        $(this.$el).find('select.codes-source').selectpicker('val', this.newOptions.codesSource);
    },
    updated: function() {
        $(this.$el).find('select.codes-source').selectpicker('val', this.newOptions.codesSource);
    }
  }
</script>
