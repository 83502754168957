<template>
  <li :class="`list-group-item d-flex align-items-center flex-wrap ${isFailure ? 'list-group-item_error' : ''}`" :id="`promotionItem_${promotionData.id}`">
    <promotionInfo :promotionStatus="promotionData.status" :promotionBranch="promotionData.branch"
                   :promotionSlug="promotionData.slug" :promotionCurrentEndDate="promotionData.endDate.current"
                   :promotionNextEndDate="promotionData.endDate.next" :promotionProjectId="promotionData.project.id"
                   :promotionProjectName="promotionData.project.name" :promotionId="promotionData.id"/>
    <promotionRatio :redemptionsTotal="promotionData.redemptionsTotal"
                    :successfulRedemptions="promotionData.successfulRedemptions" :promotionSlug="promotionData.slug"
                    :promotionProjectId="promotionData.project.id"/>
    <promotionErrors
        v-if="promotionData.redemptionsTotal"
        class="col-md-4"
        :errors="promotionData.errors " :promotionProjectId="promotionData.project.id "
         :promotionSlug="promotionData.slug " :redemptionsTotal="promotionData.redemptionsTotal "/>
  </li>
</template>

<script>
import promotionInfo from './promotionInfo'
import promotionRatio from './promotionRatio'
import promotionErrors from './promotionErrors'

export default {
  name: 'promotionItem',
  components: {
    promotionInfo, promotionRatio, promotionErrors
  },
  props: ['promotionData', 'promotion'],

  computed: {
    isFailure() {
      const ERROR_RATE = 0.5;
      let res = false;

      if (this.promotionData) {
        const {redemptionsTotal, successfulRedemptions} = this.promotionData;
        const errored = redemptionsTotal - successfulRedemptions;
        res = (redemptionsTotal - successfulRedemptions) / redemptionsTotal >= ERROR_RATE;
      }

      return res;
    }
  }
}
</script>

<style scoped></style>
