<template>
  <div class="col-md-4 d-flex flex-row justify-content-between" id="promotionInfo">
        <div class="d-flex align-items-center justify-content-between">
            <div class="flag-container " @click="filterByBranch ">
                <img :src="promotionBranch ">
            </div>
            <span class="span project-badge bg-default " @click="filterByProject ">{{promotionProjectName}}</span>
        </div>
        <div class="slug-info flex-column p-0 align-items-center">
            <h4 @click="showPromotionInfo">{{promotionSlug}}</h4>
            <small v-if="promotionStatus ==='presale'" class="mb-1">Published
              <span class="badge filter-presale">{{promotionCurrentEndDate}}</span>
            </small>
            <small v-if="promotionStatus !=='presale'" class="mb-1">{{promotionStatus.capitalize()}} Ends
              <span class="badge" v-bind:class="classObject">{{promotionCurrentEndDate}}</span>
            </small>
            <small v-if="promotionStatus !=='support' && promotionStatus !=='presale'">{{nextStatus.capitalize()}} Ends
              <span class="badge" v-bind:class="nextClassObject">{{promotionNextEndDate}}</span>
            </small>
        </div>
    </div>
</template>

<script>
import '../../optionsFormatter'
export default {
    props: ['promotionStatus', 'promotionBranch', 'promotionSlug', 'promotionCurrentEndDate', 'promotionNextEndDate', 'promotionProject', 'promotionProjectId', 'promotionProjectName', 'promotionId'],
    computed: {
        classObject: function() {
            return {
                'bg-warning': this.promotionStatus === 'support',
                'bg-success': this.promotionStatus === 'active',
                'bg-info': this.promotionStatus === 'claim'
            }
        },
        promotionLink: function() {
            return '/admin/projects/' + this.promotionProjectId + '/promotions/' + this.promotionId;
        },
        nextClassObject: function() {
            return {
                'bg-warning': this.nextStatus === 'support',
                'bg-success': this.nextStatus === 'active',
                'bg-info': this.nextStatus === 'claim'
            }
        },
        nextStatus: function() {
            if (this.promotionStatus === 'active') {
                return 'claim';
            } else {
                return 'support'
            }
        }
    },
    methods: {
        filterByBranch: function() {
            this.$parent.$parent.$emit('branchFilter', this.promotionBranch);
        },
        filterByProject: function() {
            this.$parent.$parent.$emit('projectFilter', this.promotionProjectName);
        },
        showPromotionInfo: function() {
            window.open(this.promotionLink, '_blank');
        }
    }
}
</script>

<style scoped></style>
