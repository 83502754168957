<template>
  <div class="col-md-6 mt-3 mb-3 preview" :style="commonStyles">
    <h4 class="mb-3">Preview</h4>
    <div class="p-4 preview-container card card-bordered"
         v-bind:style="{ backgroundColor: styleSettings.page.backgroundColor, fontFamily: styleSettings.text.font, color: styleSettings.text.defaultColor }">
    <h5 v-bind:style="{ color: styleSettings.text.primaryColor}">Primary Color</h5>
    <div class="mb-2">
      <label for="previewInput" class="mt-4 form-label">This is input field title</label>
      <input type="text" class="form-control" name="previewInput" id="previewInput" placeholder="Placeholder text" v-bind:style="{ borderColor: styleSettings.input.borderColor, backgroundColor: styleSettings.input.backgroundColor, color: styleSettings.text.secondaryColor}">
      <small v-bind:style="{ color: styleSettings.text.secondaryColor}">This text describes input field purpose</small>
    </div>
    <div class="form-submit">
      <button class="btn" v-bind:style="{ color: styleSettings.button.textColor, backgroundColor: styleSettings.button.backgroundColor, borderColor: styleSettings.button.textColor}">Submit</button>
    </div>
    <div class="footer-links mt-3">
      <span v-bind:style="{ color: styleSettings.text.secondaryColor}">© {{year}} InComm Payments</span>
      <a v-bind:style="{ color: styleSettings.text.defaultColor }" class="mr-2 ml-2" disabled="true">Contact Us</a>
      <a v-bind:style="{ color: styleSettings.text.defaultColor }" disabled="true">F.A.Q</a>
    </div>
  </div>
  </div>
</template>

<script>
export default {
  name: 'previewWindow',
  props: ['styleSettings'],

  computed: {
    year() {
      return new Date().getFullYear();
    },
    commonStyles() {
      return {
        '--placeholder': this.styleSettings.input.placeholderColor
      }
    }
  }
}
</script>

<style scoped>
  @import url('https://fonts.googleapis.com/css?family=Montserrat:400,400i,700,700i|Open+Sans:400,400i,700,700i|Roboto:400,400i,700,700i');

  ::placeholder {
    color: var(--placeholder) !important;
  }

  ::-webkit-input-placeholder {
    color: var(--placeholder) !important;
  }

  :-moz-placeholder {
    color: var(--placeholder) !important;
  }

  ::-moz-placeholder {
    color: var(--placeholder) !important;
  }

  :-ms-input-placeholder {
    color: var(--placeholder) !important;
  }

  label,
  button{
    font-weight: bold;
  }

  button{
    font-weight: 700;
    font-size: 13px;
  }

  h5{
    font-weight: 700;
    font-size: 18px;
  }

  .preview-container{
    position: sticky;
    top:100px;
    height: max-content;
    width: 450px;
  }

</style>
