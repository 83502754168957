import MerchantByProjectSelector from "./vue_components/admin/promotion/setup/MerchantByProjectSelector.vue";

require("jquery");
require("jquery-ujs");
require("popper.js");
require("bootstrap");
require("jquery-ui-sortable-only/jquery-ui");
require("lib/bootstrap-select");
require("lib/daterangepicker");
require("../../../vendor/assets/javascripts/bootstrap-datetimepicker");
require("data-confirm-modal");
require("dialog");

import dashboard from "./vue_components/admin/dashboard/dashboard";
import stepslist from "./vue_components/admin/promotion/stepDetails/stepsList";
import styleEditor from "./vue_components/admin/styleEditor/styleEditor";
import pagesSetupForm from "./vue_components/admin/promotion/pages/pagesSetupForm";
import rules from "./vue_components/admin/rules/rules.vue";
import PromoDates from "./vue_components/admin/promotion/setup/PromotionDates.vue";
import PresaleProductForm from "./vue_components/admin/product/PresaleProductForm.vue";
import RichEditor from "./vue_components/admin/product/RichEditor.vue";
import DataTableRedemptions from "./vue_components/dataTableRedemptions/index.vue";
import DataTableAudit from "./vue_components/dataTableAudit/index.vue";
import DataTableMerchants from "./vue_components/dataTableMerchants/index.vue";
import DataTableProducts from "./vue_components/dataTableProducts/index.vue";
import DataTablePromotions from "./vue_components/dataTablePromotions/index.vue";
import DataTablePartners from "./vue_components/dataTablePartners/index.vue";
import DataTableUsers from "./vue_components/dataTableUsers/index.vue";
import DataTableBranches from "./vue_components/dataTableBranches/index.vue";
import DataTableIntegrations from "./vue_components/Integrations/dataTable.vue";
import DataTableManualRedemptions from './vue_components/ManualRedemptions/dataTable.vue';

import MerchantByRoleSelector from './vue_components/admin/user/MerchantSelector';
import PartnerByRoleSelector from './vue_components/admin/user/PartnerSelector';

import ProductCodes from './vue_components/admin/productCodes/index.vue';

import SupportForm from "./vue_components/support/index.vue";

import moment from 'moment-timezone';
import axios from "axios";
import vSelect from "vue-select";
import MultiSelector from './vue_components/admin/shared_components/MultiSelector';

import vOutsideEvents from 'vue-outside-events';

$(function() {
  Vue.config.silent = true;
  Vue.prototype.$moment = moment;
  Vue.prototype.$http = axios;
  Vue.component('v-select', vSelect);
  Vue.component('v-multiselect', MultiSelector);
  Vue.use(vOutsideEvents);
  // Vue.prototype._ = _;

  const promoDatesApp = new Vue({
    el: '#promo-dates',
    components: {
      PromoDates,
    }
  })

  const productFormApp = new Vue({
    el: '.product-form',
    components: {
      PresaleProductForm,
      RichEditor,
    }
  })

  const setupPageApp = new Vue({
    el: "#setupPage",
    components: { "pages-setup-form": pagesSetupForm }
  });

  const stepsApp = new Vue({
    el: "#stepsApp",
    components: { stepslist }
  });

  const rulesApp = new Vue({
    el: "#rulesApp",
    components: { rules }
  });

  const dashboardApp = new Vue({
    el: "#dashboardContainer",
    components: { dashboard }
  });

  const styles = new Vue({
    el: "#styleEditorContainer",
    components: {
      "style-editor": styleEditor
    }
  });

  const dataTableMerchants = new Vue({
    el: "#dataTableMerchants",
    components: {
      "data-table-merchants": DataTableMerchants
    }
  });

  const dataTableRedemptions = new Vue({
    el: "#dataTableRedemptions",
    components: {
      "data-table-redemptions": DataTableRedemptions
    }
  });

  const dataTableAudit = new Vue({
    el: "#dataTableAudit",
    components: {
      "data-table-audit": DataTableAudit
    }
  });

  const dataTableProducts = new Vue({
    el: "#dataTableProducts",
    components: {
      "data-table-products": DataTableProducts
    }
  });

  const dataTablePromotions = new Vue({
    el: "#dataTablePromotions",
    components: {
      "data-table-promotions": DataTablePromotions
    }
  });

  const dataTablePartners = new Vue({
    el: "#dataTablePartners",
    components: {
      "data-table-partners": DataTablePartners
    }
  });

  const dataTableUsers = new Vue({
    el: "#dataTableUsers",
    components: {
      "data-table-users": DataTableUsers
    }
  });

  const dataTableIntegrations = new Vue({
    el: "#dataTableIntegrations",
    components: {
      DataTableIntegrations,
    }
  });

  const dataTableBranches = new Vue({
    el: "#dataTableBranches",
    components: {
      "data-table-branches": DataTableBranches
    }
  });

  const dataTableManualRedemption = new Vue({
    el: "#manualRedemptions",
    components: {
      "data-table-manual-redemptions": DataTableManualRedemptions,
    }
  })

  const supportForm = new Vue({
    el: '#supportForm',
    components: {
      'support-form': SupportForm,
    }
  });

  const merchantByRoleSelector = new Vue({
    el: '#merchant-role-selector',
    components: {
      'merchant-selector': MerchantByRoleSelector,
    },
  });

  const partnerByRoleSelector = new Vue({
    el: '#partner-role-selector',
    components: {
      'partner-selector': PartnerByRoleSelector,
    },
  });

  const promoMerchantsSelector = new Vue({
    el: '#promo-merchants',
    components: {
      'promo-merchants': MerchantByProjectSelector,
    },
  });

  const productCodes = new Vue({
    el: '#product-codes',
    components: {
      'product-codes': ProductCodes,
    },
  });
});
