<template>
  <div class="data-table">
    <h2 v-if="title">{{ title }}</h2>

    <notification
      v-if="message"
      :successText="!hasError ? message : ''"
      :failureText="hasError ? message : ''"
    >
    </notification>

    <div class="action-wrapper">
      <div class="row">
        <div class="col col-12">
          <ul class="data-table-columns">
            <li v-for="item in columnsList" :key="item">
              <input
                :value="item"
                :id="item"
                type="checkbox"
                v-model="visibleColumns"
                :disabled="disabledColumns.indexOf(item) !== -1 || isLoading"
                @change="applyColumnDefinition(true)"
              />
              <label :for="item">{{ columnsListNames[item] }}</label>
            </li>
          </ul>
        </div>
      </div>

    <div class="loader" v-if="isLoading"></div>
  </div>


    <div>
      <ag-grid-vue
          style="width: 100%; height: 76vh"
          class="ag-theme-balham"
          @grid-ready="onGridReady"
          :gridOptions="gridOptions"
          :columnDefs="columnDefs"
          :animateRows="true"
          :multiSortKey="'ctrl'"
          :floatingFilter="true"
          :rowClassRules="rowClassRules"
          :suppressDragLeaveHidesColumns="true"
          :enableCellTextSelection="true"
          :rowData="rowData"
      />
    </div>
  </div>
</template>

<script>
import { AgGridVue } from "ag-grid-vue";
import axios from "axios";
import qs from "qs";
import { setTimeout } from "timers";
import notification from "../notification";
import { isEqual } from "lodash";
import { filterParams } from "../../utils/dataTable";

export default {
  name: "DataTableUsers",
  props: {
    title: { type: String, required: false },
    url: { type: String, required: true },
    deleted: { type: [String], required: true }
  },
  data() {
    return {
      hasError: false,
      isLoading: false,
      message: null,
      gridOptions: {},
      columnDefs: null,
      rowData: null,
      rowClassRules: null,
      divider: 3,
      gridCurrentState: {},
      columnsList: [
        "email",
        "branch_names",
        "role",
        "last_login",
        "actions"
      ],
      columnsListNames: {
        email: "User",
        branch_names: "Branch Name",
        role: "Role",
        last_login: "Last Login(UTC)",
        actions: "Actions"
      },
      visibleColumns: [
        "email",
        "branch_names",
        "role",
        "last_login",
        "actions"
      ],
      disabledColumns: [
        "email",
        "branch_names",
        "role",
        "actions"
      ]
    };
  },

  components: {
    AgGridVue,
    notification
  },

  beforeMount() {
    this.gridCurrentState = {...this.gridState};

    this.gridOptions.defaultColDef = {
      resizable: true
    };

    this.rowClassRules = {
      "dtr-user-inactive": (param) => (param.data && !param.data.active)
    };

    this.applySavedState(['visibleColumns']);
  },

  mounted() {
    this.gridApi = this.gridOptions.api;
    this.subscribeEvents();
  },

  methods: {
    onFilterStatus(status) {
      if (status && (this.selectedStatus !== status)) {
        this.selectedStatus = status;
      } else {
        this.selectedStatus = null;
      }
    },

    subscribeEvents() {
      this.gridOptions.onGridReady = (e) => {
        this.columnApi = e.columnApi;
        this.gridApi = e.api;
      }

      this.gridOptions.onFilterChanged = (grid) => {
        const filter = grid.api.getFilterModel();
        if (!isEqual(filter, this.gridCurrentState.filter)) {
          this.popSavedState({
            filter,
          });
        }
      };

      this.gridOptions.onSortChanged = (grid) => {
        const sort = grid.columnApi.getColumnState()
            .filter(item => !!item.sort)
            .map(item => ({colId: item.colId, sort: item.sort, sortIndex: item.sortIndex}));

        if (!isEqual(sort, this.gridCurrentState.sort)) {
          this.popSavedState({
            sort,
          });
        }
      };

      this.gridOptions.onFirstDataRendered = () => {
        this.applySavedState(['filter', 'sort'], true);
      };

      this.gridOptions.onCellClicked = (cell)  => {
        const $parent = cell.event.target.closest('span');
        const {colId} = cell.column;

        switch (colId.replace(/_?\d+$/,'')) {
          case "role":
          case "branch_names":
            if ($parent && $parent.classList.contains('cell-filter-clickable')) {
              this.applyFilters(colId, $parent.textContent);
            }
            break;

          case "actions":
            if ($parent && $parent.dataset && $parent.dataset["vueAction"]) {
              this.proceedAction($parent.dataset);
            }
            break;

          default:
            break;
        }
      };

      window.addEventListener("resize", () => {
        let w = window.innerWidth;
        setTimeout(() => {
          if (window.innerWidth === w) {
            this.sizeToFit();
          }
        }, 300);
      });
    },

    applySavedState(props, initial) {
      props.forEach(prop => {
        const values = this[initial ? 'gridState' : 'gridCurrentState'][prop];
        if (values) {
          switch (prop) {
            case 'filter':
              this.gridApi.setFilterModel(this.gridCurrentState[prop]);
              this.gridApi.onFilterChanged();
              break;

            case 'sort':
              this.columnApi.applyColumnState({state: this.gridCurrentState[prop]});
              this.gridApi.onSortChanged();
              break;

            case 'visibleColumns':
              this.visibleColumns = this.gridCurrentState[prop];
              break;
          }
        }
      });
    },

    applyFilters(filterBy, text) {
      if (filterBy && text) {
        let filterInstance = this.gridApi.getFilterInstance(filterBy);

        filterInstance.setModel({
          type: "contains",
          filter: text
        });

        this.gridApi.onFilterChanged();
      }
    },

    popSavedState(state) {
      this.gridCurrentState = {
        ...this.gridCurrentState,
        ...state
      };
      location.hash = qs.stringify(this.gridCurrentState);
    },

    proceedAction(dataset) {
      const selected = this.rowData.find(item => item.id === dataset.id - 0);

      dataConfirmModal.confirm({
        backdrop: true,
        keyboard: false,
        show: true,
        title: dataset.title || "Please confirm",
        text: dataset.vueConfirm || "Are you sure you want to continue?",
        commit: "Yes",
        cancel: "Back",
        onConfirm: () => {
          axios({
            headers: {
              "X-CSRF-Token": $('meta[name="csrf-token"]').attr("content")
            },
            method: dataset.vueMethod || "delete",
            url: dataset.vueUrl
          }).then(() => {
              switch (dataset.vueAction) {
                case 'remove':
                case 'delete':
                  this.rowData = this.rowData.filter(
                      item => item.id !== dataset.id - 0
                    );
                    this.updateCount();
                    this.onSuccess("User removed successfully.");
                  break;

                case 'block':
                  this.onSuccess(`User ${selected.active ? 'blocked': 'unblocked'} successfully.`);
                  selected.active = !selected.active;
                  this.gridApi.redrawRows({selected});
                  break;
              }
            },
            error => {
              this.onError(error);
            }
          );
        },
        onCancel: function() {
          return false;
        }
      });
    },

    applyTooltips() {
      $('[data-toggle="tooltip"]').tooltip();
    },

    applyPlaceholders() {
      $('input[ref="eFloatingFilterText"], input[ref="eInput"]').each(function() {
        $(this).attr("placeholder", "Filter...");
      });
    },

    clearFilterSelections() {
      const $list = $('input[ref="eFloatingFilterText"], input[ref="eInput"]');
      setTimeout(() => {
        // to make sure the after reset filters
        $list.each(function() {
          if (!$(this).val()) {
            $(this).removeAttr("disabled");
          }
        });
      }, 600);
    },

    sizeToFit() {
      if (this.visibleColumns.length <= this.columnsList.length / 2 + 3) {
        this.gridApi.sizeColumnsToFit();
      }
    },

    applyColumnDefinition() {
      this.columnDefs = this.columns;
      this.popSavedState({visibleColumns: this.visibleColumns});

      setTimeout(() => {
        this.applyTooltips();
        this.applyPlaceholders();
        this.sizeToFit();
      }, 1);
    },

    onGridReady() {
      let params = {};
      const isDeleted = Boolean(this.deleted - 0);
      if (isDeleted) {
        params.deleted = isDeleted;
      }

      this.isLoading = true;

      axios({
        method: "get",
        url: this.requestUrl,
        params
      }).then(
        response => {
          this.fh = response.data.filters;
          this.applyColumnDefinition();
          this.rowData = response.data.list;
        },
        error => {
          this.onError(error);
        }
      ).finally(() => {
        this.isLoading = false;
      });
    },

    actionCellRenderer(params) {
      const { id, active } = params.data;
      let res = "";

      params.value.forEach(action => {
        switch (action.toUpperCase()) {
          case "READ":
            res += `<a class="icon-action" data-toggle="tooltip" data-placement="top" data-title="Show" href="/admin/users/${id}" data-original-title="" title=""><i class="fa fa-eye" aria-hidden="true"></i></a>`;
            break;
          case "UPDATE":
            res += `<a class="icon-action"  data-toggle="tooltip" data-placement="top" data-title="Edit" href="/admin/users/${id}/edit" data-original-title="" title=""><i class="fa fa-pencil" aria-hidden="true"></i></a>`;
            break;
          case "CHANGES":
            res += `<a class="icon-action"  data-toggle="tooltip" data-placement="top" data-title="Changes" href="/admin/users/${id}/changes" data-original-title="" title=""><i class="fa fa-list-alt" aria-hidden="true"></i></a>`;
            break;
          case "DELETE":
            res += `<span class="icon-action" data-vue-confirm="Are you sure you want to remove selected item?" data-toggle="tooltip" data-placement="top" data-title="Delete" rel="nofollow" data-vue-action="delete" data-id="${id}" data-vue-url="/admin/users/${id}.json"><i class="fa fa-trash text-danger" aria-hidden="true"></i></span>`;
            break;
          case "BLOCK":
            res += `<span class="icon-action icon-action-lock" data-vue-confirm="Are you sure you want to ${active ? 'block':'unblock'} selected user?" data-toggle="tooltip" data-placement="top" data-title="${active ? 'Block':'Unblock'}" rel="nofollow" data-vue-action="block" data-id="${id}" data-vue-url="/admin/users/${id}/${active? 'block': 'unblock'}" data-vue-method="post"><i class="fa fa-ban ${active && 'text-danger'}" aria-hidden="true"></i></span>`;
            break;
        }
      });
      return res;
    },

    dateTimeCellRenderer(params) {
      let res = 'N/A';

      if (params && params.value) {
          res = this.$moment(params.value).utc().format('lll');
      }

      return res;
    },

    branchesCellRenderer(params) {
      const id = params && params.data.id;

      if (id && (params.data.role !== 'admin')) {
        return params && params.value.map(item => `<span class="cell-filter-clickable">${item}</span>`).join(", ");
      }
    },


    onError(error) {
      this.hasError = true;
      this.message =
        (error.data && error.data.message) ||
        "Something went wrong. Please try again";

      this.hideNotification();
    },

    onSuccess(message) {
      this.message = message || "Success.";
      this.hideNotification();
    },

    hideNotification(timeout) {
      setTimeout(() => {
        this.message = null;
        this.hasError = false;
      }, timeout || 5000);
    },
    updateCount() {
      const $el = $(".btn-deleted > span");
      $el.text(($el.text() || 0) - 0 + 1);
    },

    getFilter(filterName, options) {
      const filter = this.fh.find(item => (item.key === filterName));

      return {
        filter: options?.filterType || "agTextColumnFilter",
        floatingFilter: true,
        filterParams: filterParams({
          type: filter.type,
          list: filter.value,
          suppressAndOrCondition: filter.unique || false,
        }),
      }
    }
  },

  computed: {
    requestUrl() {
      return `${window.location.origin}${this.url}`;
    },

    gridState() {
      return location.hash ? qs.parse(location.hash.substring(1)) : {}
    },

    columns() {
      let list = [];
      const optionsList = {
        branch_names: {
          headerName: this.columnsListNames["branch_names"],
          field: "branch_names",
          width: 400,
          cellClass: ['ag-cell-auto'],
          autoHeight: true,
          wrapText: true,
          cellRenderer: this.branchesCellRenderer,
          ...this.getFilter('branch_names')
        },
        email: {
          headerName: this.columnsListNames["email"],
          field: "email",
          minWidth: 200,
          width: 200,
          cellClass: ['ag-cell-email'],
          sortable: true,
          ...this.getFilter('email')
        },
        actions: {
          headerName: this.columnsListNames["actions"],
          field: "actions",
          width: 105,
          minWidth: 105,
          maxWidth: 105,
          cellClass: ["ag-cell-actions", "ag-cell-no-overflow"],
          cellRenderer: this.actionCellRenderer,
          classClass: "actions",
          pinned: "right"
        },
        role: {
          headerName: this.columnsListNames["role"],
          field: "role",
          width: 180,
          maxWidth: 250,
          sortable: true,
          cellRenderer: (data) => (`<span class="cell-filter-clickable">${data && data.value}</span>`),
          ...this.getFilter("role")
        },
        last_login: {
          headerName: this.columnsListNames["last_login"],
          field: "last_login",
          cellClass: ['ag-cell-center', 'ag-cell-auto'],
          autoHeight: true,
          wrapText: true,
          maxWidth: 200,
          sortable: true,
          cellRenderer: this.dateTimeCellRenderer
        }
      };

      this.columnsList.forEach(item => {
        if (this.visibleColumns.indexOf(item) !== -1) {
          list.push(optionsList[item] || []);
        }
      });
      return list;
    }
  }
};
</script>
