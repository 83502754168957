<template>
  <div class="form-group align-items-baseline col px-0 mt-4">
    <label class="form-label" :for="id">
      {{componentProperties.options.label}}
    </label>
    <textarea class="form-control"
           :id="id"
           :readonly="componentProperties.readonly"
           :disabled="componentProperties.readonly"
           :required="componentProperties.options.required"
           v-model="componentProperties.options.value"
           ref="text"/>

    <template v-if="componentProperties.options.hint">
      <div class="small my-1 pr-4 font-italic lh-sm" v-html="componentProperties.options.hint"/>
    </template>
  </div>
</template>

<script>
export default {
  name: 'textInput',
  props: ['options', 'locale', 'id', 'name', 'languageId', 'language', 'type', 'value', 'readonly'],
  computed: {
    opt: {
      get() {
        return {
          required: this.options.required,
          label: this.options.label || '',
          value: this.value || '',
          hint: this.options.hint || '',
        }
      },
      set(v) {},
    },

    componentProperties() {
      return {
        type: this.type,
        is: this.$options._componentTag,
        id: this.id || '',
        locale: this.locale,
        name: this.name,
        languageId: this.languageId,
        readonly: this.readonly,
        options: this.opt,
      };
    },
  },
};
</script>

<style scoped>
</style>
