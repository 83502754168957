<template>
  <div class="form-group row mr-0">
    <div class="col col-3 d-flex align-center justify-center">
      <label for="promo" class="mb-0 text-muted align-self-center">{{label}}* :</label>
    </div>
    <div class="col col-9 pr-0">
      <v-select :options="list"
                v-model="model"
                :disabled="isDisabled"
                :clearable="false"
                label="label"
                id="promo">
        <template v-slot:option="value">
          <div>{{value.description}}</div>
          <span class="product-description">
            {{ value.slug }}
          </span>
        </template>

        <template v-slot:no-options="{ search, searching }">
          <template v-if="searching">
            No results found for <em>{{ search }}</em>.
          </template>
          <em style="opacity: 0.5;" v-else>Start typing to search for a {{label}}</em>
        </template>
      </v-select>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    label: {
      required: false,
      type: String,
      default: "Promotion Name",
    },
    value: {
      required: true,
      type: Number,
    },
    options: {
      required: true,
      type: Array,
    },
    disabled: {
      required: false,
      type: Boolean,
    }
  },

  data() {
    return {
      model: undefined,
    }
  },

  watch: {
    model(value) {
      this.$emit('change', value.id);
    },
  },

  computed: {
    isDisabled() {
      return this.disabled || (this.options.length <= 1);
    },
    list() {
      return this.options.map(item => ({...item, label: `${item.description} (${item.slug})`}))
    }
  },

  mounted() {
    this.$nextTick()
    if (this.value && this.list.length) {
      this.model = this.list.find(item => item.id === this.value);
    }
  },
};
</script>
