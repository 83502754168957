const filterByEqualSelect = ({list, suppressAndOrCondition}) => {
  let filterParams = {};
  if (list?.length) {
    filterParams = {
      filterOptions: [
        "contains",
        ...list.filter(item => !!item)
          .map(param => ({
            displayKey: param,
            displayName: param,
            numberOfInputs: 0,
            predicate: (filterValue, cellValue) => (cellValue?.toLowerCase() === param?.toLowerCase()),
          })),
      ],
      buttons: ['reset'],
      suppressAndOrCondition,
    }
  } else {
    filterParams = {
      filterOptions: ['contains'],
      buttons: ['reset'],
    }
  }

  return {
    filter: "agTextColumnFilter",
    floatingFilter: true,
    filterParams,
  }
};

const filterByPartialSelect = ({list, isArray}) => {
  let filterParams = {};

  if (list?.length) {
    filterParams = {
      filterOptions: [
        "contains",
        ...list.map(param => ({
          displayKey: param,
          displayName: param,
          predicate: (filterValue, cellValue) =>
            isArray
              ? (cellValue?.length && param && cellValue.includes(param))
              : (cellValue?.toLowerCase().indexOf(param.toLowerCase()) !== -1),
          numberOfInputs: 0,
        }))
      ],
      buttons: ['reset']
    };
  } else {
    filterParams = {
      filterOptions: ['contains'],
    }
  }

  return {
    filter: "agTextColumnFilter",
    floatingFilter: true,
    filterParams,
  }
}

const filterByDefault = (options) => ({
  filter: "agTextColumnFilter",
  floatingFilter: true,
  filterParams: {
    filterOptions: options?.filterOptions || ["contains", "startsWith", "notContains"],
    suppressAndOrCondition: options?.suppressAndOrCondition,
    buttons: ['reset']
  }
});

const filterParams = ({type, list, suppressAndOrCondition, defaultFilterOptions}) => {
  let res = {
    suppressAndOrCondition,
    buttons: ['reset'],
  };

  if (type) {
    switch (type) {
      case 'select':
        res = {
          ...res,
          filterOptions: filterByEqualSelect({
            list,
            suppressAndOrCondition,
          }).filterParams.filterOptions,
        };
        break;

      case 'select_partial':
        res = {
          ...res,
          filterOptions: filterByPartialSelect({ list, isArray: true }).filterParams.filterOptions,
        };
        break;

      case 'date':
        res = {
          ...res,
          filterOptions: ["equals", "greaterThan", "lessThan", "inRange"],
        };
        break;

      case 'string':
      case 'key':
      default:
        res = {
          ...res,
          filterOptions: defaultFilterOptions || ["contains", "startsWith", "notContains"]
        };
        break;

    }
  }

  return res; //filterParams
}

export default filterByDefault;

export {
  filterByDefault,
  filterByEqualSelect,
  filterByPartialSelect,

  filterParams,
};
