<template>
  <div class="col pt-2 position-relative" id="dashboard">
    <notification
        v-if="!!failureText"
        successText=""
        :failureText="failureText"
        @close="handleCloseNotification"
    >
    </notification>

    <div v-if="rawPromotions && rawPromotions.length">
      <status-counters-list :selectedBranch="selectedBranch"
                          :selectedProject="selectedProject"
                          :promotions="filteredPromotions"
                          :rawPromotions="rawPromotions"
                          :sortedPromosByStatus="sortedPromosByStatus"
                          :selectedStatus="selectedStatus"
                          :selectedPeriod="period"
                          :showAllMoreFilter="selectedBranch != 'all' || selectedProject != 'all'"
                          @branchFilter="branchFilter"
                          @statusFilter="statusFilter"
                          @projectFilter="projectFilter"
                          @periodFilter="periodFilter"/>
      <template v-if="thresholdRate">
        <hi-error-warn :threshold="threshold"/>
      </template>
      <promotions-list :promotions="filteredPromotions" @branchFilter="branchFilter" @projectFilter="projectFilter"/>
    </div>

    <h3 v-else-if="!loading" class="text-center mt-5">
      No active promotions
    </h3>

    <Loader v-if="loading"/>
  </div>
</template>

<script>
import Vue from 'vue';

import StatusCountersList from './statusCountersList.vue'
import PromotionsList from './promotionsList.vue'
import Loader from '../shared_components/Loader.vue';

import {abilitiesPlugin} from '@casl/vue'
import {Ability} from '@casl/ability'
import HiErrorWarn from "./hiErrorWarn.vue";
import notification from "../../notification.vue";

export const ability = new Ability();
Vue.use(abilitiesPlugin, ability);

export default {
  name: 'dashboard',

  components: {
    notification,
    HiErrorWarn,
    Loader,
    PromotionsList,
    StatusCountersList,
  },

  data() {
    return {
      loading: false,
      promotions: [],
      rawPromotions: [],
      sortedPromosByStatus: {},
      selectedStatus: 'all',
      selectedBranch: 'all',
      selectedProject: 'all',
      statuses: ['active', 'claim', 'support', 'presale'],
      period: '1',
      threshold: null,
      failureText: '',
      notificationTimer: null,
    }
  },

  mounted() {
    this.loadRole();
    this.getStats();
  },

  watch: {
    period(value, oldValue) {
      if (value !== oldValue) {
        this.getStats();
      }
    },
  },

  computed: {
    thresholdRate() {
      return this.threshold ? this.threshold.percentage : 0;
    },


    filteredPromotions() {
      return this.sortByStatusAndDate(this.rawPromotions.filter(promotion => {
        let byProject = this.selectedProject === 'all' || promotion.project.name === this.selectedProject,
            byBranch = this.selectedBranch === 'all' || promotion.branch === this.selectedBranch,
            byStatus = this.selectedStatus === 'all' || promotion.status === this.selectedStatus;

        return byProject && byBranch && byStatus;
      }));
    }

  },

  methods: {
    handleCloseNotification() {
      this.notificationTimer.clearTimeout();
      this.failureText = '';
    },

    fireErrorNotification(message) {
      this.failureText = message;
      if (this.notificationTimer) {
        this.notificationTimer.clearTimeout();
      }

      this.notificationTimer = setTimeout(() => {
        this.failureText = ''
      }, 5000);
    },

    branchFilter: function (branch) {
      this.selectedBranch = branch;
      $('.branch-filter').selectpicker('val', branch);
    },

    statusFilter: function (status) {
      if (status && (this.selectedStatus !== status)) {
        this.selectedStatus = status;
      } else {
        this.selectedStatus = 'all'
      }
    },

    periodFilter(value) {
      this.period = value;
    },

    sortByStatusAndDate: function (promotions) {
      let res = [];

      if (promotions && promotions.length) {
        let group = {};
        const getTime = (item) => new Date(item.endDate && item.endDate.current || null).getTime();

        promotions.forEach(item => {
          const status = item.status;

          if (group[status] && group[status].length) {
            group[status].push(item);
          } else {
            group[status] = [item];
          }
        });

        this.statuses.forEach(name => {
          if (group[name]) {
            res = [
              ...res,
              ...group[name].sort((a,b) => getTime(a) - getTime(b))
            ]
          }
        })
      }

      return res;
    },

    projectFilter: function (project) {
      this.selectedProject = project;
      $('.project-filter').selectpicker('val', project);
    },

    loadRole() {
      this.$http.get(`${window.location.origin}/admin/role.json`)
        .then((response) => {
          if (response.data && response.data.role && (response.data.role === 'admin')) {
            ability.update([{actions: 'read', subject: 'ProjectReport'}])
          }
        }).catch(() => {
          this.fireErrorNotification('Unable receive data');
      });
    },

    getStats() {
      this.loading = true;

      const params = {};
      if (this.period) {
        const now = this.$moment();
        params.to = now.toISOString();
        params.from = now.add(-this.period, 'd').toISOString();
        params.period = this.period;
      }

      this.$http.get('dashboard/stats.json', {params})
        .then(({data}) => {
          const {rawPromotions, lastThreshold} = data;
          this.rawPromotions = rawPromotions;

          this.statuses.forEach(status => {
            this.sortedPromosByStatus[status] = this.rawPromotions.filter(item => item.status === status).length;
          });

          if (lastThreshold) {
            this.threshold = {
              ...lastThreshold,
              percentage: lastThreshold.percentage || lastThreshold.percent,
              errors: lastThreshold.errors || [],
            }
          }
        })
        .catch(() => {
          this.fireErrorNotification('Unable receive data');
        }).finally(() => {
          this.loading = false;
      })
    }
  },
}
</script>
