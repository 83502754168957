<template>
  <div>
    <div class="row">
      <div class="col-md-6">
        <upcInput @upcsChanged="upcsChanged"></upcInput>
        <div v-for="upcItem in upcData" :key="upcItem">
          <correctUpc v-if="upcItem.correct" :key="upcItem" :upcInfo="upcItem"></correctUpc>
          <wrongUpc v-if="!upcItem.correct" :key="upcItem" :upcInfo="upcItem"></wrongUpc>
        </div>
      </div>
      <div class="col-md-6">
        <div class="mb-2">
          <label class="col-form-label">
            Bonus Product
            <span class="text-danger">*</span>
          </label>
          <v-select
            :options="bonusProductsWithLabels"
            v-model="currentBonusProduct"
            label="label"
            ref="bonusProduct"
            @search:blur="assignMutableValue('bonusProduct')"
            @input="assignMutableValue('bonusProduct')"
          >
            <template slot="option" slot-scope="option">
              <div>{{ option.upc }}</div>
              <span class="product-description">{{ option.description }}</span>
            </template>
          </v-select>
        </div>
        <label class="col-form-label">
          Bonus Amount
          <span class="text-danger">*</span>
        </label>
        <div class="input-group">
          <input
            type="number"
            class="form-control"
            aria-label="Text input with dropdown button"
            v-model="bonusAmount"
          >
          <div class="input-group-append">
            <button
              type="button"
              class="btn btn-secondary dropdown-toggle"
              data-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
            >{{currentCurrency}}</button>
            <div class="dropdown-menu dropdown-menu-right">
              <span
                class="dropdown-item"
                href="#"
                @click="currentCurrency = modalData.currency"
              >{{modalData.currency}}</span>
              <span class="dropdown-item" href="#" @click="currentCurrency = '%'">%</span>
            </div>
          </div>
        </div>
        <small v-if="!isAmountValid" class="text-danger">Wrong bonus amount</small>
      </div>
    </div>
    <div class="row">
      <div class="col-md-12">
        <span class="text-danger">*</span>
        <i class="small text-muted">Please fill all required fields</i>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";

import upcInput from "./upcInput";
import correctUpc from "./correctUpc";
import wrongUpc from "./wrongUpc";

export default {
  name: "batchAddRule",
  components: { upcInput, correctUpc, wrongUpc },
  props: ["modalData", "changesAccepted"],
  data: function() {
    return {
      XCRSFToken: document.querySelector("meta[name='csrf-token']").content,
      upcData: [],
      bonusAmount: "",
      currentCurrency: this.modalData.currency || "",
      currentBonusProduct: " ",
      wrongUpcs: [],
      isAmountValid: true
    };
  },
  computed: {
    bonusProducts: function() {
      return this.modalData.products.filter(
        product => product.type === "BonusProduct"
      );
    },
    bonusProductUpcs: function() {
      return this.bonusProducts.map(product => product.upc);
    },
    sponsoringProducts: function() {
      return this.modalData.products.filter(
        product => product.type === "SponsoringProduct"
      );
    },
    correctUpcs: function() {
      return this.upcData
        .filter(upcObject => upcObject.correct)
        .map(upcObject => upcObject.upc);
    },
    correctSponsoringProductIds: function() {
      return this.sponsoringProducts
        .filter(product => this.correctUpcs.includes(product.upc))
        .map(product => product.id);
    },
    bonusProductsWithLabels: function() {
      return this.bonusProducts.map(product => {
        product.label = `${product.upc} ${product.description}`;
        return product;
      });
    },
    batchRules: function() {
      let rules = [];
      this.correctSponsoringProductIds.forEach(id =>
        rules.push({
          type: "FixedRule",
          sponsoring_product_id: id,
          bonus_product_id: this.currentBonusProduct.id,
          bonus_unit: this.currentCurrency == "%" ? "percent" : "amount",
          bonus_amount: this.bonusAmount
        })
      );
      return rules;
    },
    validationPassed: function() {
      return this.correctSponsoringProductIds.length > 0 &&
        this.currentBonusProduct.upc &&
        this.isAmountValid
        ? true
        : false;
    }
  },
  watch: {
    currentBonusProduct: function() {
      this.currentBonusProduct == null
        ? (this.currentBonusProduct = " ")
        : this.currentBonusProduct;
    },
    bonusAmount: function() {
      this.isAmountValid =
        this.bonusAmount <= 0 ||
        (this.bonusAmount > 100 && this.currentCurrency == "%")
          ? false
          : true;
    },
    validationPassed: function() {
      this.$parent.$emit("allowChanges");
    },
    changesAccepted: function() {
      axios
        .post(
          `${window.location.href}/batch_create.json`,
          { batchRules: this.batchRules },
          { headers: { "X-CSRF-Token": this.XCRSFToken } }
        )
        .then(response => {
          this.$parent.$parent.$emit("hideModal");
        });
    }
  },
  methods: {
    assignMutableValue: function(productType) {
      if (this.$refs[productType].mutableValue == null) {
        this.$refs[productType].mutableValue = "  ";
      }
    },
    upcsChanged: function(upcs) {
      upcs = [...new Set(upcs)];
      let validatedUpc = [];
      upcs.forEach(upc => {
        validatedUpc.push(this.validateUpcs(upc));
      });
      this.upcData = validatedUpc;
    },
    validateUpcs: function(upc) {
      let correctProduct = this.sponsoringProducts.find(
        product => product.upc === upc && product.amountType == "fixed_amount"
      );
      let wrongProduct = this.sponsoringProducts.find(
        product => product.upc == upc && product.amountType !== "fixed_amount"
      );
      return correctProduct
        ? {
            upc: upc,
            correct: true,
            description: correctProduct.description,
            amount: correctProduct.amount
          }
        : { upc: upc, amountType: wrongProduct ? wrongProduct.amountType : "" };
    }
  }
};
</script>

<style>
.invalid {
  border: 1px solid rgb(233, 23, 23);
}
span.product-description {
  color: rgb(175, 174, 174);
  font-size: 10px;
}
</style>
