<template>
  <div class="data-table">
    <h2 v-if="title">{{ title }}</h2>

    <notification
      v-if="message"
      :successText="!hasError ? message : ''"
      :failureText="hasError ? message : ''"
    >
    </notification>

    <template v-if="wizardEnabled">
      <integrations-wizard :steps="wizardData"
                           @error="onError"
                           @saved="onSaveNewIntegration"/>
    </template>

    <div class="action-wrapper">
      <div class="row">
        <div class="col col-12">
          <ul class="data-table-columns">
            <li v-for="item in columnsList" :key="item">
              <input
                  :value="item"
                  :id="item"
                  type="checkbox"
                  v-model="visibleColumns"
                  :disabled="disabledColumns.indexOf(item) !== -1 || isLoading"
                  @change="applyColumnDefinition(true)"
              />
              <label :for="item">{{ columnsListNames[item] }}</label>
            </li>
          </ul>
        </div>
      </div>

      <div class="loader" v-if="isLoading"></div>
    </div>

    <div>
      <ag-grid-vue
          style="width: 100%; height: 75vh"
          class="ag-theme-balham"
          @grid-ready="onGridReady"
          :gridOptions="gridOptions"
          :columnDefs="columnDefs"
          :animateRows="true"
          :multiSortKey="'ctrl'"
          :floatingFilter="true"
          :rowClassRules="rowClassRules"
          :suppressDragLeaveHidesColumns="true"
          :enableCellTextSelection="true"
          :rowData="rowData"
      />
    </div>
  </div>
</template>

<script>
import { AgGridVue } from "ag-grid-vue";
import qs from "qs";
import notification from "../notification";
import {isEqual} from "lodash";

import IntegrationsWizard from "./wizard.vue";
import {filterParams} from "../../utils/dataTable";

export default {
  name: "DataTableIntegrations",
  props: {
    title: { type: String, required: false },
    url: { type: String, required: true }
  },
  data() {
    return {
      wizardEnabled: true,
      hasError: false,
      isLoading: false,
      message: null,
      gridOptions: {},
      columnDefs: null,
      rowData: null,
      wizardData: null,
      rowClassRules: null,
      gridCurrentState: {},
      columnsList: [
        "name",
        "success",
        "service",
        "upc",
        "request_type",
        "request_params",
        "time"
      ],
      columnsListNames: {
        name: "Display Name",
        success: "Status",
        service: "Service",
        upc: "UPC or Serial Number",
        request_type: "Request Type",
        request_params: "Request Params",
        time: "Time"
      },
      visibleColumns: [
        "name",
        "success",
        "service",
        "time"
      ],
      disabledColumns: [
        "name",
        "service",
      ],
      UPC_KEYS: ['serial_number', 'Serial number', 'upcs', 'upc'],
    };
  },

  components: {
    AgGridVue,
    IntegrationsWizard,
    notification,
  },

  beforeMount() {
    this.gridCurrentState = {...this.gridState};

    this.gridOptions.defaultColDef = {
      resizable: true
    };

    this.applySavedState(['visibleColumns']);
  },

  mounted() {
    this.gridApi = this.gridOptions.api;
    this.subscribeEvents();
  },

  methods: {
    onFilterStatus(status) {
      if (status && (this.selectedStatus !== status)) {
        this.selectedStatus = status;
      } else {
        this.selectedStatus = null;
      }
    },

    subscribeEvents() {
      this.gridOptions.onGridReady = (e) => {
        this.columnApi = e.columnApi;
        this.gridApi = e.api;
      }

      this.gridOptions.onFilterChanged = (grid) => {
        const filter = grid.api.getFilterModel();
        if (!isEqual(filter, this.gridCurrentState.filter)) {
          this.popSavedState({
            filter
          });
        }
      };

      this.gridOptions.onSortChanged = (grid) => {
        const sort = grid.columnApi.getColumnState()
            .filter(item => !!item.sort)
            .map(item => ({colId: item.colId, sort: item.sort, sortIndex: item.sortIndex}));

        if (!isEqual(sort, this.gridCurrentState.sort)) {
          this.popSavedState({
            sort
          });
        }
      };

      this.gridOptions.onFirstDataRendered = () => {
        this.applySavedState(['filter', 'sort'], true);
      };

      this.gridOptions.onCellClicked = (cell)  => {
        const $parent = cell.event.target.closest('span');
        const {colId} = cell.column;

        switch (colId.replace(/_?\d+$/,'')) {
          case "service":
          case "request_type":
          case "upc_normalized":
            if ($parent && $parent.classList.contains('cell-filter-clickable')) {
              this.applyFilters(colId, $parent.textContent);
            }
            break;

          default:
            break;
        }
      };

      window.addEventListener("resize", () => {
        let w = window.innerWidth;
        setTimeout(() => {
          if (window.innerWidth === w) {
            this.sizeToFit();
          }
        }, 300);
      });
    },

    applySavedState(props, initial) {
      props.forEach(prop => {
        const values = this[initial ? 'gridState' : 'gridCurrentState'][prop];
        if (values) {
          switch (prop) {
            case 'filter':
              this.gridApi.setFilterModel(this.gridCurrentState[prop]);
              this.gridApi.onFilterChanged();
              break;

            case 'sort':
              this.columnApi.applyColumnState({state: this.gridCurrentState[prop]});
              this.gridApi.onSortChanged();
              break;

            case 'visibleColumns':
              this.visibleColumns = this.gridCurrentState[prop];
              break;
          }
        }
      });
    },

    applyFilters(filterBy, text) {
      if (filterBy && text) {
        let filterInstance = this.gridApi.getFilterInstance(filterBy);

        filterInstance.setModel({
          type: "contains",
          filter: text
        });

        this.gridApi.onFilterChanged();
      }
    },

    popSavedState(state) {
      this.gridCurrentState = {
        ...this.gridCurrentState,
        ...state
      };
      location.hash = qs.stringify(this.gridCurrentState);
    },

    applyTooltips() {
      $('[data-toggle="tooltip"]').tooltip();
    },

    applyPlaceholders() {
      $('input[ref="eFloatingFilterText"], input[ref="eInput"]').each(function() {
        $(this).attr("placeholder", "Filter...");
      });
    },

    sizeToFit() {
      this.gridApi.sizeColumnsToFit();
    },

    applyColumnDefinition() {
      this.columnDefs = this.columns;
      this.popSavedState({visibleColumns: this.visibleColumns});

      setTimeout(() => {
        this.applyTooltips();
        this.applyPlaceholders();
        this.sizeToFit();
      }, 1);
    },

    onGridReady() {
      this.isLoading = true;

      this.$http({
        method: "get",
        url: this.requestUrl
      }).then(
        response => {
          this.fh = response.data.filters;
          this.applyColumnDefinition();
          this.rowData = response.data.list.map((item) => {
            let upc_normalized = [];

            this.UPC_KEYS.forEach(key => {
              if (item.request_params && item.request_params[key]) {
                upc_normalized = upc_normalized.concat(item.request_params[key] || []);
              }
            })

            return {
              ...item,
              upc_normalized,
            };
          });
          this.wizardData = response.data.steps
        },
        error => {
          this.onError(error);
        }
      ).finally(() => {
        this.isLoading = false;
      });
    },

    statusCellRenderer(params) {
      return params && params.value ? '<span class="text-success">Success</span>' : '<span class="text-danger">Failure</span>';
    },

    nameCellRenderer(params) {
      const id = params && params.data && params.data.id;

      if (id) {
        return `<a data-toggle="tooltip" data-placement="top" data-title="Show" href="/admin/integrations/${id}">${params.value} <i class="fa fa-eye"></i></a>`
      }
    },

    dateTimeCellRenderer(params) {
      let res = 'N/A';

      if (params && params.value) {
        res = `${this.$moment(params.value).utc().format('lll')} UTC`;
      }

      return res;
    },

    upcCellRenderer(params) {
      let res = 'N/A';

      if (params && params.value) {
        res = params.value.map(item => (`<span class="cell-filter-clickable">${item}</span>`)).join(', ');
      }

      return res;
    },

    onError(error) {
      this.hasError = true;
      this.message =
        (error.data && error.data.message) ||
        "Something went wrong. Please try again";

      this.hideNotification();
    },

    onSuccess(message) {
      this.message = message || "Success.";
      this.hideNotification();
    },

    hideNotification(timeout) {
      setTimeout(() => {
        this.message = null;
        this.hasError = false;
      }, timeout || 5000);
    },

    onSaveNewIntegration(value, message) {
      const index = this.rowData.findIndex(item => item.id === value.id);
      const msg = message || (index !== -1 ? `Record "${value.name}" updated` : `New record "${value.name}" added successfully`);

      if (index !== -1) {
        this.rowData[index] = value;
        this.gridApi.getRowNode(index).setData(value);
      } else {
        this.rowData.unshift(value);
      }
      this.gridApi.redrawRows();

      this.onSuccess(msg);
    },

    getFilter(filterName, options) {
      const filter = this.fh.find(item => (item.key === filterName));

      return {
        filter: options?.filterType || "agTextColumnFilter",
        floatingFilter: true,
        filterParams: filterParams({
          type: filter.type,
          list: filter.value,
          suppressAndOrCondition: filter.unique || false,
        }),
      }
    },
  },

  computed: {
    requestUrl() {
      return `${window.location.origin}${this.url}`;
    },

    gridState() {
      return location.hash ? qs.parse(location.hash.substring(1)) : {}
    },

    columns() {
      let list = [];
      const optionsList = {
        upc: {
          headerName: this.columnsListNames["upc"],
          field: "upc_normalized",
          minWidth: 150,
          cellClass: ['ag-cell-auto'],
          autoHeight: true,
          wrapText: true,
          cellRenderer: this.upcCellRenderer,
          sortable: true,
          ...this.getFilter('request_params'),
        },

        success: {
          headerName: this.columnsListNames["success"],
          field: "success",
          sortable: false,
          minWidth: 80,
          cellRenderer: this.statusCellRenderer
        },

        name: {
          headerName: this.columnsListNames["name"],
          field: "name",
          minWidth: 150,
          cellClass: ['ag-cell-auto'],
          autoHeight: true,
          wrapText: true,
          cellRenderer: this.nameCellRenderer,
          sortable: true,
          ...this.getFilter('name')
        },

        service: {
          headerName: this.columnsListNames["service"],
          field: "service",
          cellRenderer: (data) => (`<span class="cell-filter-clickable">${data && data.value}</span>`),
          minWidth: 100,
          sortable: true,
          ...this.getFilter('service')
        },

        request_type: {
          headerName: this.columnsListNames["request_type"],
          field: "request_type",
          minWidth: 150,
          cellRenderer: (data) => (`<span class="cell-filter-clickable">${data && data.value}</span>`),
          sortable: true,
          ...this.getFilter('request_type')
        },

        request_params: {
          headerName: this.columnsListNames["request_params"],
          field: "request_params",
          minWidth: 300,
          cellClass: ['ag-cell-auto'],
          autoHeight: true,
          wrapText: true,
          cellRenderer: (data) => (`<span class="pre mb-0">${data && data.value? JSON.stringify(data.value, null, 2) : ''}</span>`),
        },

        time: {
          headerName: this.columnsListNames["time"],
          field: "time",
          cellClass: ['ag-cell-center', 'ag-cell-auto'],
          autoHeight: true,
          wrapText: true,
          maxWidth: 200,
          minWidth: 150,
          sortable: true,
          cellRenderer: this.dateTimeCellRenderer
        }
      };

      this.columnsList.forEach(item => {
        if (this.visibleColumns.indexOf(item) !== -1) {
          list.push(optionsList[item] || []);
        }
      });
      return list;
    }
  }
};
</script>
