<template>
  <div class="multiple-hint">
    <span class="multiple-hint_icon fa fa-info"/>
    <div class="multiple-hint_details bg-white pa-2">
      <table class="table table-sm table-hover">
        <thead>
          <tr>
            <th class="text-left">Serial Number</th>
            <th class="align-right text-nowrap">Card Denom</th>
          </tr>
        </thead>
        <tbody>
        <tr v-for="(item, idx) in list[0]" :key="item[0]">
          <td class="text-left">
            <span :class="`${clickable ? 'cell-filter-clickable': ''} text-nowrap`" v-text="item"/>
          </td>
          <td class="text-right text-nowrap"
              v-text="list[1][idx] || ''"
          />
        </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    serials: {
      required: false,
      type: String,
      default: '',
    },

    values: {
      required: false,
      type: String,
      default: '',
    },
    clickable: {
      required: false,
      type: Boolean,
      default: false,
    }
  },

  computed: {
    list() {
      let res = [];
      ['serials', 'values'].forEach((key, idx) => {
        if (this[key]?.length) {
          res[idx] = this[key].split(';');
        }
      });

      return res;
    }
  },
}
</script>
