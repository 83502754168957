<template>
  <select name="values" id="values" class="form-control" v-model="inputVal">
    <option
        v-for="opt in Object.entries(options)"
        :key="opt[0]"
        :value="opt[1]">
      {{opt[0]}}
    </option>
  </select>
</template>

<script>
export default {
  props: {
    value: {
      required: true,
      type: [String, Number]
    }
  },
  data() {
    return {
      options: {
        'Whole Promotion Period': null,
        'Last 24h': '1',
        'Last 48h': '2',
        'last 3 days': '3',
        'last week': '7',
        'last month': '30',
      }
    }
  },

  computed: {
    inputVal: {
      get() {
        return this.value || null;
      },
      set(val) {
        this.$emit('input', val);
      }
    }
  }
}
</script>
