<template>
  <div>
    <div class="row">
      <div class="col-md-6">
        <label class="col-form-label">
          Amount
          <span class="text-danger">*</span>
        </label>
        <input type="text" class="form-control" v-model="sponsoringAmount" :disabled="true">
      </div>
      <div class="col-md-6">
        <label class="col-form-label">
          Bonus Amount
          <span class="text-danger">*</span>
        </label>
        <div class="input-group">
          <input
            type="number"
            class="form-control"
            aria-label="Text input with dropdown button"
            v-model="bonusAmount"
            v-bind:class="{invalid: !isAmountValid }"
          >
          <div class="input-group-append">
            <button
              type="button"
              class="btn btn-secondary dropdown-toggle"
              data-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
            >{{currentCurrency}}</button>
            <div class="dropdown-menu dropdown-menu-right">
              <span class="dropdown-item" @click="() => setBonusUnit(currency)">{{currency}}</span>
              <span class="dropdown-item" @click="() => setBonusUnit('%')">%</span>
            </div>
          </div>
        </div>
        <small v-if="!isAmountValid" class="text-danger">Wrong bonus amount</small>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "fixedAmount",
  props: {
    sponsoringAmount: { type: String, required: true, default: "" },
    bonusAmount: { type: String },
    currency: { type: String, required: true },
    currentCurrency: { type: String, required: true }
  },
  data: function() {
    return {
      currentCurrency: this.currentCurrency || "",
      isAmountValid: true
    };
  },
  watch: {
    bonusAmount: function() {
      this.$emit("setBonusAmount", this.bonusAmount);
      this.validateAmount();
    }
  },
  methods: {
    validateAmount: function() {
      this.isAmountValid =
        (!this.bonusAmount && this.bonusAmount == "") ||
        this.bonusAmount < 0 ||
        (this.bonusAmount > 100 && this.currentCurrency) == "%"
          ? false
          : true;
      this.$emit("validateAmount", this.isAmountValid);
    },

    setBonusUnit(val) {
      if (val === '%') {
        this.currentCurrency = '%';
        this.$emit('setBonusUnit', 'percent')
      } else {
        this.currentCurrency = this.currency;
        this.$emit('setBonusUnit', 'amount')
      }

      this.validateAmount();
    }

  }
};
</script>

<style scoped>
.invalid {
  border: 1px solid rgb(233, 23, 23);
}
</style>
