<template>
  <div>
    <div class="row">
      <div class="col-md-6">
        <div class="form-group mr-0">
          <label class="col-form-label">
            Sponsoring Product
            <span class="text-danger">*</span>
          </label>
          <v-select
              :options="sponsoringProductsWithLabels"
              v-model="currentSponsoringProduct"
              label="label"
              ref="sponsoringProduct"
              @search:blur="assignMutableValue('sponsoringProduct')"
              @input="assignMutableValue('sponsoringProduct')"
          >
            <template slot="option" slot-scope="option">
              <div>{{ option.upc }}</div>
              <span class="product-description">{{ option.description }}</span>
            </template>
          </v-select>
        </div>
      </div>
      <div class="col-md-6">
        <label class="col-form-label">
          Bonus Product
          <span class="text-danger">*</span>
        </label>
        <v-select
            :options="bonusProductsWithLabels"
            v-model="currentBonusProduct"
            label="label"
            ref="bonusProduct"
            @search:blur="assignMutableValue('bonusProduct')"
            @input="assignMutableValue('bonusProduct')"
        >
          <template slot="option" slot-scope="option">
            <div>{{ option.upc }}</div>
            <span class="product-description">{{ option.description }}</span>
          </template>
        </v-select>
      </div>
    </div>
    <fixed-amount
        v-if="currentSponsoringProduct && currentSponsoringProduct.amountType === 'fixed_amount'"
        :sponsoringAmount="currentSponsoringProduct.amount"
        :bonusAmount="bonusAmount || ''"
        :currentCurrency="modalData.rule && !Array.isArray(modalData.rule.amount) ? modalData.rule.amount.currency : modalData.currency"
        :currency="modalData.currency"
        @setBonusAmount="setBonusAmount"
        @setBonusUnit="setBonusUnit"
        @validateAmount="validateAmount"
    />
    <tires
        v-else-if="currentSponsoringProduct && currentSponsoringProduct.amountType === 'variable_amount'"
        :minAmount="currentSponsoringProduct.amount.start"
        :maxAmount="currentSponsoringProduct.amount.end"
        :currency="modalData.currency"
        :tiersData="modalData.rule ? modalData.rule.amount : []"
        @setTiers="setTiers"
        @setTiersValidity="setTiersValidity"
        @validateAmount="validateAmount"
    />

    <template v-if="modalData.presale">
      <div class="row presale-fields">
        <div class="col-4 pt-4">
          <div class="form-group">
            <label class="mb-1 col-form-label">Street date</label>
            <datepicker v-model="street_date"
                        @input="(value) => changeDate(value)"
                        format="dd/MM/yyyy"
                        input-class="form-control date-picker-input"
                        placeholder="Choose date"
                        :clear-button="true"/>
          </div>
        </div>

        <div class="col-4 pt-4">
          <div class="form-group">
            <label class="mb-1 col-form-label" for="street_timezone">Street timezone</label>
            <select name="street_timezone"
                    id="street_timezone"
                    class="custom-select"
                    v-model="street_timezone">
              <option v-for="item in timezoneList"
                      :selected="item.value === street_timezone"
                      :key="item.value"
                      :value="item.value"
                      v-text="item.label"/>
            </select>
          </div>
        </div>

        <div class="col-12 pt-2">
          <div class="form-group">
            <label for="primary" class="mb-1 label">
              <input type="checkbox" name="primary" id="primary" v-model="primary"/>
              Primary rule
            </label>
          </div>
        </div>
      </div>
    </template>

    <template v-else-if="modalData.secondaryRule">
      <div class="row presale-fields my-4">
        <div class="col-4">
          <label class="mb-1 col-form-label">
            Rule Importance
            <span class="text-danger">*</span>
          </label>
          <select class="form-control"
                  required
                  v-model="secondary.importance">
            <template v-for="entry in ruleImportanceList">
              <option :value="entry" :key="entry">{{ capitalize(entry) }}</option>
            </template>
          </select>
        </div>

        <div class="col-4">
          <label class="mb-1 col-form-label">
            Activation Start Date
            <span class="text-danger">*</span>
          </label>
          <datepicker v-model="secondary.startDate"
                      format="dd/MM/yyyy"
                      :input-class="`form-control date-picker-input ${errors.activation_start_date ? 'is-invalid' : ''}`"
                      placeholder="Choose date"
                      :clear-button="false"
                      @input="hideError('activation_start_date')"
          />
          <small class="text-muted">{{secondary.startDateTZ || secondary.tzFallbackText }}</small>
          <template v-if="errors.activation_start_date">
            <div class="invalid-feedback">{{errors.activation_start_date.join(', ')}}</div>
          </template>
        </div>

        <div class="col-4">
          <label class="mb-1 col-form-label">
            Activation End Date
            <span class="text-danger">*</span>
          </label>
          <datepicker v-model="secondary.endDate"
                      format="dd/MM/yyyy"
                      :input-class="`form-control date-picker-input ${errors.activation_end_date ? 'is-invalid' : ''}`"
                      placeholder="Choose date"
                      :clear-button="false"
                      @input="hideError('activation_end_date')"
          />
          <small class="text-muted">{{secondary.endDateTZ || secondary.tzFallbackText }}</small>
          <template v-if="errors.activation_end_date">
            <div class="invalid-feedback">{{errors.activation_end_date.join(', ')}}</div>
          </template>
        </div>

        <div class="offset-4"></div>

        <div class="col-4">
          <label class="mb-1 col-form-label">
            Claim Start Date
          </label>
          <datepicker v-model="secondary.claimStart"
                      format="dd/MM/yyyy"
                      :input-class="`form-control date-picker-input ${errors.claim_start_date ? 'is-invalid' : ''}`"
                      placeholder="Choose date"
                      :clear-button="false"
                      @input="hideError('claim_start_date')"
          />
          <small class="text-muted">{{secondary.claimStartTZ || secondary.tzFallbackText }}</small>
          <template v-if="errors.claim_start_date">
            <div class="invalid-feedback">{{errors.claim_start_date.join(', ')}}</div>
          </template>
        </div>

        <div class="col-4">
          <label class="mb-1 col-form-label">
            Claim End Date
            <span class="text-danger">*</span>
          </label>
          <datepicker v-model="secondary.claimEnd"
                      format="dd/MM/yyyy"
                      :input-class="`form-control date-picker-input ${errors.claim_end_date ? 'is-invalid' : ''}`"
                      placeholder="Choose date"
                      :clear-button="false"
                      @input="hideError('claim_end_date')"
          />
          <small class="text-muted">{{secondary.claimEndTZ || secondary.tzFallbackText }}</small>
          <template v-if="errors.claim_end_date">
            <div class="invalid-feedback">{{errors.claim_end_date.join(', ')}}</div>
          </template>
        </div>
      </div>
    </template>

    <div class="row">
      <div class="col-md-12">
          <span class="text-danger">*</span>
          <i class="small text-muted">
            Please fill all required fields<template v-if="modalData.secondaryRule">; <span class="d-block ml-2">{{secondaryHint}}</span></template>
          </i>
      </div>
    </div>
  </div>
</template>

<script>
import Datepicker from 'vuejs-datepicker';

import fixedAmount from "./fixedAmount";
import tires from "./tires";
import getTimezoneList from '../../../../utils/timezoneSelectOptions';
import {capitalize, get} from 'lodash';

export default {
  name: "rulesForm",
  props: ["modalData", "changesAccepted"],
  components: {
    "fixed-amount": fixedAmount,
    Datepicker,
    tires
  },
  data: function() {
    return {
      ruleImportanceList: ['main', 'additional'],
      currentSponsoringProduct: " ",
      currentBonusProduct: " ",
      bonusUnit: "",
      bonusAmount: 0,
      tires: [],
      validAmount: false,
      timezoneList: getTimezoneList(),

      street_date: null,
      street_timezone: this.modalData.branchTimezone,
      primary: false,

      secondary: {
        startDate: null,
        startDateTZ: null,
        endDate: null,
        endDateTZ: null,
        claimStart: null,
        claimStartTZ: null,
        claimEnd: null,
        claimEndTZ: null,
        importance: null,

        tzFallbackText: 'Nested from the Promotion settings'
      },

      errors: {},
    };
  },
  computed: {
    XCRSFToken() {
      return document.querySelector("meta[name='csrf-token']").content;
    },
    promotion() {
      return this.modalData.promotion;
    },
    bonusProducts: function() {
      return this.modalData.products.filter((product) => {
        if (this.modalData.presale)
          return product.type === 'PresaleProduct';
        else if (this.modalData.external)
          return product.type === 'ExternalProduct' || product.type === 'BonusProduct';
        else
          return product.type === 'BonusProduct';
      });
    },
    sponsoringProducts: function() {
      return this.modalData.products.filter(
        product => product.type === "SponsoringProduct"
      );
    },
    currentBonusProduct: function() {
      return this.currentBonusProduct ? this.currentBonusProduct.upc : "";
    },
    ruleType: function() {
      let type = `${this.currentSponsoringProduct.amountType === "variable_amount" ? 'VariableRule': 'FixedRule'}`;
      if (this.modalData.ddsRule) type = `DDS${type}`;
      else if (this.modalData.secondaryRule) type = `Secondary${type}`;
      return type;
    },
    sponsoringProductsWithLabels: function() {
      return this.sponsoringProducts.map(product => {
        product.label = `${product.upc} ${product.description}`;
        return product;
      });
    },
    bonusProductsWithLabels: function() {
      return this.bonusProducts.map(product => {
        product.label = `${product.upc} ${product.description}`;
        return product;
      });
    },
    rule() {
      const {rule} = this.modalData;

      let ruleData = {
        id: rule?.id || "",
        type: this.ruleType,
        sponsoring_product_id: this.currentSponsoringProduct.id || "",
        bonus_product_id: this.currentBonusProduct.id || "",
        position: this.modalData.position || "",

        presale: this.modalData.presale,
        street_date: this.street_date,
        street_timezone: this.street_timezone,
        primary: this.primary,
      };

      if (this.ruleType.includes("FixedRule")) {
        ruleData.bonus_amount = this.bonusAmount || 0;
        ruleData.bonus_unit = this.bonusUnit || "amount";
      } else {
        ruleData.tiers_attributes = this.tires;
      }

      if (this.modalData.secondaryRule) {
        ruleData.secondary = true;
        ruleData.activation_start_date = this.$moment(this.secondary.startDate).format('YYYY-MM-DD');
        ruleData.activation_end_date = this.$moment(this.secondary.endDate).format('YYYY-MM-DD');
        ruleData.claim_start_date = this.secondary.claimStart && this.$moment(this.secondary.claimStart).format('YYYY-MM-DD');
        ruleData.claim_end_date = this.$moment(this.secondary.claimEnd).format('YYYY-MM-DD');
        ruleData.importance = this.secondary.importance;
      }

      return ruleData;
    },
    validationPassed() {
      return !!(
        this.currentSponsoringProduct.upc &&
        this.currentBonusProduct.upc &&
        ((this.ruleType.includes("FixedRule") &&
          this.bonusAmount &&
          this.validAmount) ||
          (this.ruleType.includes("VariableRule") && this.validAmount))
        && (this.modalData.secondaryRule ? (this.secondary.startDate && this.secondary.endDate &&
                                            this.secondary.claimEnd && this.secondary.importance &&
                                            !Object.values(this.errors).length)
                                         : true)
      );
    },

    secondaryHint() {
      const dates = ['activation_start_datetime_with_timezone', 'activation_end_datetime_with_timezone', 'claim_end_datetime_with_timezone']
          .map(item => this.promotion[item].split('T')[0]);

      return `All dates should be within the promotion activation period (${dates[0]} UTC – ${dates[1]} UTC), the claim end date is ${dates[2]} UTC`;
    }
  },
  mounted() {
    if (this.modalData.rule) {
      this.currentSponsoringProduct = this.modalData.products.filter(
        product => product.id === this.modalData.rule.sponsoringProductId
      )[0];
      this.currentBonusProduct = this.modalData.products.filter(
        product => product.id === this.modalData.rule.bonusProductId
      )[0];
      this.bonusAmount = this.modalData.rule.amount.value;
      this.validAmount = true;
      this.tires = this.modalData.rule.amount;
      this.bonusUnit = this.modalData.rule.amount.currency === "%" ? "percent" : "amount";

      this.primary = this.modalData.rule.primary;
      this.street_date = this.modalData.rule.street_date ? this.$moment.tz(this.modalData.rule.street_date, this.modalData.rule.street_timezone).format() : null;
      this.street_timezone = this.modalData.rule.street_timezone;
      this.branch_timezone = this.modalData.branchTimezone;

      // secondary rules dates and timezones already exist
      const { activationStartDate, activationStartTZ, activationEndDate, activationEndTZ,
              claimStartDate, claimStartTZ, claimEndDate, claimEndTZ, importance } = this.modalData.rule;

      if (importance) {
        this.secondary.importance = importance;
      }

      if (activationStartDate) {
        this.secondary.startDateTZ = activationStartTZ;
        this.secondary.startDate = new Date(this.$moment(activationStartDate).format());
      }

      if (activationEndDate) {
        this.secondary.endDateTZ = activationEndTZ;
        this.secondary.endDate = new Date(this.$moment(activationEndDate).format());
      }

      if (claimStartDate) {
        this.secondary.claimStart = new Date(this.$moment(claimStartDate).format());
      }

      if (claimStartTZ) {
        this.secondary.claimStartTZ = claimStartTZ;
      }

      if (claimEndDate) {
        this.secondary.claimEndTZ = claimEndTZ;
        this.secondary.claimEnd = new Date(this.$moment(claimEndDate).format());
      }
    } else if (this.modalData.secondaryRule) {
      // set promotion's values for the NEW rule
      const {activation_start_timezone, activation_end_timezone, claim_end_timezone} = this.modalData.promotion;

      this.secondary.startDateTZ = activation_start_timezone;
      this.secondary.endDateTZ = activation_end_timezone;
      this.secondary.claimStartTZ = activation_start_timezone;
      this.secondary.claimEndTZ = claim_end_timezone;
    }
  },
  watch: {
    currentSponsoringProduct() {
      this.currentSponsoringProduct == null
        ? (this.currentSponsoringProduct = " ")
        : this.currentSponsoringProduct;
    },
    currentBonusProduct() {
      this.currentBonusProduct == null
        ? (this.currentBonusProduct = " ")
        : this.currentBonusProduct;
    },
    validationPassed() {
      this.validationPassed
        ? this.$parent.$emit("allowChanges", true)
        : this.$parent.$emit("allowChanges", false);
    },
  },
  methods: {
    capitalize,
    acceptChanges() {
      const url = !this.rule.id ? `${window.location.href}.json` : `${window.location.href}/${this.rule.id}.json`;
      const method = !this.rule.id ? 'post' : 'patch';

      this.$http[method](
          url,
          {rule: this.rule},
          {headers: {"X-CSRF-Token": this.XCRSFToken}}
        ).then(() => {
            this.$parent.$parent.$emit("hideModal");
        }, this.handleError);
    },
    hideError(key) {
      Vue.delete(this.errors, key);
    },
    handleError(e) {
      this.errors = get(e, 'response.data.errors', {});
    },
    assignMutableValue(productType) {
      if (this.$refs[productType].mutableValue == null) {
        this.$refs[productType].mutableValue = "  ";
      }
    },
    setBonusAmount(newBonusAmount) {
      this.bonusAmount = newBonusAmount;
    },
    setBonusUnit(unit) {
      this.bonusUnit = unit;
    },
    setTiers(tiers) {
      this.tires = tiers;
    },
    validateAmount(validAmount) {
      this.validAmount = validAmount;
    },
    changeDate(value) {
      if (value == null) {
        this.street_timezone = this.modalData.branchTimezone;
      }
    },
  }
};
</script>

<style lang="scss">
  .presale-fields {
    .vdp-datepicker__clear-button, .vdp-datepicker__calendar-button {
      position: absolute;
      top: 0;
      right: 0;
      padding: 4px 16px;
      color: #9d9d9d;
      font-weight: bold;

      i {
        font-style: normal;
        font-size: 1.4em;
      }
    }
  }
</style>
