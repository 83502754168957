<template>
  <div class="data-table">
    <h2 v-if="title">{{ title }}</h2>

    <notification
      v-if="message"
      :successText="!hasError ? message : ''"
      :failureText="hasError ? message : ''"
    >
    </notification>

    <div class="action-wrapper">
      <div class="row">
        <div class="col col-12">
          <ul class="data-table-columns">
            <li v-for="item in columnsList" :key="item">
              <input
                :value="item"
                :id="item"
                type="checkbox"
                v-model="visibleColumns"
                :disabled="disabledColumns.indexOf(item) !== -1 || isLoading"
                @change="applyColumnDefinition(true)"
              />
              <label :for="item">{{ columnsListNames[item] }}</label>
            </li>
          </ul>
        </div>
      </div>

    <div class="loader" v-if="isLoading"></div>
  </div>


    <div>
      <ag-grid-vue
          style="width: 100%; height: 76vh"
          class="ag-theme-balham"
          @grid-ready="onGridReady"
          :gridOptions="gridOptions"
          :columnDefs="columnDefs"
          :animateRows="true"
          :multiSortKey="'ctrl'"
          :rowClassRules="rowClassRules"
          :suppressDragLeaveHidesColumns="true"
          :enableCellTextSelection="true"
          :rowData="rowData"
      />
    </div>
  </div>
</template>

<script>
import { AgGridVue } from "ag-grid-vue";
import axios from "axios";
import qs from "qs";
import { setTimeout } from "timers";
import notification from "../notification";
import {isEqual, without} from "lodash";
import { filterParams } from "../../utils/dataTable";

export default {
  name: "DataTableBranches",
  props: {
    title: { type: String, required: false },
    url: { type: String, required: true },
    deleted: { type: [String], required: true },
    isAdmin: { type: [Boolean], required: true }
  },
  data() {
    return {
      hasError: false,
      isLoading: false,
      message: null,
      gridOptions: {},
      columnDefs: null,
      rowData: null,
      rowClassRules: null,
      divider: 3,
      gridCurrentState: {},
      columnsList: [
        "name",
        "merchants",
        "supervisors",
        "managers",
        "languages",
        "currency_code",
        "promotions",
        "created_at",
        "updated_at",
        "timezone",
        "actions"
      ],
      columnsListNames: {
        "name": "Branch Name",
        "merchants": "Merchants",
        "supervisors": "Supervisors",
        "managers": "Managers",
        "languages": "Languages",
        "currency_code": "Currency",
        "promotions": "Promotions",
        "created_at": "Added On",
        "updated_at": "Updated On",
        "timezone": "Timezone",
        "actions": "Actions"
      },
      visibleColumns: [
        "name",
        "merchants",
        "supervisors",
        "languages",
        "actions"
      ],
      disabledColumns: [
        "name",
        "merchants",
        "supervisors",
        "actions"
      ]
    };
  },


  components: {
    AgGridVue,
    notification
  },

  beforeMount() {
    this.gridCurrentState = {...this.gridState};

    this.gridOptions.defaultColDef = {
      resizable: true
    };

    if (!this.isAdmin) {
      this.columnsList = without(this.columnsList, 'supervisors', 'managers');
      this.visibleColumns = without(this.visibleColumns, 'supervisors', 'managers');
      this.disabledColumns = without(this.disabledColumns, 'supervisors', 'managers');
    }

    this.applySavedState(['visibleColumns']);
  },

  mounted() {
    this.gridApi = this.gridOptions.api;
    this.subscribeEvents();
  },

  methods: {
    onFilterStatus(status) {
      if (status && (this.selectedStatus !== status)) {
        this.selectedStatus = status;
      } else {
        this.selectedStatus = null;
      }
    },

    subscribeEvents() {
      this.gridOptions.onGridReady = (e) => {
        this.columnApi = e.columnApi;
        this.gridApi = e.api;
      }

      this.gridOptions.onFilterChanged = (grid) => {
        const filter = grid.api.getFilterModel();
        if (!isEqual(filter, this.gridCurrentState.filter)) {
          this.popSavedState({
            filter
          });
        }
      };

      this.gridOptions.onSortChanged = (grid) => {
        const sort = grid.columnApi.getColumnState()
            .filter(item => !!item.sort)
            .map(item => ({colId: item.colId, sort: item.sort, sortIndex: item.sortIndex}));

        if (!isEqual(sort, this.gridCurrentState.sort)) {
          this.popSavedState({
            sort
          });
        }
      };

      this.gridOptions.onFirstDataRendered = () => {
        this.applySavedState(['filter', 'sort'], true);
      };

      this.gridOptions.onCellClicked = (cell)  => {
        const $parent = cell.event.target.closest('span');
        const {colId} = cell.column;

        switch (colId.replace(/_?\d+$/,'')) {
          case "branch_names":
            if ($parent && $parent.classList.contains('cell-filter-clickable')) {
              this.applyFilters(colId, $parent.textContent);
            }
            break;

          case "actions":
            if ($parent && $parent.dataset && $parent.dataset["vueAction"]) {
              this.proceedAction($parent.dataset);
            }
            break;

          default:
            break;
        }
      };

      window.addEventListener("resize", () => {
        let w = window.innerWidth;
        setTimeout(() => {
          if (window.innerWidth === w) {
            this.sizeToFit();
          }
        }, 300);
      });
    },

    applySavedState(props, initial) {
      props.forEach(prop => {
        const values = this[initial ? 'gridState' : 'gridCurrentState'][prop];
        if (values) {
          switch (prop) {
            case 'filter':
              this.gridApi.setFilterModel(this.gridCurrentState[prop]);
              this.gridApi.onFilterChanged();
              break;

            case 'sort':
              this.columnApi.applyColumnState({state: this.gridCurrentState[prop]});
              this.gridApi.onSortChanged();
              break;

            case 'visibleColumns':
              this.visibleColumns = this.gridCurrentState[prop];
              break;
          }
        }
      });
    },

    applyFilters(filterBy, text) {
      if (filterBy && text) {
        let filterInstance = this.gridApi.getFilterInstance(filterBy);

        filterInstance.setModel({
          type: "contains",
          filter: text
        });

        this.gridApi.onFilterChanged();
      }
    },

    popSavedState(state) {
      this.gridCurrentState = {
        ...this.gridCurrentState,
        ...state
      };
      location.hash = qs.stringify(this.gridCurrentState);
    },

    proceedAction(dataset) {
      dataConfirmModal.confirm({
        backdrop: true,
        keyboard: false,
        show: true,
        title: dataset.title || "Please confirm",
        text: dataset.vueConfirm || "Are you sure you want to continue?",
        commit: "Yes",
        cancel: "Back",
        onConfirm: () => {
          axios({
            headers: {
              "X-CSRF-Token": $('meta[name="csrf-token"]').attr("content")
            },
            method: dataset.vueMethod || "delete",
            url: dataset.vueUrl
          }).then(() => {
              this.rowData = this.rowData.filter(
                  item => item.id !== dataset.id - 0
                );
                this.updateCount();
                this.onSuccess("Branch removed successfully.");
            },
            error => {
              this.onError(error);
            }
          );
        },
        onCancel: function() {
          return false;
        }
      });
    },

    applyTooltips() {
      $('[data-toggle="tooltip"]').tooltip();
    },

    applyPlaceholders() {
      $('input[ref="eFloatingFilterText"], input[ref="eInput"]').each(function() {
        $(this).attr("placeholder", "Filter...");
      });
    },

    clearFilterSelections() {
      const $list = $('input[ref="eFloatingFilterText"], input[ref="eInput"]');
      setTimeout(() => {
        // to make sure the after reset filters
        $list.each(function() {
          if (!$(this).val()) {
            $(this).removeAttr("disabled");
          }
        });
      }, 600);
    },

    sizeToFit() {
      if (this.visibleColumns.length <= 5) {
        this.gridApi.sizeColumnsToFit();
      }
    },

    applyColumnDefinition() {
      this.columnDefs = this.columns;
      this.popSavedState({visibleColumns: this.visibleColumns});

      setTimeout(() => {
        this.applyTooltips();
        this.applyPlaceholders();
        this.sizeToFit();
      }, 1);
    },

    onGridReady() {
      let params = {};
      const isDeleted = Boolean(this.deleted - 0);
      if (isDeleted) {
        params.deleted = isDeleted;
      }

      this.isLoading = true;

      axios({
        method: "get",
        url: this.requestUrl,
        params
      }).then(
        response => {
          this.fh = response.data.filters;

          let list = response.data.list;
          // store initial value into new column for filtering

          const byKeys = this.fh.filter(item => (item.type ==='key'));
          list = list.map(item => {
            byKeys.forEach(byKey => {
              if (item[byKey.key]) {
                item[`${byKey.key}__init`] = [...item[byKey.key]];
                item[byKey.key] = item[`${byKey.key}__init`].map(l => (l[byKey.value]));
              }
            });
            return item;
          });

          this.rowData = list;
          this.applyColumnDefinition();
        },
        error => {
          this.onError(error);
        }
      ).finally(() => {
        this.isLoading = false;
      });
    },

    actionCellRenderer(params) {
      const { id } = params.data;
      let res = "";

      params.value.forEach(action => {
        switch (action.toUpperCase()) {
          case "READ":
            res += `<a class="icon-action" data-toggle="tooltip" data-placement="top" data-title="Show" href="/admin/branches/${id}" data-original-title="" title=""><i class="fa fa-eye" aria-hidden="true"></i></a>`;
            break;
          case "UPDATE":
            res += `<a class="icon-action"  data-toggle="tooltip" data-placement="top" data-title="Edit" href="/admin/branches/${id}/edit" data-original-title="" title=""><i class="fa fa-pencil" aria-hidden="true"></i></a>`;
            break;
          case "CHANGES":
            res += `<a class="icon-action"  data-toggle="tooltip" data-placement="top" data-title="Changes" href="/admin/branches/${id}/changes" data-original-title="" title=""><i class="fa fa-list-alt" aria-hidden="true"></i></a>`;
            break;
          case "DELETE":
            res += `<span class="icon-action" data-vue-confirm="Are you sure you want to remove selected item?" data-toggle="tooltip" data-placement="top" data-title="Delete" rel="nofollow" data-vue-action="delete" data-id="${id}" data-vue-url="/admin/branches/${id}.json"><i class="fa fa-trash text-danger" aria-hidden="true"></i></span>`;
            break;
        }
      });
      return res;
    },

    nameCellRenderer(params) {
      let res = '';
      if (params && params.value) {
        const {id} = params.data;

        res = `<a data-toggle="tooltip" data-placement="top" data-title="Show" href="/admin/branches/${id}">${params.value}</a> (${params.data && params.data.locale})`;
      }

      return res;
    },

    langCellRenderer(params) {
      if (params && params.value) {
        return params.value.map(item => {
          let res = '';
          const data = `${item.description}`;

          if (item.default) {
            res = `<strong title="Default">${data}</strong>`
          } else {
            res = `${data}`
          }
          return res;
        }).join('<br>');
      }
    },


    userCellRenderer(params) {
      let res = '';

      if (params && params.value) {
        res = params.value.map((item,index) => {
          // get initial data
          let user = params.data[`${params.colDef.field}__init`][index];

          return `<a href="/admin/users/${user.id}/edit">${item}</a>`
        }).join('<br>');
      }
      return res;
    },

    promotionsCellRenderer(params) {
      let res = '';

      if (params && params.value) {
        res += params.value.map((item,index) => {
          // get initial data
          let promo = params.data[`${params.colDef.field}__init`][index];

          return `<a href="${promo.path}">${item}</a>`
        }).join(', ');
      }
      return res;
    },

    dateTimeCellRenderer(params) {
      if (params && params.value) {
        return this.$moment.utc(params.value).format('lll');
      }
    },

    redemtionsCellRenderer(params) {
      if (params && params.data && params.data.id) {
        return `${params.value} redemption${params.value > 1 ? 's' : ''}`
      }
    },

    onError(error) {
      this.hasError = true;
      this.message =
        (error.data && error.data.message) ||
        "Something went wrong. Please try again";

      this.hideNotification();
    },

    onSuccess(message) {
      this.message = message || "Success.";
      this.hideNotification();
    },

    hideNotification(timeout) {
      setTimeout(() => {
        this.message = null;
        this.hasError = false;
      }, timeout || 5000);
    },
    updateCount() {
      const $el = $(".btn-deleted > span");
      $el.text(($el.text() || 0) - 0 + 1);
    },

    getFilter(filterName, options) {
      const filter = this.fh.find(item => (item.key === filterName));

      return {
        filter: options?.filterType || "agTextColumnFilter",
        floatingFilter: true,
        filterParams: filterParams({
          type: filter.type,
          list: filter.value,
          suppressAndOrCondition: filter.unique || false,
        }),
      }
    }
  },

  computed: {
    requestUrl() {
      return `${window.location.origin}${this.url}`;
    },

    gridState() {
      return location.hash ? qs.parse(location.hash.substring(1)) : {}
    },

    columns() {
      let list = [];
      const optionsList = {
        name: {
          headerName: this.columnsListNames["name"],
          field: "name",
          cellRenderer: this.nameCellRenderer,
          sortable: true,
          ...this.getFilter('name'),
        },
        merchants: {
          headerName: this.columnsListNames["merchants"],
          field: "merchants",
          width: 400,
          cellClass: ['ag-cell-auto'],
          autoHeight: true,
          wrapText: true,
          ...this.getFilter('merchants'),
        },
        supervisors: {
          headerName: this.columnsListNames["supervisors"],
          field: "supervisors",
          minWidth: 200,
          width: 200,
          cellClass: ['ag-cell-auto'],
          autoHeight: true,
          wrapText: true,
          cellRenderer: this.userCellRenderer,
          ...this.getFilter('supervisors'),
        },
        managers: {
          headerName: this.columnsListNames["managers"],
          field: "managers",
          minWidth: 200,
          width: 200,
          cellClass: ['ag-cell-auto'],
          autoHeight: true,
          wrapText: true,
          cellRenderer: this.userCellRenderer,
          ...this.getFilter('managers'),
        },
        languages: {
          headerName: this. columnsListNames["languages"],
          field: "languages",
          width: 200,
          cellClass: ['ag-cell-auto'],
          autoHeight: true,
          wrapText: true,
          cellRenderer: this.langCellRenderer
        },
        currency_code: {
          headerName: this.columnsListNames["currency_code"],
          field: "currency_code",
          maxWidth: 150,
          sortable: true
        },
        timezone: {
          headerName: this.columnsListNames["timezone"],
          field: "timezone",
          maxWidth: 250,
          cellClass: ['ag-cell-auto'],
          autoHeight: true
        },
        redemptions: {
          headerName: this.columnsListNames["redemptions"],
          field: "redemptions",
          width: 200,
          cellRenderer: this.redemtionsCellRenderer
        },
        promotions: {
          headerName: this.columnsListNames["promotions"],
          field: "promotions",
          width: 350,
          minWidth: 350,
          cellClass: ['ag-cell-auto'],
          autoHeight: true,
          wrapText: true,
          cellRenderer: this.promotionsCellRenderer,
          ...this.getFilter('promotions'),
        },
        created_at: {
          headerName: this.columnsListNames["created_at"],
          field: "created_at",
          cellClass: ['ag-cell-center', 'ag-cell-auto'],
          autoHeight: true,
          wrapText: true,
          minWidth: 120,
          sortable: true,
          cellRenderer: this.dateTimeCellRenderer
        },
        updated_at: {
          headerName: this.columnsListNames["updated_at"],
          field: "updated_at",
          cellClass: ['ag-cell-center', 'ag-cell-auto'],
          autoHeight: true,
          wrapText: true,
          minWidth: 120,
          sortable: true,
          cellRenderer: this.dateTimeCellRenderer
        },
        actions: {
          headerName: this.columnsListNames["actions"],
          field: "actions",
          width: 110,
          minWidth: 110,
          maxWidth: 110,
          cellClass: ["ag-cell-actions", "ag-cell-no-overflow"],
          cellRenderer: this.actionCellRenderer,
          classClass: "actions",
          pinned: "right"
        }
      };

      this.columnsList.forEach(item => {
        if (this.visibleColumns.indexOf(item) !== -1) {
          list.push(optionsList[item] || []);
        }
      });
      return list;
    }
  }
};
</script>
