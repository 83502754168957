<template>
  <div class="data-table">
    <h2 v-if="title">{{ title }}</h2>

    <notification
      v-if="message"
      :successText="!hasError ? message : ''"
      :failureText="hasError ? message : ''"
    >
    </notification>

    <div>
      <ag-grid-vue
          style="width: 100%; height: 76vh"
          class="ag-theme-balham"
          @grid-ready="onGridReady"
          :gridOptions="gridOptions"
          :columnDefs="columnDefs"
          :animateRows="true"
          :multiSortKey="'ctrl'"
          :rowClassRules="rowClassRules"
          :suppressDragLeaveHidesColumns="true"
          :enableCellTextSelection="true"
          :rowData="rowData"
      />
    </div>
  </div>
</template>

<script>
import { AgGridVue } from "ag-grid-vue";
import axios from "axios";
import qs from "qs";
import { setTimeout } from "timers";
import notification from "../notification";
import {isEqual} from "lodash";
import { filterParams } from "../../utils/dataTable";

export default {
  name: "DataTablePartners",
  props: {
    title: { type: String, required: false },
    url: { type: String, required: true },
    projectId: { type: [String], required: true },
  },
  data() {
    return {
      hasError: false,
      isLoading: false,
      message: null,
      gridOptions: {},
      columnDefs: null,
      rowData: null,
      rowClassRules: null,
      gridCurrentState: {},
      columnsList: [
        "name",
        "validation_name",
        "projects",
        "actions"
      ],
      columnsListNames: {
        name: "Display Name",
        validation_name: "Validation Name",
        projects: "Projects",
        actions: "Actions"
      },
      visibleColumns: [
        "name",
        "validation_name",
        "projects",
        "actions"
      ],
      disabledColumns: [
        "name",
        "validation_name",
        "projects",
        "actions"
      ]
    };
  },

  components: {
    AgGridVue,
    notification
  },

  beforeMount() {
    this.gridCurrentState = {...this.gridState};

    this.gridOptions.defaultColDef = {
      resizable: true
    };

    this.applySavedState(['visibleColumns']);
  },

  mounted() {
    this.gridApi = this.gridOptions.api;
    this.subscribeEvents();
  },

  methods: {
    onFilterStatus(status) {
      if (status && (this.selectedStatus !== status)) {
        this.selectedStatus = status;
      } else {
        this.selectedStatus = null;
      }
    },

    subscribeEvents() {
      this.gridOptions.onGridReady = (e) => {
        this.columnApi = e.columnApi;
        this.gridApi = e.api;
      }

      this.gridOptions.onFilterChanged = (grid) => {
        const filter = grid.api.getFilterModel();
        if (!isEqual(filter, this.gridCurrentState.filter)) {
          this.popSavedState({
            filter
          });
        }
      };

      this.gridOptions.onSortChanged = (grid) => {
        const sort = grid.columnApi.getColumnState()
            .filter(item => !!item.sort)
            .map(item => ({colId: item.colId, sort: item.sort, sortIndex: item.sortIndex, sortIndex: item.sortIndex}));

        if (!isEqual(sort, this.gridCurrentState.sort)) {
          this.popSavedState({
            sort
          });
        }
      };

      this.gridOptions.onFirstDataRendered = () => {
        this.applySavedState(['filter', 'sort'], true);
      };

      this.gridOptions.onCellClicked = (cell)  => {
        const $parent = cell.event.target.closest('span');
        const {colId} = cell.column;

        switch (colId.replace(/_?\d+$/,'')) {
          case "projects":
            if ($parent && $parent.classList.contains('cell-filter-clickable')) {
              this.applyFilters(colId, $parent.textContent);
            }
            break;

          case "actions":
            if ($parent && $parent.dataset && $parent.dataset["vueAction"]) {
              this.proceedAction($parent.dataset);
            }
            break;

          default:
            break;
        }
      };

      window.addEventListener("resize", () => {
        let w = window.innerWidth;
        setTimeout(() => {
          if (window.innerWidth === w) {
            this.sizeToFit();
          }
        }, 300);
      });
    },

    applySavedState(props, initial) {
      props.forEach(prop => {
        const values = this[initial ? 'gridState' : 'gridCurrentState'][prop];
        if (values) {
          switch (prop) {
            case 'filter':
              this.gridApi.setFilterModel(this.gridCurrentState[prop]);
              this.gridApi.onFilterChanged();
              break;

            case 'sort':
              this.columnApi.applyColumnState({state: this.gridCurrentState[prop]});
              this.gridApi.onSortChanged();
              break;

            case 'visibleColumns':
              this.visibleColumns = this.gridCurrentState[prop];
              break;
          }
        }
      });
    },

    applyFilters(filterBy, text) {
      if (filterBy && text) {
        let filterInstance = this.gridApi.getFilterInstance(filterBy);

        filterInstance.setModel({
          type: "contains",
          filter: text
        });

        this.gridApi.onFilterChanged();
      }
    },

    popSavedState(state) {
      this.gridCurrentState = {
        ...this.gridCurrentState,
        ...state
      };
      location.hash = qs.stringify(this.gridCurrentState);
    },

    proceedAction(dataset) {
      dataConfirmModal.confirm({
        backdrop: true,
        keyboard: false,
        show: true,
        title: dataset.title || "Confirm",
        text: dataset.vueConfirm || "Are you sure you want to continue?",
        commit: "Yes",
        cancel: "Back",
        onConfirm: () => {
          axios({
            headers: {
              "X-CSRF-Token": $('meta[name="csrf-token"]').attr("content")
            },
            method: dataset.vueMethod || "delete",
            url: dataset.vueUrl
          }).then(() => {
              this.rowData = this.rowData.filter(
                item => item.id !== dataset.id - 0
              );

              this.updateCount();
              this.onSuccess("Partner removed successfully.");
            },
            error => {
              this.onError(error);
            }
          );
        },
        onCancel: function() {
          return false;
        }
      });
    },

    applyTooltips() {
      $('[data-toggle="tooltip"]').tooltip();
    },

    applyPlaceholders() {
      $('input[ref="eFloatingFilterText"], input[ref="eInput"]').each(function() {
        $(this).attr("placeholder", "Filter...");
      });
    },

    sizeToFit() {
      this.gridApi.sizeColumnsToFit();
    },

    applyColumnDefinition() {
      this.columnDefs = this.columns;
      this.popSavedState({visibleColumns: this.visibleColumns});

      setTimeout(() => {
        this.applyTooltips();
        this.applyPlaceholders();
        this.sizeToFit();
      }, 1);
    },

    onGridReady() {
      let params = {};
      const isDeleted = Boolean(this.deleted - 0);
      if (isDeleted) {
        params.deleted = isDeleted;
      }

      this.isLoading = true;

      axios({
        method: "get",
        url: this.requestUrl,
        params
      }).then(
        response => {
          this.fh = response.data.filters;
          this.applyColumnDefinition();
          this.rowData = response.data.list;
        },
        error => {
          this.onError(error);
        }
      ).finally(() => {
        this.isLoading = false;
      });
    },

    actionCellRenderer(params) {
      const id = params.data.id;
      let res = "";

      params.value.forEach(action => {
        switch (action.toUpperCase()) {
          case "READ":
            res += `<a class="icon-action" data-toggle="tooltip" data-placement="top" data-title="Show" href="/admin/projects/${this.projectId}/partners/${id}" data-original-title="" title=""><i class="fa fa-eye" aria-hidden="true"></i></a>`;
            break;
          case "UPDATE":
            res += `<a class="icon-action"  data-toggle="tooltip" data-placement="top" data-title="Edit" href="/admin/projects/${this.projectId}/partners/${id}/edit" data-original-title="" title=""><i class="fa fa-pencil" aria-hidden="true"></i></a>`;
            break;
          case "DELETE":
            res += `<span class="icon-action" data-vue-confirm="Are you sure you want to remove selected item? This action cannot be reverted" data-toggle="tooltip" data-placement="top" data-title="Delete" rel="nofollow" data-vue-action="delete" data-id="${id}" data-vue-url="/admin/projects/${this.projectId}/partners/${id}.json"><i class="fa fa-trash text-danger" aria-hidden="true"></i></span>`;
            break;
        }
      });
      return res;
    },

    projectsCellRenderer(params) {
      return params && params.value.map(item => `<span class="cell-filter-clickable">${item}</span>`).join(", ");
    },


    onError(error) {
      this.hasError = true;
      this.message =
        (error.data && error.data.message) ||
        "Something went wrong. Please try again";

      this.hideNotification();
    },

    onSuccess(message) {
      this.message = message || "Success.";
      this.hideNotification();
    },

    hideNotification(timeout) {
      setTimeout(() => {
        this.message = null;
        this.hasError = false;
      }, timeout || 5000);
    },

    getFilter(filterName, options) {
      const filter = this.fh.find(item => (item.key === filterName));

      return {
        filter: options?.filterType || "agTextColumnFilter",
        floatingFilter: true,
        filterParams: filterParams({
          type: filter.type,
          list: filter.value,
          suppressAndOrCondition: filter.unique || false,
        }),
      }
    }
  },

  computed: {
    requestUrl() {
      return `${window.location.origin}${this.url}`;
    },

    gridState() {
      return location.hash ? qs.parse(location.hash.substring(1)) : {}
    },

    columns() {
      let list = [];
      const optionsList = {
        projects: {
          headerName: this.columnsListNames["projects"],
          field: "projects",
          minWidth: 350,
          cellClass: ['ag-cell-auto'],
          autoHeight: true,
          wrapText: true,
          cellRenderer: this.projectsCellRenderer,
          ...this.getFilter('projects'),
        },
        name: {
          headerName: this.columnsListNames["name"],
          field: "name",
          minWidth: 200,
          sortable: true,
          ...this.getFilter('name'),
        },
        validation_name: {
          headerName: this.columnsListNames["validation_name"],
          field: "validation_name",
          minWidth: 200,
          sortable: true,
          ...this.getFilter('validation_name'),
        },
        actions: {
          headerName: this.columnsListNames["actions"],
          field: "actions",
          width: 100,
          minWidth: 100,
          maxWidth: 100,
          cellClass: ["ag-cell-actions", "ag-cell-no-overflow"],
          cellRenderer: this.actionCellRenderer,
          classClass: "actions",
          pinned: "right"
        }
      };

      this.columnsList.forEach(item => {
        if (this.visibleColumns.indexOf(item) !== -1) {
          list.push(optionsList[item] || []);
        }
      });
      return list;
    }
  }
};
</script>
