<template>
  <div class="text-danger small mt-1" v-if="getErrorText(fieldName)">{{getErrorText(fieldName)}}</div>
</template>

<script>
import {get} from "lodash";

export default {
  props: {
    fieldName: {
      required: true,
      type: String,
    },
    value: {
      required: true,
      type: Object,
    },
  },
  methods: {
    getErrorText(field) {
      return get(this.value, `errors[${field}]`, []).join(';') || '';
    },
  },
};
</script>
