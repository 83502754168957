<template>
  <div v-if="options.length" class="mb-0 d-flex flex-row align-items-center">
    <label for="values" class="m-0 p-0 mr-2 text-nowrap">With status:</label>
    <select name="values" id="values" class="form-control" v-model="inputVal">
      <option :value="null">Any</option>
      <option
          v-for="opt in options"
          :key="opt"
          :value="opt">
        {{ textValue(opt) }}
      </option>
    </select>
  </div>
</template>

<script>
import {capitalize} from "lodash";

export default {
  props: {
    options: {
      required: true,
      type: Array,
      default: () => ([]),
    },
    value: {
      required: true,
      type: String
    }
  },

  computed: {
    inputVal: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit('input', val);
      }
    },
  },
  methods: {
    textValue(value) {
      let text = value;
      try {
        text = value.match(/\w+$/)[0]
            .split('_')
            .map(word => capitalize(word))
            .join(' ');
      } catch (error) {
        throw Error(error);
      }

      return text;
    }
  },
}
</script>
