<template>
  <div class="col-md-6 pl-0">
    <form @input="stylesChanged" @submit="submitForm">
      <div class="d-flex mt-2" v-if="pageScrolledPosition < 200">
        <button class="btn btn-sm btn-success mr-1" @click="showSaveModal(true)">Apply to Project</button>
        <button class="btn btn-sm btn-info mr-1" @click="showSaveModal(false)">Apply to Promotion</button>
        <button class="btn btn-sm btn-outline-secondary mr-1" @click="undoChanges">Undo</button>
      </div>
      <page-settings :settings="styleSettings.page"></page-settings>
      <text-settings :settings="styleSettings.text" @changeFont="changeFont"></text-settings>
      <input-settings :settings="styleSettings.input"></input-settings>
      <button-settings :settings="styleSettings.button"></button-settings>
      <div class="d-flex mt-2" v-if="pageScrolledPosition >= 200">
        <button class="btn btn-sm btn-success mr-1" @click="showSaveModal(true)">Apply to Project</button>
        <button class="btn btn-sm btn-info mr-1" @click="showSaveModal(false)">Apply to Promotion</button>
        <button class="btn btn-sm btn-outline-secondary mr-1" @click="undoChanges">Undo</button>
      </div>
    </form>
  </div>
</template>

<script>
import pageSettings from './settings/pageSettings';
import textSettings from './settings/textSettings';
import inputSettings from './settings/inputSettings';
import buttonSettings from './settings/buttonSettings';

export default {
  name: 'editorForm',
  props: ['styleSettings', 'currentPromotion'],
  components: {
    'page-settings': pageSettings,
    'text-settings': textSettings,
    'input-settings': inputSettings,
    'button-settings': buttonSettings
  },
  data() {
    return {
      styleSettings: this.styleSettings,
      pageScrolledPosition: 0
    }
  },
  mounted() {
    window.onscroll = () => {
      this.pageScrolledPosition = window.pageYOffset
    };
  },
  methods: {
    stylesChanged (font) {
      this.$emit('stylesChanged', font);
    },
    submitForm(event) {
      event.preventDefault();
    },
    showSaveModal(saveToProject) {
      this.$emit('showSaveModal', saveToProject)
    },
    undoChanges() {
      this.$emit('undoChanges');
    }
  }
}
</script>
