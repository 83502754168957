<template>
  <div class="form-group row">
    <div class="row align-items-center ml-2">
      <i class="fa fa-check mr-2 ml-2 text-success"></i>
        <span class="text-secondary"><b>{{upc}}</b> {{description}}, amount: {{amount}}</span>
    </div>
  </div>
</template>

<script>
export default {
  name: 'correctUpc',
  props: ['upcInfo'],
  data: function(){
    return{
      upc: this.upcInfo.upc,
      description: this.upcInfo.description,
      amount: this.upcInfo.amount
    }
  }
}
</script>

<style scoped></style>
