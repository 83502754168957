<template>
  <div class="mt-2 col px-0">
    <div class="card card-bordered upload-img-card mt-2">
      <div class="custom-file d-flex justify-content-between align-items-center card-block card-header">
        <b class="text-strong text-nowrap">
          {{ componentProperties.options.label }}
        </b>
        <span v-show="componentProperties.options.value" class="truncate ml-2 mr-2"> Name: {{ imageName }}</span>
        <div class="action-buttons-container text-nowrap">
          <span class="mr-2">{{ validationProperties }}</span>
          <b v-if="sizeError" class="text-danger"> Wrong Image Size! </b>
          <label class="fa fa-upload upload-desktop-banner" :for="inputId"></label>
          <i class="fa fa-trash ml-2" @click="clearImage"></i>
        </div>
      </div>
      <input type="file" :id="inputId" @change="uploadImage" ref="uploadInput" accept="image/*">
      <div class="upload-img-container card-image-bottom d-flex justify-content-center align-items-center">
        <img class="img-fluid card-image-bottom" :src="objectURL" ref="uploadedImage" @load="validateWidth" alt=""/>
      </div>
    </div>
  </div>
</template>

<script>
import '../optionsFormatter';

export default {
  name: 'imageUploader',
  components: {},
  props: ['options', 'locale', 'languageId', 'id', 'name', 'language', 'type', 'value', 'readonly'],

  data() {
    return {
      inputId: `input_${Math.floor(1000 + Math.random() * 9000)}`,
      objectURL: this.value || '',
      sizeError: false,
      imageName: '',
    }
  },

  computed: {
    opt: {
      get() {
        return {
          label: this.options.label || '',
          value: this.value || '',
          validation: this.options.validation || {}
        };
      },
      set(v) {}
    },

    componentProperties() {
      return {
        type: this.type,
        is: this.$options._componentTag,
        id: this.id || '',
        locale: Object.keys(this.options.locale)[0],
        languageId: this.languageId,
        name: this.name,
        readonly: this.readonly,
        options: this.opt,
      };
    },

    validationProperties() {
      if (!this.options.validation) {
        return null;
      }

      return Object.entries(this.options.validation)
          .map(e => (`${e[0].capitalize()}: ${e[1]}px`))
          .join(', ');
    },
  },
  mounted: function(){
    if( this.componentProperties.options.value ){
      this.imageName = this.componentProperties.options.value.split(/(\\|\/)/g).pop()
    }
  },
  methods: {
    uploadImage: function(event){
      this.componentProperties.options.value = event.target.files[0];
      this.objectURL = URL.createObjectURL(event.target.files[0]);
      this.imageName = this.componentProperties.options.value.name;
    },
    clearImage: function(){
      if (this.componentProperties.options.value) {
        dataConfirmModal.confirm({
          title: 'Remove image',
          text: 'Are you sure you want to remove current image?',
          commit: 'Yes',
          cancel: 'Back',
          backdrop: true,
          keyboard: false,
          show: true,
          onConfirm: () => {
            this.componentProperties.options.value = '';
            this.value = '';
            this.objectURL = '';
            this.imageName = '';
            this.$refs.uploadInput.value = '';
          }
        });
      }
    },
    validateWidth: function(){
      const imageRealWidth = this.$refs.uploadedImage.naturalWidth;
      if (this.options.validation && imageRealWidth === this.options.validation.width || !this.options.validation){
        this.sizeError = false
      } else {
        this.sizeError = true;
        this.componentProperties.options.value = '';
        this.objectURL = '';
      }
    }
  }
}
</script>

<style scoped>
  input[type="file"]{
    display: none;
  }
  .upload-img-container img{
    max-height: 300px;
    max-width: 100%;
  }
</style>
