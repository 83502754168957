<template>
  <div :class="`text-editor-container px-0 ${className}`">
    <label :class="`string ${required ? '' : 'optional'}`"
           :for="id">
      {{label}}
      <template v-if="required">
        <span>*</span>
      </template>
    </label>

    <textarea :class="`form-control text ${required ? '' : 'optional'} ${errors.length ? 'is-invalid' : ''}`"
              :id="id">
      {{value}}
    </textarea>

    <input :type="debug ? 'text' : 'hidden'" :name="name" :value="encoded" class="form-control"/>

    <div v-if="errors.length">
      <span class="invalid-feedback" v-html="errors.split('<br/>')"/>
    </div>

    <div v-if="debug" style="background-color: #ffbbee30; padding: 16px; border: 1px solid #ddd">
      <pre>model: {{model}}</pre>
      <pre>label: {{ label }}</pre>
      <pre>value: {{ value }}</pre>
      <pre>errors(if any): {{ errors }}</pre>
      <pre>className: {{ className }}</pre>
    </div>
  </div>
</template>

<script>
import { encrypt } from "../../../crypto";
import config from "../../../config";

export default {
  props: {
    name: {
      required: true,
      type: String,
    },
    label: {
      required: true,
      type: String,
    },
    value: {
      required: true,
      type: String,
    },
    token: {
      required: true,
      type: String,
    },
    className: {
      required: false,
      type: String,
      default: 'form-group'
    },
    required: {
      required: false,
      type: Boolean,
      default: false,
    },
    errors: {
      required: false,
      type: Array,
      default: () => ([]),
    }
  },

  data() {
    return {
      debug: false,

      model: '',
      rid: Math.random().toString(32).substring(2),
    };
  },

  mounted() {
    this.initModel();
    this.$nextTick(() => {
      this.initRichTextEditor();
    });
  },

  computed: {
    id() {
      return `field__${this.rid}`;
    },

    encoded() {
      return encrypt(this.model, this.token.substring(0, 32));
    }
  },

  methods: {
    initModel() {
      this.model = this.value;
    },

    initRichTextEditor() {
      const editorConfig = {
        selector: `textarea#` + this.id,
        readonly: this.readonly,
        setup:  (ed) => {
          ed.on("change", () => {
            this.model = ed.getBody().innerHTML;
          })
        },
      };

      tinymce.init(Object.assign(config.tinySettings, editorConfig));
    }
  }
};
</script>
