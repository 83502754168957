<template>
  <button class="btn btn-primary mt-2 mb-4" @click.prevent="$emit('click')">
    <span class="fa fa-angle-left mr-1"/>
    <span>Back</span>
  </button>
</template>

<script>
export default {};
</script>
