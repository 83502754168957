<template>
    <div class="col-lg-7" id="partnerSelection">
        <div class="row field-row mb-2 align-items-center">
            <div class="col-lg-4">
                <div class="field-title">Partner type:</div>
            </div>
            <div class="col-lg-8">
                <select class="partner-type selectpicker" data-width="100%" @change="setPartnerType">
                    <option v-for="fieldOption in initialStepData.stepOptions.partnerType">{{fieldOption}}</option>
                </select>
            </div>
        </div>
        <small class="text-muted">
            {{newOptions.partnerType || "Sponsoring"}} partners in regular rules must be unique
            to enable image selection on the landing page.
        </small>
    </div>
</template>

<script>
  export default{
    name: 'partnerSelection',
    props: ['stepsObj', 'stepOptions', 'blockClass'],
    data: function() {
        return {
            stepName: 'Partner Selection',
            newBlockClass: '',
            newOptions: {
                partnerType: ''
            }
        }
    },
    computed: {
        step: function() {
            if (this.blockClass.length > 0) {
                return {
                    blockClass: this.blockClass,
                    options: {
                        partnerType: this.newOptions.partnerType || "Sponsoring"
                    }
                }
            }
        },
        stepObject: function() {
            let index = this.legacyBlocksList == undefined || this.legacyBlocksList.length <= 0 ? 6 : 8;
            return this.stepsObj[index];
        },
        initialStepData: function(){
          return this.stepsObj.filter(step => { return step.blockClass == this.blockClass })[0];
        }
    },
    mounted: function() {
        if (this.stepOptions) {
            this.newOptions = this.stepOptions;
        }

        $(this.$el).find('select.selectpicker.partner-type').selectpicker();
        $(this.$el).find('select.selectpicker.partner-type').selectpicker('val', this.newOptions.partnerType);
    },
    methods: {
        setPartnerType: function(event) {
            Vue.set(this.newOptions, 'partnerType', event.target.selectedOptions[0].text);
        }
    }
  }
</script>
