function showDialogById(id) {
  const dialog = document.getElementById(id);
  dialog.showModal();
}

document.addEventListener('DOMContentLoaded', () => {
  [...document.querySelectorAll('.js-open-dialog')].forEach((el) => {
    el.addEventListener('click', () => {
      showDialogById(el.dataset.target);
    });
  });

  [...document.querySelectorAll('.js-close-dialog')].forEach((el) => {
    el.addEventListener('click', () => {
      const dialog = el.closest('dialog');

      if (el.dataset.url) {
        window.location.href = el.dataset.url;
      }

      dialog.close();
    });
  });
});
