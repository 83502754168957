<template>
<div>
  <template v-if="steps && steps.service">
    <h4 class="no-select mb-2">
      <a href="#" @click.prevent="formVisible = !formVisible">
        Integration form
        <span :class="formVisible ? 'fa fa-angle-up' : 'fa fa-angle-down'"></span>
      </a>
    </h4>

    <template v-if="formVisible">
      <div class="form-group row mt-4">
        <div class="col-2">
          <label class="mb-0 pt-2" for="service">Service:</label>
        </div>
        <select name="service"
                id="service"
                :disabled="raw_response"
                v-model="integration.service"
                @change="changeService" class="form-control col-10">
          <option v-for="key in Object.keys(steps.service)"
                  :key="key"
                  :value="key">
            {{ key }}
          </option>
        </select>
      </div>

      <div v-if="integration.service" class="form-group row ml-2">
        <div class="col-2">
          <label for="request_type"
                 class="mb-0 pt-2">Request Type:</label>
        </div>
        <select name="request_type"
                id="request_type"
                v-model="integration.request_type"
                :disabled="raw_response"
                @change="cleanData"
                class="form-control col-10">
          <option v-for="type in Object.keys(steps.service[integration.service].request_type)"
                  :key="type"
                  :value="type">
            {{ type }}
          </option>
        </select>
      </div>

      <template v-if="integration.request_type">
        <div
            v-for="key in Object.keys(inputs)"
            :key="key" class="form-group row ml-4">
          <div class="col-3">
            <label :for="'input_' + key" class="mb-0 pt-2">
              {{ inputs[key].label }}:
              <template v-if="inputs[key].required">*</template>
            </label>
          </div>
          <template
              v-if="inputs[key].type === 'string'">
            <input type="text"
                   :id="'input_' + key"
                   v-model="integration.request_params[key]"
                   :required="inputs[key].required"
                   :disabled="raw_response"
                   :placeholder="inputs[key].placeholder"
                   class="form-control col-9"/>
          </template>
          <template
              v-else-if="inputs[key].type === 'number'">
            <input type="number"
                   :for="'input_' + key"
                   step="0.01"
                   min="0.01"
                   v-model="integration.request_params[key]"
                   :required="inputs[key].required"
                   :disabled="raw_response"
                   :placeholder="inputs[key].placeholder"
                   class="form-control col-9"/>
          </template>
          <template
              v-else-if="inputs[key].type === 'select-autocomplete'">
              <div class="col-9 px-0">
                <v-select taggable
                          :options="inputs[key].values"
                          v-model="integration.request_params[key]"
                          :disabled="raw_response"
                          :required="inputs[key].required"
                          :taggable="inputs[key].taggable"
                          :id="'input_' + key">
                  <template v-slot:option="value">
                      <div>{{ getData(value, 'label')}}</div>
                      <template v-if="getData(value, 'extra')">
                        <span class="product-description">
                          {{ getData(value, 'extra')}}
                        </span>
                      </template>
                  </template>

                  <template v-slot:no-options="{ search, searching }">
                    <template v-if="searching">
                      No results found for <em>{{ search }}</em>.
                    </template>
                    <em style="opacity: 0.5;" v-else>Start typing to search for a {{inputs[key].name}}.</em>
                  </template>
                </v-select>
              </div>
          </template>
          <template
              v-else-if="inputs[key].type === 'select'">
              <select :id="'input_' + key"
                      :disabled="raw_response"
                      v-model="integration.request_params[key]"
                      class="form-control col-9"
                      :required="inputs[key].required">
                <option
                    v-for="value in inputs[key].values"
                    :key="getData(value, 'value')"
                    :value="getData(value, 'value')">
                  {{ getData(value, 'label') }}
                </option>
              </select>
          </template>

          <template
              v-else-if="inputs[key].type === 'boolean'">
            <div class="col-9 pt-2 px-0">
              <input type="checkbox"
                     :id="'input_' + key"
                     v-model="integration.request_params[key]"
                     :disabled="raw_response"
                     :required="inputs[key].required"
                     :name="'input_' + key"
              />
            </div>
          </template>


          <template v-else>
            undefined type:
            <pre>{{
                inputs[key].type
              }}</pre>
          </template>
        </div>
      </template>


      <div class="form-group row ml-4">
        <div class="offset-3 col-9 pl-0">
          <p class="small font-italic">* Marked fields are required.</p>
          <button @click="send" class="btn btn-primary mr-1" :disabled="!sendEnabled || loading">
            <span v-if="loading" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"/>
            Send Request
          </button>

          <template v-if="raw_request">
            <template v-if="steps.service[integration.service].request_type[integration.request_type].cancelable">
              <button class="btn btn-warning mr-1"
                      @click="cancelRequest(steps.service[integration.service].request_type[integration.request_type].cancelable)">
                Cancel Request
              </button>
            </template>

            <button class="btn btn-light" @click="clearRaw">Reset Form</button>
          </template>
        </div>
      </div>

      <template v-if="raw_request">
        <hr class="mx-4 my-3"/>

        <div class="mx-2 row">
          <div class="col-12">
            <h5>
              Status:
              <span :class="success? 'text-success': 'text-danger'">
                <template v-if="success">Success</template>
                <template v-else>Failure</template>
              </span>
            </h5>
          </div>
        </div>
        <div class="mx-2 row">
          <div class="col-6">
            <h4>Request</h4>
            <div class="pre-container">
              <pre class="over-container">{{raw_request}}</pre>
            </div>
          </div>
          <div class="col-6 pl-0">
            <h4>Response</h4>
            <div class="pre-container">
              <template v-if="integration.service==='DDP'">
                <pre class="over-container">{{JSON.stringify(JSON.parse(raw_response), null, 2)}}</pre>
              </template>
              <template v-else-if="integration.service==='TV'">
                <pre class="over-container">{{raw_response}}</pre>
              </template>
              <template v-else-if="integration.service==='RTG'">
                <pre class="over-container">{{raw_response}}</pre>
              </template>
              <template v-else-if="integration.service==='APLS'">
                <pre class="over-container">{{JSON.stringify(JSON.parse(raw_response), null, 2)}}</pre>
              </template>
            </div>
          </div>
        </div>
        <div class="row ml-2" v-if="integration.request_params.auto_cancel && success">
          <div class="col-12">
            <p class="font-weight-bold py-0">This request will be cancelled automatically. Cancel request will be saved in the log. </p>
          </div>
        </div>
        <div class="form-group row mt-4 ml-2">
          <div class="col-2">
            <label for="name" class="mb-0 pt-2">Display Name: *</label>
          </div>
          <div class="col-4 pr-2">
            <input type="text" v-model="name" class="form-control" id="name" ref="name"/>
          </div>
          <div class="col-6 pl-2">
            <button @click="save" class="btn btn-primary mr-1" :disabled="!saveEnabled || saving">
              <span v-if="saving" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"/>
              Save
            </button>

            <template v-if="steps.service[integration.service].request_type[integration.request_type].cancelable">
              <button class="btn btn-warning mr-1"
                      @click="cancelRequest(steps.service[integration.service].request_type[integration.request_type].cancelable)">
                Cancel Request
              </button>
            </template>

            <button class="btn btn-light" @click="clearRaw">Reset Form</button>
          </div>
        </div>
        <template v-if="service_log && service_log.name && service_log.id">
          <p class="small font-italic ml-4">
            * The record automatically saved in the table. Please update <strong>Display Name</strong> if required
          </p>
        </template>

      </template>

      <hr class="my-4">

    </template>
  </template>
</div>
</template>

<script>
import { cloneDeep } from "lodash";
import { encrypt } from '../../crypto.js';

export default {
  props: {
    'steps': {
      required: true,
      type: Object,
    }
  },
  data() {
    return {
      formVisible: false,

      integration: {
        service: null,
        request_type: null,
        request_params: {},
      },

      raw_request: null,
      raw_response: null,
      request_at: null,
      resposne_at: null,

      name: '',
      success: null,
      loading: false,
      saving: false,

      cancel_params: {},
      service_log: {},
    }
  },

  computed: {
    XCSRFToken: () => document.querySelector('meta[name="csrf-token"]').getAttribute("content"),

    headers() {
      return {
        'X-CSRF-Token': document.querySelector("meta[name='csrf-token']") && document.querySelector("meta[name='csrf-token']").content,
        'authenticity_token': document.querySelector("input[name='authenticity_token']") && document.querySelector("input[name='authenticity_token']").value,
      }
    },

    inputs() {
      return this.integration.service && this.integration.request_type && this.steps.service[this.integration.service].request_type[this.integration.request_type].inputs || [];
    },

    sendEnabled() {
      let required_field = [];
      let filled_fields = [];

      Object.entries(this.integration.request_params).forEach(item => {
        const valueNormalized = typeof (item[1]) === 'string' ?  item[1].trim() : item[1];
        if (valueNormalized) {
          filled_fields.push(item[0]);
        }
      });

      Object.entries(this.inputs).forEach(item => {
        if(item[1].required) {
          required_field.push(item[0])
        }
      });

      const allRequiredFilled = required_field.filter(item => (filled_fields.includes(item))).length === required_field.length;

      return this.integration.service
          && this.integration.request_type
          && !this.raw_request
          && allRequiredFilled;
    },

    saveEnabled() {
      const storedName =  this.service_log.name || '';
      return this.raw_request && this.raw_response && this.name && (this.name !== storedName);
    },

    normalizedIntegrationData() {
      const data = cloneDeep(this.integration);
      const request_params = Object.keys(data.request_params);
      const arrays = ['upcs'];

      request_params.forEach(key => {
        // normalize object values
        if(data.request_params[key].value) {
          data.request_params[key] = data.request_params[key].value
        }

        // normalize arrays
        if (arrays.includes(key)) {
          data.request_params[key] = data.request_params[key].toString().split(',').map(item => (item.trim()));
        }
      });

      return data;
    },
  },

  watch: {
    steps(value) {
      if (value && value.service) {
        const keys = Object.keys(value.service);
        if (keys.length) {
          this.integration.service = keys[0];

          const request_types = Object.keys(value.service[keys[0]].request_type);

          if (request_types) {
            this.integration.request_type = request_types[0];
          }

          this.cleanData();
        }
      }
    },

    // service(value) {
    //   if (value && value.request_type) {
    //     const keys = Object.keys(value.request_type);
    //     if (keys.length) {
    //       this.integration.request_type = keys[0];
    //     }
    //   }
    //   this.integration.request_params = {};
    // }
  },

  methods: {
    getData(data, key) {
      return (typeof(data) === 'object') ? data.hasOwnProperty(key) && data[key] : data;
    },

    changeService(e) {
      const value = e.target.value;

      const request_types = Object.keys(this.steps.service[value].request_type);

      if (request_types.length) {
        this.integration.request_type = request_types[0];
        this.cleanData();
      }
    },

    send() {
      const message = 'Are you sure to proceed with this request without Auto-cancel?';
      const displayConfirmation = this.integration.service === 'RTG' && this.integration.request_type === 'Request Active Code' && !this.integration.request_params.auto_cancel;

      const doRequest = () => {
        this.loading = true;
        this.cancel_params = {};
        this.service_log = {};

        this.$http.post('/admin/integrations/preview.json', {
          integration: this.normalizedIntegrationData,
        }, {headers: this.headers}).then((response) => {
          ['raw_response', 'raw_request', 'success', 'service_log', 'cancel_params', 'request_at', 'response_at'].forEach((key) => {
            const value = response.data && response.data[key]
            this[key] = typeof value !== 'undefined' && value;
          });

          if (this.service_log && this.service_log.id) {
            this.$emit('saved', this.service_log);
            this.name = this.service_log.name || '';
          }

          this.$nextTick(() => {
            this.$refs.name.focus();
          });

        }, (error) => {
          const message =  error.response && error.response.data && error.response.data.error || 'Something went wrong. Please try again';
          this.$emit('error', {data: {message}});
        }).finally(() => {
          this.loading = false;
        })
      }

      if (displayConfirmation) {
        if (confirm(message)) {
          doRequest();
        }
      } else {
        doRequest();
      }
    },

    cancelRequest(request_type) {
      this.cleanData();
      this.integration.request_type = request_type;
      this.integration.request_params = cloneDeep(this.cancel_params);
      this.cancel_params = {};
    },

    save() {
      this.$emit('onSave', this.name);
      let params = {
        method: 'post',
        path: '/admin/integrations.json',
        data: {
          name: this.name,
        },
      };

      if (this.service_log.id) {
        params = {
          ...params,
          method: 'patch',
          path: `/admin/integrations/${this.service_log.id}.json`,
        }
      } else {
        params.data = {
          ...params.data,
          ...this.normalizedIntegrationData,
          raw_request:  encrypt(this.raw_request, this.XCSRFToken.substring(0, 32)),
          raw_response: encrypt(this.raw_response, this.XCSRFToken.substring(0, 32)),
          request_at:   this.request_at,
          response_at:  this.response_at,
          status:       this.status
        }
      }

      this.saving = true;
      this.$http[params.method](
          params.path,
          params.data,
          {headers: this.headers}
        ).then((response) => {
        this.$emit('saved', response.data);
        this.cleanData();
      }, (error) => {
        const message = error.response && error.response.data && error.response.data.error || 'Something went wrong. Please try again';
        this.$emit('error', {data: {message}});
      }).finally(() => {
        this.saving = false;
      });
    },

    clearRaw() {
      const message = 'Are you sure you don\'t want to save existing request?'

      if (this.service_log.id || confirm(message)) {
        this.cleanData();
      }
    },

    cleanData() {
      this.raw_request = null;
      this.raw_response = null;
      this.name = '';
      this.success = null;
      this.integration.request_params = {};

      Object.entries(this.inputs).forEach(item => {
        let value = item[1].value;
        const type = typeof(value);

        if ( type === 'object') {
          value = Array.isArray(value) ? value.join(',') : JSON.stringify(value);
          this.integration.request_params[item[0]] = value;
        } else if (type !== 'undefined') {
          this.integration.request_params[item[0]] = value;
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.pre-container {
  min-width: unset;
}
.over-container {
  max-height: calc(100vh / 3);
  width: unset;
  overflow: auto;
  margin-bottom: 2em;
}
</style>
