<template>
  <div class="setting-section card card-bordered p-0 pt-3 pl-3 pb-2 mt-3">
    <h5>Page</h5>
    <div class="row">
      <label for="pageBackground" class="col-sm-4 col-form-label">Background Color</label>
      <chrome-picker v-model="settings.backgroundColor" @input="settings.backgroundColor = `rgba(${settings.backgroundColor.rgba.r}, ${settings.backgroundColor.rgba.g}, ${settings.backgroundColor.rgba.b},${settings.backgroundColor.rgba.a})`"></chrome-picker>
    </div>
  </div>
</template>

<script>
import { Chrome } from 'vue-color'

export default {
  name: 'pageSettings',
  props: ['settings'],
  components: { 'chrome-picker' : Chrome }
}
</script>

<style scoped>
  .vc-chrome{
    width: 60%;
    display: inherit;
  }


  .vc-chrome:first-child{
    padding-bottom: 40% !important;
  }
</style>
