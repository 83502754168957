const crypto = require('crypto');
const algorithm = 'aes-256-cbc';
const defaultKey = 'SOME_RANDOM_KEY_32_CHR_123456789'; // 32 Characters
const iv = "0000000000000000"; // 16 Characters
const c = "utf-8"; // ansii table


const encrypt = (text, key) => {
  const k = key || defaultKey;
  const cipher = crypto.createCipheriv(algorithm, k, iv)
  let en = cipher.update(text, c, "base64");
  en += cipher.final("base64");
  return en;
}

const decrypt = (text, key) => {
  const cipher = crypto.createDecipheriv(algorithm, k, iv)
  let de = cipher.update(text, "base64", c);
  de += cipher.final(c);
  return de;
}

export default encrypt;
export {
  decrypt,
  encrypt,
}
