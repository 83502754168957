import { render, staticRenderFns } from "./textEditor.vue?vue&type=template&id=16fc876f&scoped=true&"
import script from "./textEditor.vue?vue&type=script&lang=js&"
export * from "./textEditor.vue?vue&type=script&lang=js&"
import style0 from "./textEditor.vue?vue&type=style&index=0&id=16fc876f&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "16fc876f",
  null
  
)

export default component.exports