<template>
  <div class="row">

    <div class="col-md-3">
      <label class="col-form-label">
        Start
        <span class="text-danger">*</span>
      </label>
      <input
        type="number"
        class="form-control"
        :placeholder="minAmount"
        v-bind:class="{invalid: !validStart || invalidStartOverlap }"
        v-model="start"
        @input="$emit.setTier; invalidStartOverlap = validateTierOverlap(start, tiers)"
      >
      <span class="error text-danger">{{startErrorText}}</span>
    </div>

    <div class="col-md-3">
      <label class="col-form-label">
        End
        <span class="text-danger">*</span>
      </label>
      <input
        type="number"
        class="form-control"
        :placeholder="maxAmount"
        v-model="end"
        v-bind:class="{invalid: !validEnd || invalidEndOverlap }"
        @input="$emit.setTier; invalidEndOverlap = validateTierOverlap(end, tiers)"
      >
      <span class="error text-danger">{{endErrorText}}</span>
    </div>

    <div class="col-md-6">
      <label class="col-form-label">
        Bonus Amount
        <span class="text-danger">*</span>
      </label>
      <div class="d-flex justify-content-between align-items-center">
        <div class="input-group pr-3">
          <input
            type="number"
            class="form-control"
            aria-label="Text input with dropdown button"
            min="0"
            v-model="bonus"
            v-bind:class="{invalid: !validBonus }"
            @input="$emit.setTier; validateBonusAmount()"
          >
          <div class="input-group-append">
            <button
              type="button"
              class="btn btn-secondary dropdown-toggle"
              data-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
            >{{currentCurrency}}</button>
            <div class="dropdown-menu dropdown-menu-right">
              <span
                class="dropdown-item"
                href="#"
                @click="currentCurrency = currency; bonusUnit = 'amount'"
              >{{currency}}</span>
              <span
                class="dropdown-item"
                href="#"
                @click="currentCurrency = '%'; bonusUnit = 'percent'"
              >%</span>
            </div>
          </div>
        </div>
        <i class="fa fa-trash text-danger" v-show="tierIsRemovable" @click="removeTier"></i>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "tire",
  props: [
    "currency",
    "minAmount",
    "maxAmount",
    "tierData",
    "tierIsRemovable",
    "id",
    "tiers"
  ],
  data: function() {
    return {
      currentCurrency: this.currency,
      start: "",
      end: "",
      bonus: "",
      validStart: true,
      validEnd: true,
      validBonus: true,
      invalidStartOverlap: "",
      invalidEndOverlap: "",
      tiers: this.tiers
    };
  },
  computed: {
    tierIsValid: function() {
      return this.start &&
        this.end &&
        this.validStart &&
        this.validEnd &&
        this.validBonus &&
        !this.invalidStartOverlap &&
        !this.invalidEndOverlap
        ? true
        : false;
    },
    tireInfo: function() {
      return {
        id: this.id,
        start_amount: this.start,
        end_amount: this.end,
        bonus_amount: this.bonus,
        bonus_unit: this.currentCurrency == "%" ? "percent" : "amount"
      };
    },
    invalidTierAmountError: function() {
      return `Should be in ${this.minAmount}..${this.maxAmount} range`;
    },
    startErrorText: function() {
      if (parseFloat(this.start) > parseFloat(this.end)) {
        return "More than end amount";
      } else if (this.invalidStartOverlap) {
        return this.invalidStartOverlap;
      } else if (!this.validStart) {
        return `Should be in ${this.minAmount}..${this.maxAmount} range`;
      } else {
        return "";
      }
    },
    endErrorText: function() {
      if (parseFloat(this.start) > parseFloat(this.end)) {
        return "Less than start amount";
      } else if (this.invalidEndOverlap) {
        return this.invalidEndOverlap;
      } else if (!this.validEnd) {
        return `Should be in ${this.minAmount}..${this.maxAmount} range`;
      } else {
        return "";
      }
    }
  },
  created: function() {
    if (this.tierData) {
      this.start = this.tierData.start;
      this.end = this.tierData.end;
      this.bonus = this.tierData.bonus;
      this.currentCurrency = this.tierData.currency;
      this.$parent.tiersData.length > 1
        ? (this.tierIsRemovable = true)
        : (this.tierIsRemovable = false);
    }
    this.$emit("setTier");
  },
  updated: function() {
    parseFloat(this.minAmount) > parseFloat(this.start) ||
    parseFloat(this.maxAmount) < parseFloat(this.start) ||
    parseFloat(this.start) > parseFloat(this.end)
      ? (this.validStart = false)
      : (this.validStart = true);
    parseFloat(this.minAmount) > parseFloat(this.end) ||
    parseFloat(this.maxAmount) < parseFloat(this.end) ||
    parseFloat(this.start) > parseFloat(this.end)
      ? (this.validEnd = false)
      : (this.validEnd = true);
    this.validateBonusAmount();
    this.$emit("setTier");
  },
  watch: {
    tiers: function() {
      if (this.start && this.end) {
        this.invalidStartOverlap = this.validateTierOverlap(
          this.start,
          this.tiers
        );
        this.invalidEndOverlap = this.validateTierOverlap(this.end, this.tiers);
        this.validateBonusAmount();
      }
    }
  },
  methods: {
    validateBonusAmount: function() {
      this.bonus == "" ||
      this.bonus < 0 ||
      (0 < this.bonus && this.bonus > 100 && this.currentCurrency == "%")
        ? (this.validBonus = false)
        : (this.validBonus = true);
    },
    validateTierOverlap: function(tierProp, tiers) {
      var tiersWOCurrent = tiers.filter(tier => tier.id != this.id);
      if (tiersWOCurrent.length > 0) {
        for (var i = 0; i < tiersWOCurrent.length; i++) {
          var currentTierStart = parseFloat(tiersWOCurrent[i].start_amount);
          var currentTierEnd = parseFloat(tiersWOCurrent[i].end_amount);

          if (
            tiersWOCurrent[i] &&
            (currentTierStart <= parseFloat(tierProp) &&
              currentTierEnd >= parseFloat(tierProp))
          ) {
            return `Overlays ${tiersWOCurrent[i].start_amount}..${
              tiersWOCurrent[i].end_amount
            } range`;
          } else if (!tiersWOCurrent[i]) {
            return "";
          }
        }
      } else {
        return "";
      }
    },
    removeTier: function() {
      this.$el.remove();
      this.$emit("removeTier", this.id);
    }
  }
};
</script>

<style scoped>
.invalid {
  border: 1px solid rgb(233, 23, 23);
}

span.error {
  font-size: 10px;
}
</style>
