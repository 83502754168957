<template>
  <div class="data-table">
    <notification
        v-if="message"
        :successText="!hasError ? message : ''"
        :failureText="hasError ? message : ''"
    />

    <h4 class="no-select mb-2">
      <a href="#" @click.prevent="formEnabled = !formEnabled">
        {{title}}
        <span :class="formEnabled ? 'fa fa-angle-up' : 'fa fa-angle-down'"></span>
      </a>
    </h4>

    <template v-if="formEnabled">
      <manual-form
          :form-data="formData"
          @onSuccess="onFormSuccess"
          @onError="onError"
          @onCancel="() => {formEnabled = false}"
          class="my-4"
          />
    </template>

    <div class="action-wrapper">
      <div class="row">
        <div class="col col-12">
          <ul class="data-table-columns">
            <li v-for="item in columnsList" :key="item">
              <input
                  :value="item"
                  :id="item"
                  type="checkbox"
                  v-model="visibleColumns"
                  :disabled="disabledColumns.indexOf(item) !== -1 || isLoading"
                  @change="applyColumnDefinition(true)"
              />
              <label :for="item">{{ columnsListNames[item] }}</label>
            </li>
          </ul>
        </div>
      </div>

      <div class="loader" v-if="isLoading"></div>
    </div>

    <div>
      <ag-grid-vue
          style="width: 100%; height: 75vh"
          class="ag-theme-balham"
          @grid-ready="onGridReady"
          :gridOptions="gridOptions"
          :columnDefs="columnDefs"
          :animateRows="true"
          :multiSortKey="'ctrl'"
          :floatingFilter="true"
          :rowClassRules="rowClassRules"
          :suppressDragLeaveHidesColumns="true"
          :enableCellTextSelection="true"
          :rowData="rowData"
      />
    </div>
  </div>
</template>

<script>
import { AgGridVue } from "ag-grid-vue";
import qs from "qs";
import notification from "../notification";
import {isEqual} from "lodash";

import {filterParams} from "../../utils/dataTable";
import ManualForm from './form.vue';

export default {
  name: "DataTableManualRedemptions",
  props: {
    title: { type: String, required: false },
    url: { type: String, required: true }
  },
  data() {
    return {
      formEnabled: false,
      hasError: false,
      isLoading: false,
      message: null,
      gridOptions: {},
      columnDefs: null,
      rowData: null,
      formData: null,
      rowClassRules: null,
      gridCurrentState: {},
      columnsList: [
        "id",
        "redemption",
        "user",
        "time",
        "justification"
      ],
      columnsListNames: {
        id: "ID",
        redemption: "Redemption",
        justification: "Justification",
        user: "User",
        time: "DateTime"
      },
      visibleColumns: [
        "id",
        "redemption",
        "justification",
        "user",
        "time"
      ],
      disabledColumns: [
        "id",
        "redemption",
      ],
    };
  },

  components: {
    AgGridVue,
    notification,
    ManualForm,
  },

  beforeMount() {
    this.gridCurrentState = {...this.gridState};

    this.gridOptions.defaultColDef = {
      resizable: true
    };

    this.applySavedState(['visibleColumns']);
  },

  mounted() {
    this.gridApi = this.gridOptions.api;
    this.subscribeEvents();
  },

  methods: {
    onFilterStatus(status) {
      if (status && (this.selectedStatus !== status)) {
        this.selectedStatus = status;
      } else {
        this.selectedStatus = null;
      }
    },

    subscribeEvents() {
      this.gridOptions.onGridReady = (e) => {
        this.columnApi = e.columnApi;
        this.gridApi = e.api;
      }

      this.gridOptions.onFilterChanged = (grid) => {
        const filter = grid.api.getFilterModel();
        if (!isEqual(filter, this.gridCurrentState.filter)) {
          this.popSavedState({
            filter
          });
        }
      };

      this.gridOptions.onSortChanged = (grid) => {
        const sort = grid.columnApi.getColumnState()
            .filter(item => !!item.sort)
            .map(item => ({colId: item.colId, sort: item.sort, sortIndex: item.sortIndex}));

        if (!isEqual(sort, this.gridCurrentState.sort)) {
          this.popSavedState({
            sort
          });
        }
      };

      this.gridOptions.onFirstDataRendered = () => {
        this.applySavedState(['filter', 'sort'], true);
      };

      this.gridOptions.onCellClicked = (cell)  => {
        const $parent = cell.event.target.closest('span');
        const {colId} = cell.column;

        switch (colId.replace(/_?\d+$/,'')) {
          case "redemption":
          case "user":
            if ($parent && $parent.classList.contains('cell-filter-clickable')) {
              this.applyFilters(colId, $parent.textContent);
            }
            break;

          default:
            break;
        }
      };

      window.addEventListener("resize", () => {
        let w = window.innerWidth;
        setTimeout(() => {
          if (window.innerWidth === w) {
            this.sizeToFit();
          }
        }, 300);
      });
    },

    applySavedState(props, initial) {
      props.forEach(prop => {
        const values = this[initial ? 'gridState' : 'gridCurrentState'][prop];
        if (values) {
          switch (prop) {
            case 'filter':
              this.gridApi.setFilterModel(this.gridCurrentState[prop]);
              this.gridApi.onFilterChanged();
              break;

            case 'sort':
              this.columnApi.applyColumnState({state: this.gridCurrentState[prop]});
              this.gridApi.onSortChanged();
              break;

            case 'visibleColumns':
              this.visibleColumns = this.gridCurrentState[prop];
              break;
          }
        }
      });
    },

    applyFilters(filterBy, text) {
      if (filterBy && text) {
        let filterInstance = this.gridApi.getFilterInstance(filterBy);

        filterInstance.setModel({
          type: "contains",
          filter: text
        });

        this.gridApi.onFilterChanged();
      }
    },

    popSavedState(state) {
      this.gridCurrentState = {
        ...this.gridCurrentState,
        ...state
      };
      location.hash = qs.stringify(this.gridCurrentState);
    },

    applyTooltips() {
      $('[data-toggle="tooltip"]').tooltip();
    },

    applyPlaceholders() {
      $('input[ref="eFloatingFilterText"], input[ref="eInput"]').each(function() {
        $(this).attr("placeholder", "Filter...");
      });
    },

    sizeToFit() {
      this.gridApi.sizeColumnsToFit();
    },

    applyColumnDefinition() {
      this.columnDefs = this.columns;
      this.popSavedState({visibleColumns: this.visibleColumns});

      setTimeout(() => {
        this.applyTooltips();
        this.applyPlaceholders();
        this.sizeToFit();
      }, 1);
    },

    onGridReady() {
      this.isLoading = true;

      this.$http({
        method: "get",
        url: this.requestUrl
      }).then(
        response => {
          this.fh = response.data.filters;
          this.applyColumnDefinition();
          this.rowData = response.data.list.map((item) => {
            return {
              ...item,
              user: item.user && item.user.email,
            };
          });
          this.formData = response.data.promotions
        },
        error => {
          this.onError(error);
        }
      ).finally(() => {
        this.isLoading = false;
      });
    },


    userCellRenderer(params) {
      return params.value  ? `<span class="cell-filter-clickable">${params.value}</span>` : 'N/A';
    },

    redemptionCellRenderer(params) {
      if (!params.value) return  '';
      const {email, bonus_amount, serial_number, id, project_id, error_class, incomplete} = params.value;
      const user = `Customer: ${email || 'N/A'}<br/>`;
      const sn = `Card Number: ${serial_number || 'N/A'}${bonus_amount ? `<br/>Bonus Amount: ${bonus_amount}`: ''}`;
      const link = id && project_id ? `<br/><a href=${location.protocol}//${location.host}/admin/projects/${project_id}/redemptions/${id} target="_blank">Show redemption <i class="fa fa-external-link"/></a>` : '';
      const status = incomplete ? `<br/><span class="text-warning"><i class="fa fa-adjust"></i> Incomplete</span>` : '';
      const error = error_class ? `<br/><span class="text-danger"><i class="fa fa-exclamation-circle"></i> ${error_class}</span>` : '';

      return `<p>${user}${sn}${status}${error}${link}</p>`;
    },

    dateTimeCellRenderer(params) {
      let res = 'N/A';

      if (params && params.value) {
        res = `${this.$moment(params.value).utc().format('lll')} UTC`;
      }

      return res;
    },

    onError(error) {
      this.hasError = true;
      this.message =
        (error.data && error.data.message) ||
        "Something went wrong. Please try again";

      this.hideNotification();
    },

    onSuccess(message) {
      this.message = message || "Success.";
      this.hideNotification();
    },

    hideNotification(timeout) {
      setTimeout(() => {
        this.message = null;
        this.hasError = false;
      }, timeout || 5000);
    },

    onFormSuccess(redemptionStatusErrorMessage) {
      if (redemptionStatusErrorMessage) {
        this.message = redemptionStatusErrorMessage;
        this.hasError = true;
      } else {
        this.message = "Manual redemption was created successfully.";
        this.hasError = false;
      }

      this.hideNotification();

      this.onGridReady();
    },

    getFilter(filterName, options) {
      const filter = this.fh.find(item => (item.key === filterName));

      return {
        filter: options && options.filterType || "agTextColumnFilter",
        floatingFilter: true,
        filterParams: filterParams({
          type: filter && filter.type,
          list: filter && filter.value,
          suppressAndOrCondition: filter && filter.unique || false,
        }),
      }
    },
  },

  computed: {
    requestUrl() {
      return `${window.location.origin}${this.url}`;
    },

    gridState() {
      return location.hash ? qs.parse(location.hash.substring(1)) : {}
    },

    columns() {
      let list = [];
      const optionsList = {
        id: {
          headerName: this.columnsListNames["id"],
          field: "id",
          minWidth: 30,
          maxWidth: 50,
          cellClass: ['ag-cell-auto'],
          autoHeight: true,
          wrapText: true,
          sortable: true,
          // ...this.getFilter('request_params'),
        },

        redemption: {
          headerName: this.columnsListNames["redemption"],
          field: "redemption",
          autoHeight: true,
          wrapText: true,
          sortable: false,
          minWidth: 250,
          cellRenderer: this.redemptionCellRenderer
        },

        user: {
          headerName: this.columnsListNames["user"],
          field: "user",
          minWidth: 150,
          cellClass: ['ag-cell-auto'],
          autoHeight: true,
          wrapText: true,
          cellRenderer: this.userCellRenderer,
          sortable: true,
          ...this.getFilter('user')
        },

        justification: {
          headerName: this.columnsListNames["justification"],
          field: "justification",
          minWidth: 150,
          cellClass: ['ag-cell-auto'],
          autoHeight: true,
          wrapText: true,
          sortable: true,
          ...this.getFilter('justification')
        },

        time: {
          headerName: this.columnsListNames["time"],
          field: "time",
          cellClass: ['ag-cell-center', 'ag-cell-auto'],
          autoHeight: true,
          wrapText: true,
          maxWidth: 200,
          minWidth: 150,
          sortable: true,
          cellRenderer: this.dateTimeCellRenderer,
          // ...this.getFilter('time')
        }
      };

      this.columnsList.forEach(item => {
        if (this.visibleColumns.indexOf(item) !== -1) {
          list.push(optionsList[item] || []);
        }
      });
      return list;
    }
  }
};
</script>
