<template>
  <div class="form-group text-editor-container col mt-2 mb-4 px-0">
    <label class="editor-label form-label" :for="rid">
      {{componentProperties.options.label}}
      <!--span v-if="componentProperties.options.required">*</span-->
    </label>
    <textarea
        class="form-control"
        v-model="componentProperties.options.value"
        :id="rid"
        :readonly="componentProperties.readonly"
        :disabled="componentProperties.readonly"
        ref="textarea"></textarea>
    <template v-if="componentProperties.options.hint">
      <div class="small my-1 pr-4 font-italic lh-sm" v-html="componentProperties.options.hint"/>
    </template>
  </div>
</template>

<script>
import config from "../../config";

export default {
  name: 'textEditor',
  props: ['options', 'locale', 'id', 'name', 'languageId', 'language', 'type', 'value', 'readonly'],
  data: function () {
    return {
      rid: `_${Math.random().toString(32).substring(2)}`,
      config: {},
    }
  },

  computed: {
    opt: {
      get() {
        return {
          required: this.options.required,
          label: this.options.label || '',
          value: this.value || '',
          hint: this.options.hint
        }
      },
      set(v) {},
    },

    componentProperties() {
      return {
        type: this.type,
        is: this.$options._componentTag,
        id: this.id || '',
        locale: this.locale,
        name: this.name,
        languageId: this.languageId,
        readonly: this.readonly,
        options: this.opt
      }
    },
  },

  mounted: function () {
    this.initRichTextEditor();
  },

  methods: {
    initRichTextEditor() {
      const editorConfig = {
        selector: `textarea#` + this.rid,
        readonly: this.readonly,
        setup:  (ed) => {
          ed.on("change", () => {
            this.componentProperties.options.value = ed.getBody().innerHTML;
          })
        },
      };

      tinymce.init(Object.assign(config.tinySettings, editorConfig));
    }
  }
}
</script>

<style scoped>
  .editor-label {
    display: block;
    margin: 15px 0 5px 0;
    font-size: 15px;
  }
</style>
