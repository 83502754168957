<template>
  <div class="mt-4 align-items-baseline col-md-6 px-0 form-group">
    <label class="form-label">
      {{componentProperties.options.label}}
      <!--span v-if="componentProperties.options.required">*</span-->
    </label>
    <div v-for="(o, index) in componentProperties.options.list">
      <input type="radio"
             :id="`id__${componentProperties.languageId}__${o.value}`"
             :name="componentProperties.name"
             :disabled="componentProperties.readonly"
             v-model="componentProperties.options.value"
             :required="componentProperties.options.required"
             :value="o.value"
             @change="$emit('change', componentProperties.options.value)"
             ref="radio"/>
      <label :for="`id__${componentProperties.languageId}__${o.value}`" class="ml-2">{{o.label}}</label>
    </div>
  </div>
</template>

<script>
export default {
  name: 'labeledRadio',
  components:{},
  props: ['options', 'locale', 'id', 'name', 'languageId', 'language', 'value', 'readonly', 'extra'],

  computed: {
    opt: {
      get() {
        return {
          ...this.options,
          value: this.value,
        };
      },

      set(v) {},
    },

    componentProperties() {
      return {
        type: this.type,
        title: this.title,
        is: this.$options._componentTag,
        id: this.id || '',
        locale: this.locale,
        name: this.name,
        languageId: this.languageId,
        readonly: this.readonly,
        options: this.opt,
      };
    },
  },
};
</script>
