<template>
<div id="stepTemplate" class="card card-bordered card-default step-panel mb-3">
        <div class="card-header">
            <h4 class="step-title">Step <span class="step-counter"></span>: {{this.stepName}}</h4>
            <span class="close-step" v-on:click="remove">
                    <i class="fa fa-times" aria-hidden="true"></i>
                </span>
        </div>
        <div class="card-body d-flex">
            <div class="col-lg-5">
                <select data-width="100%" @change="selectStep" class="stepselector">
                  <option v-for="step in blocksData" :key="step.stepName" :data-block="step.blockClass">{{ step.stepName }}</option>
                </select>
            </div>
            <userInput v-if="blockClass === 'Block::FormInput' || stepName==='User Inputs Information'" :stepsObj="blocksData" :blockClass="blockClass" :stepOptions="stepOptions"></userInput>
            <userForm v-if="blockClass === 'Block::UserForm' || stepName==='User Form'" :stepsObj="blocksData" :blockClass="blockClass" :stepOptions="stepOptions" :readOnly="readOnly"></userForm>
            <cardValidator v-if="blockClass === 'Block::LocalValidator' || blockClass === 'Block::TVValidator' || blockClass === 'Block::ATTValidator' || stepName==='Card Is Validated'" :stepsObj="blocksData" :blockClass="blockClass" :stepOptions="stepOptions"></cardValidator>
            <selectBonus v-if="blockClass === 'Block::SelectBonus' || stepName==='Select Bonus'" :stepsObj="blocksData" :blockClass="blockClass" :stepOptions="stepOptions"></selectBonus>
            <redeemer v-if="blockClass === 'Block::Redeemer' || stepName==='Redeemer'" :stepsObj="blocksData" :blockClass="blockClass" :stepOptions="stepOptions" :showLinkToBonusCodes="showLinkToBonusCodes" :enableBonusCodesLink="enableBonusCodesLink"></redeemer>
            <externalLink v-if="blockClass === 'Block::ExternalLink' || stepName==='External Link'" :stepsObj="blocksData" :blockClass="blockClass" :stepOptions="stepOptions"></externalLink>
            <additionalRedeemer v-if="blockClass === 'Block::AdditionalRedeemer' || stepName==='Additional Redeemer'" :stepsObj="blocksData" :blockClass="blockClass" :stepOptions="stepOptions"></additionalRedeemer>
            <createOrder v-if="blockClass === 'Block::CreateOrder' || stepName==='Create Order'" :stepsObj="blocksData" :blockClass="blockClass" :stepOptions="stepOptions"></createOrder>
            <denomCheck v-if="blockClass === 'Block::DenomCheck' || stepName==='Denom Check'" :stepsObj="blocksData" :blockClass="blockClass" :stepOptions="stepOptions"></denomCheck>
            <emailDelivery v-if="blockClass === 'Block::EmailDelivery' || stepName==='Email Delivery'" :stepsObj="blocksData" :blockClass="blockClass" :stepOptions="stepOptions"></emailDelivery>
            <confirmation v-if="blockClass === 'Block::Confirmation' || stepName==='Confirmation'" :stepsObj="blocksData" :blockClass="blockClass" :stepOptions="stepOptions" :projectName="projectName"></confirmation>
            <getBonus v-if="blockClass === 'Block::GetBonus' || stepName==='Get Bonus'" :stepsObj="blocksData" :blockClass="blockClass" :stepOptions="stepOptions"></getBonus>
            <partnerSelection v-if="blockClass === 'Block::PartnerSelection' || stepName==='Partner Selection'" :stepsObj="blocksData" :blockClass="blockClass" :stepOptions="stepOptions"></partnerSelection>
            <tvRedeem v-if="blockClass === 'Block::TVRedeem' || stepName==='TV Redeem'" :stepsObj="blocksData" :blockClass="blockClass" :stepOptions="stepOptions"></tvRedeem>
            <ddsOrder v-if="blockClass === 'Block::DDSOrder' || stepName==='DDS Order'" :stepsObj="blocksData" :blockClass="blockClass" :stepOptions="stepOptions"></ddsOrder>
        </div>
    </div>
</template>

<script>
import userInput from './userInput'
import cardValidator from './cardValidator'
import redeemer from './redeemer'
import externalLink from './externalLink'
import additionalRedeemer from './additionalRedeemer'
import createOrder from './createOrder'
import denomCheck from './denomCheck'
import selectBonus from './selectBonus'
import emailDelivery from './emailDelivery'
import confirmation from './confirmation'
import getBonus from './getBonus'
import userForm from './userForm'
import partnerSelection from './partnerSelection'
import tvRedeem from './tvRedeem'
import ddsOrder from './ddsOrder'

export default{
  name: 'step',
  components:{userInput, cardValidator, selectBonus, redeemer, externalLink, additionalRedeemer, createOrder, denomCheck, emailDelivery, getBonus, confirmation, userForm, partnerSelection, tvRedeem, ddsOrder},
  props: ['stepInfo', 'enableLegacyBlocks', 'blocksData', 'readOnly', 'showLinkToBonusCodes', 'enableBonusCodesLink', 'projectName'],

    data: function() {
        return {
            stepNumber: 0,
            stepData: { blockClass: '', options: {} },
            blocksData: this.blocksData,
            legacyBlocksList: this.$parent.legacyBlocksList
        }
    },
    computed: {
        blockClass: function() { return this.stepData.blockClass },
        blocksDataWithoutLegacy: function(){
          return this.blocksData.filter(block => { return !block.hasOwnProperty('disabled')})
        },
        stepIndex: function() {
            return Array.from(this.$parent.$el.children[1].children).indexOf(this.$el);
        },
        stepOptions: function() {
            if (this.stepData.options) {
                return this.stepData.options;
            } else {
                return {};
            };
        },
        stepName: function() {
          if(this.stepData.blockClass.length > 0 && this.stepData.blockClass.includes('Validator')){
              return 'Card Is Validated'
            } else if (this.stepData.blockClass.length > 0) {
               return this.blocksData.find((block) => { return block.blockClass == this.stepData.blockClass }).stepName;
            }
        }
    },
    created: function(){
      this.updateBlockStructure();
    },
    mounted: function() {
        if (this.stepInfo) {
            this.stepData = this.stepInfo;
        }

        $(this.$el).find('select.stepselector').selectpicker();
        $(this.$el).find('select.stepselector').selectpicker('val', this.stepName);

        if (!this.readOnly) $('#stepsContainer').sortable({
            axis: 'y'
        });
    },
    updated: function() {
        if (this.readOnly)
            this.disableInputs();
    },
    methods: {
        updateBlockStructure: function(){
          if(!this.enableLegacyBlocks){
            this.blocksData = this.blocksDataWithoutLegacy
          }
        },
        remove: function() {
            if (this.readOnly) return;
            this.$el.remove();
            var stepIndex = this.$parent.$children.indexOf(this);
            this.$parent.$children.splice(stepIndex, 1);
            this.$emit('removeStep');
        },
        selectStep: function(e) {
            this.stepData.blockClass = ($(e.target).find('option:selected').attr('data-block'));
        },
        disableInputs: function() {
            $(this.$el).find('.btn.dropdown-toggle, input').attr('disabled', 'disabled');
        }
    }
}
</script>
