<template>
  <div class="row">
    <div class="col-3">
      <label class="form-check-label mb-2">
        Link Type
      </label>

      <div class="form-check ml-1">
        <input class="form-check-input"
               type="radio"
               :name="`product[product_settings_attributes][${index}][pull_from_redemption_url]`"
               :id="`pr_${value.rid}_true`"
               v-model="pullFromRedemption"
               :value="true">

        <label class="form-check-label" :for="`pr_${value.rid}_true`">
          Pull from Redemption
        </label>
      </div>
      <div class="form-check ml-1">
        <input class="form-check-input"
               type="radio"
               :name="`product[product_settings_attributes][${index}][pull_from_redemption_url]`"
               :id="`pr_${value.rid}_false`"
               v-model="pullFromRedemption"
               :value="false">
        <label class="form-check-label" :for="`pr_${value.rid}_false`">
          Text Link
        </label>
      </div>
    </div>
    <div class="col-9 align-self-end">
      <div class="mb-3" v-if="!pullFromRedemption">
        <label class="form-label" :for="`link_${value.rid}`">Link</label>
        <input
            type="text"
            class="form-control w-100"
            :id="`link_${value.rid}`"
            :name="`product[product_settings_attributes][${index}][link]`"
            v-model="link"
        />
        <span class="small d-inline-block">
          You can add any source, such as <code>%{pin}</code>, <code>%{pan}</code>, <code>%{code}</code>, etc. Link example: <code>https://link-example.com/redeem?code=%{pin}</code>
        </span>
        <error-hint field-name="link" :value="value" class="mt-1"/>
      </div>

      <div class="d-flex flex-row mb-3">
        <div class="form-check">
          <input class="form-check-input"
                 type="checkbox"
                 :name="`product[product_settings_attributes][${index}][button]`"
                 :id="`button_${value.rid}`"
                 v-model="button"
                 :value="true">
          <label class="form-check-label text-nowrap" :for="`button_${value.rid}`">
            Show link as a button
          </label>
          <template v-if="!button">
            <input type="hidden" :name="`product[product_settings_attributes][${index}][button]`" value="false">
          </template>
        </div>

        <div class="form-check ml-3">
          <input class="form-check-input"
                 type="checkbox"
                 :name="`product[product_settings_attributes][${index}][link_as_label]`"
                 :id="`link_as_label_${value.rid}`"
                 v-model="link_as_label"
                 :value="true">
          <label class="form-check-label text-nowrap" :for="`link_as_label_${value.rid}`">
            Show full link
          </label>
          <template v-if="!link_as_label">
            <input type="hidden" :name="`product[product_settings_attributes][${index}][link_as_label]`" value="false">
          </template>
        </div>
      </div>

      <div v-if="!link_as_label" class="row mt-3">
        <template v-for="(lang, idx) in languages">
          <div class="col-4 mb-3">
            <label class="form-label" :for="`label_${idx}_${value.rid}`">Label ({{lang.name}})</label>
            <input
                type="text"
                class="form-control"
                :id="`label_${idx}_${value.rid}`"
                :name="`${propertyNameSuffix}[${idx}][value]`"
                v-model="labels[lang.id]"
            />
            <input type="hidden" :name="`${propertyNameSuffix}[${idx}][id]`" :value="getPropsByLanguageId(lang.id).id || ''" />
            <input type="hidden" :name="`${propertyNameSuffix}[${idx}][language_id]`" :value="lang.id" />
            <input type="hidden" :name="`${propertyNameSuffix}[${idx}][name]`" value="url.label" />
             <small>
              Leave empty to use default label.
            </small>
          </div>
        </template>
      </div>
    </div>
  </div>
</template>

<script>
import ErrorHint from "./ErrorHint.vue";

export default {
  components: {
    ErrorHint,
  },

  props: {
    languages: {
      required: true,
      type: Array, // of languages
    },
    value: {
      required: true,
      type: String,
    },
    index: {
      required: true,
      type: Number,
    },
  },
  data() {
    return {
      pullFromRedemption: false,
      button: false,
      link: '',
      link_as_label: false,
      labels: {},
    };
  },

  mounted() {
    this.setValues();
  },

  watch: {
    value: {
      handler() {
        this.setValues();
      },
      deep: true,
    },
  },

  methods: {
    getPropsByLanguageId(languageId) {
      return this.value.properties.find(prop => prop.language_id == languageId) || {};
    },

    setValues() {
      const labels = {};
      this.languages.forEach((lang) => {
        const prop = this.getPropsByLanguageId(lang.id);
        labels[lang.id] = prop && prop.value || '';
      });

      this.labels = labels;
      this.pullFromRedemption = this.value.pull_from_redemption_url || false;
      this.button = this.value.button || false;
      this.link = this.value.link || this.options && this.options[0];
      this.link_as_label = this.value.link_as_label || false;
    },
  },

  computed: {
    propertyNameSuffix() {
      return `product[product_settings_attributes][${this.index}][properties_attributes]`;
    }
  }
}
</script>
