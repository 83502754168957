<template>
  <div class="row">
    <div class="col-3">
      <label class="form-label" :for="`barcode_${value.rid}`">Barcode Format</label>
      <select class="form-control"
              :id="`barcode_${value.rid}`"
              :name="`product[product_settings_attributes][${index}][barcode_format]`"
              v-model="barcodeFormat"
      >
        <template v-for="opt in options">
          <option :value="opt">{{opt}}</option>
        </template>
      </select>
      <error-hint field-name="barcode_format" :value="value" />
    </div>
    <div class="col-3">
      <label class="form-label" :for="`source_${value.rid}`">Source</label>
      <select class="form-control"
              :id="`barcode_${value.rid}`"
              :name="`product[product_settings_attributes][${index}][source]`"
              v-model="source"
      >
        <template v-for="opt in sources">
          <option :value="opt">{{opt}}</option>
        </template>
      </select>
      <small>
        Customized value for barcode generation <br>
        Set <code>default</code> to use default formatting depends on Barcode format
      </small>
      <error-hint field-name="source" :value="value" />
    </div>
    <div class="col-3 pl-2 mt-4 pt-2">
      <template v-if="!humanReadable">
        <input type="hidden" :name="`product[product_settings_attributes][${index}][human_readable]`" value="false">
      </template>

      <div class="form-check pt-1">
        <input class="form-check-input" type="checkbox" :id="`hr_${value.rid}`" :name="`product[product_settings_attributes][${index}][human_readable]`" v-model="humanReadable" :value="true">
        <label class="form-check-label" :for="`hr_${value.rid}`">Human Readable</label>
      </div>
      <error-hint field-name="human_readable" :value="value" />
    </div>
  </div>
</template>

<script>
import ErrorHint from './ErrorHint.vue';
export default {
  components: {
    ErrorHint,
  },

  props: {
    options: {
      required: true,
      type: Array // of barcode options
    },
    sources: {
      required: true,
      type: Array // of source options
    },
    index: {
      required: true,
      type: Number,
    },
    value: {
      required: false,
      type: Object,
      default: () => ({}),
    }
  },
  data() {
    return {
      barcodeFormat: null,
      source: null,
      humanReadable: false,
    };
  },

  mounted() {
    this.setValues();
  },

  watch: {
    value: {
      handler() {
        this.setValues();
      },
      deep: true,
    },
  },

  methods: {
    setValues() {
      this.barcodeFormat = this.value.barcode_format || this.options && this.options[0];
      this.source = this.value.source || this.sources && this.sources[0];
      this.humanReadable = this.value.human_readable || false;
    },
  },
}
</script>
