<template>
  <div class="status-counter-list">
    <div class="d-flex mb-3 justify-content-between" id="statusCountersList">
      <div class="d-flex justify-content-start align-items-center pr-2">

        <statusCounter v-for="(value, key) in sortedPromosByStatus"
                       :status="key"
                       :key="key"
                       :selectedStatus="selectedStatus"
                       @statusFilter="statusFilter"
                       :promosAmount="value"/>

        <div>
          <period-selector v-model="periodValue"/>
        </div>
        <span @click.prevent="toggleMoreFilter"
              class="ml-2 mr-2 filter_button">{{filterText}}</span>

      </div>
      <div class="d-flex reports align-items-center">
        <span class="all-promos badge bg-default" v-if="$can('read', 'ProjectReport')" @click="showReportModal">Project Report</span>
        <span class="all-promos badge bg-default ml-3" @click="dashboardReport">Dashboard Report</span>
      </div>
      <report-modal v-if="showModal" @hideModal="hideReportModal"></report-modal>
    </div>

    <div :class="filterClass">
      <span class="pt-2 pr-3">Filter by:</span>

      <select @change="filterByBranch" class="branch-filter mr-3" v-model="selectedBranch">
        <option value="all">All branches</option>
        <option v-for="branch in branches" :key="branch.flag" :value="branch.flag">{{branch.name}}</option>
      </select>

      <select @change="filterByProject" class="project-filter mr-1" v-model="selectedProject">
        <option value="all">All projects</option>
        <option v-for="project in projects" :key="project.label" :value="project.label">{{project.name}}</option>
      </select>

      <span v-if="showAllMoreFilter" class="all-promos reset-filters pt-2" @click="showAllNotMorePromos">
        <i class="fa fa-close" aria-hidden="true"></i>

      </span>
    </div>

  </div>
</template>

<script>
import statusCounter from './statusCounter';
import reportModal from "./reportModal";
import PeriodSelector from "./periodSelector.vue";

export default {
  name: 'statusCountersList',
  components: {
    "statusCounter": statusCounter,
    "reportModal": reportModal,
    PeriodSelector,
  },
  props: ['sortedPromosByStatus', 'promotions', 'rawPromotions', 'selectedBranch', 'selectedPeriod', 'showAllMoreFilter', 'selectedStatus', 'selectedProject'],

  data() {
    return {
      showModal: false,
      showFilter: false,
    }
  },

  mounted() {
    $('.branch-filter, .project-filter').selectpicker();
  },

  watch: {
    selectedBranch(value) {
      if (value) {
        this.showFilter = true;
      }
    },

    selectedProject(value) {
      if (value) {
        this.showFilter = true;
      }
    },
  },

  methods: {
    showAllNotMorePromos: function () {
      this.$emit('branchFilter', 'all');
      this.$emit('projectFilter', 'all');
    },
    dashboardReport: function () {
      let reportUrl = window.location.href + '/dashboard_report.json';
      this.$http.get(reportUrl).then(response => {
        downloadFile(response.data.url);
      });
    },
    showReportModal: function () {
      this.showModal = true;
    },
    hideReportModal() {
      this.showModal = false;
    },
    filterByBranch: function () {
      this.$emit('branchFilter', this.selectedBranch);
    },
    filterByProject: function () {
      this.$emit('projectFilter', this.selectedProject);
    },

    toggleMoreFilter: function() {
      if (this.showFilter) {
        this.showAllNotMorePromos();
        this.$emit('statusFilter', 'all');
        this.$emit('periodFilter', '1');
      }

      this.showFilter = !this.showFilter;
    },

    statusFilter(data) {
      this.$emit('statusFilter', data);
    }
  },

  computed: {
    periodValue: {
      get() {
        return this.selectedPeriod;
      },
      set(val) {
        this.$emit('periodFilter', val);
      },
    },

    branches: function () {
      let branches = {};
      this.rawPromotions.forEach(promotion => {
        branches[promotion.branchName] = promotion.branch;
      });

      let result = Object.keys(branches).map(branchName => {
        return {
          name: branchName,
          flag: branches[branchName]
        }
      });

      return result;
    },
    projects: function () {
      let result = {};
      this.rawPromotions.forEach(promotion => {
        result[promotion.project.name] = promotion.project.fullName;
      });

      return Object.keys(result).map(label => {
        return {
          label: label,
          name: result[label]
        }
      });
    },

    filterClass() {
      return 'd-flex mb-3 ml-3 filters' + (!this.showFilter ? ' hidden' :'');
    },

    filterText() {
      return (this.showFilter ? 'Clear all' : 'More') + ' filters';
    }
  },
}
</script>
