<template>
  <div>
    <div v-if="showNotice" :class="`alert alert-${type} d-block opacity-1 my-4`">
      {{title}}
      <br>
      {{promoPeriodHint}}
    </div>
  </div>
</template>

<script>

export default {
  name: 'RulesNotice',
  props: {
    rules: {
      required: true,
      type: Array,
    },
    promotion: {
      required: true,
      type: Object
    },
    title: {
      type: String,
      required: false,
      default: 'Please note there are dates in this promotion when no rule is active due to the current configuration. You may add additional rules or extend existing timeframes to cover all the dates.'
    },
    type: {
      required: false,
      type: String,
      default: 'info' // warning, danger, success
    }
  },

  computed: {
    promoPeriodHint() {
      const dates = ['activation_start_datetime_with_timezone', 'activation_end_datetime_with_timezone', 'claim_end_datetime_with_timezone']
          .map(item => this.promotion[item].split('T')[0]);

      return `Promotion period: ${dates[0]} – ${dates[1]}, claim end at ${dates[2]}`;
    },
    promotionStartAt() {
      return this.$moment.tz(this.promotion.activation_start_datetime_with_timezone, this.promotion.activation_start_timezone).startOf('d');
    },
    promotionEndAt() {
      return this.$moment.tz(this.promotion.activation_end_datetime_with_timezone, this.promotion.activation_end_timezone).endOf('d');
    },

    showNotice() {
      let res = false;
      const secondaryRules = this.rules.filter(rule => rule.secondaryRule);

      // if no rules
      if (!this.rules.length) {
        return false;
      }

      // if at least one non-secondary rule exists
      if (secondaryRules.length !== this.rules.length) {
        return false;
      }

      const list = secondaryRules.map(item => ({
        ...item,
        start: this.$moment.tz(item.secondaryData.activationStartDate, this.promotion.activation_start_timezone).startOf('d'),
        end: this.$moment.tz(item.secondaryData.activationEndDate, this.promotion.activation_end_timezone).endOf('d'),
      }));

      let initial =  this.promotionStartAt;

      while(!res && initial.isSameOrBefore(this.promotionEndAt, 'd')) {
        if (!list.find(item => initial.isSameOrAfter(item.start) && initial.isSameOrBefore(item.end))) {
          res = true;
          break;
        }

        initial = initial.clone().add(1, 'd');
      }

      return res;
    }
  }
}
</script>
