<template>
  <div class="tires-container">
    <span class="text-danger" v-if="tiersOverlap">Tiers ranges overlap each other</span>
    <tire
      v-for="tire in tiersCount"
      :key="tire"
      :id="tire"
      :minAmount="minAmount"
      :maxAmount="maxAmount"
      :currency="currency"
      :tierData="tiersData[tire-1]"
      @setTier="setTier"
      @removeTier="removeTier"
      :tierIsRemovable="tierIsRemovable"
      :tiers="tiers"></tire>
    <button class="btn btn-primary mt-2" @click="addTier">Add</button>
  </div>
</template>

<script>
import tire from './tire'

export default {
  name: 'tires',
  props: {
    'tiersData':{ type: Array },
    'minAmount':{ type: String, required: true },
    'maxAmount': { type: String, required: true },
    'currency': { type: String, required: true }
  },
  components: { tire },
  data: function(){
    return {
      tiersCount: 1,
      tiers: []
    }
  },
  mounted: function() {
    if( this.tiersData.length > 1 ){ this.tiersCount = this.tiersData.length }
    this.$emit('validateAmount', this.validateTiers())
    },
  computed: {
    tierIsRemovable: function(){ return this.tiers.length > 1 ? true : false  }},
  methods: {
    validateTiers: function(){
      let allTiersCorrect = this.$children.map( tier => tier.tierIsValid ).find(tierValidity => tierValidity == false)
      return !allTiersCorrect && allTiersCorrect !== undefined ? false : true
    },
    addTier: function(){
      this.$emit('validateAmount', this.validateTiers())
      this.tiersCount++
    },
    setTier: function(){
      this.tiers = this.$children.map( tier => tier.tireInfo )
      this.$emit('setTiers', this.tiers)
      this.$emit('validateAmount', this.validateTiers())
    },
    removeTier: function(id){
      let tireIndex = this.$children.findIndex( tire => tire.id == id );
      this.$children.splice(tireIndex, 1);
      this.setTier()
    }
  }
}
</script>

<style scoped></style>
