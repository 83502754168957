<template>
  <div class="form-group">
    <label for="exampleTextarea">
      UPCs
      <span class="text-danger">*</span>
    </label>
    <textarea class="form-control" id="exampleTextarea" rows="3" v-model="UPCs"></textarea>
  </div>
</template>

<script>
export default {
  name: "upcInput",
  data: function() {
    return {
      UPCs: ""
    };
  },
  watch: {
    UPCs: function() {
      this.$emit("upcsChanged", this.UPCs.match(/^\d+/gm));
    }
  }
};
</script>

<style scoped></style>
