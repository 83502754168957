import moment from 'moment-timezone';
import tzList from './tz.json';

export default () => {
  const tzSet = new Set(Object.values(tzList));
  const timeZones = moment.tz.names(true).filter(key => tzSet.has(key));

  let offsetTmz = timeZones.map(tz => {
    const zone = moment.tz(tz);
    const prefix = RegExp(/^-|\d/).test(zone.format('z')) ? '' : `${zone.format('z')} - `;

    return {
      offset: zone._offset,
      label: `(GMT ${zone.format('Z')}) ${prefix}${tz.replace(/_/g,' ')}`,
      value: tz
    }
  });

  return offsetTmz.sort((a,b) => (a.offset - b.offset));
};
