<template>
  <div class="mb-4 py-3 border rounded">
    <h5 class="mx-3 no-select" @dblclick="() => {debug = !debug}">Code Section</h5>

    <div class="mx-3" v-if="!values.length">
      <div class="alert alert-warning alert_permanent" role="alert">
        No Code Sections defined. Please add at least one.
      </div>
    </div>

    <table  v-else class="table table-striped table-hover text-left border-left-0 border-right-0">
      <draggable element="tbody" v-model="values" :options="{handle: '.drag-handler'}">
        <tr v-for="(item, index) in values" :key="item.rid">
          <td class="pr-0" style="width: 6px">
            <span :class="`mt-4 pt-3 d-block ${(values.length > 1) ? 'drag-handler' : ''}`">
              <i class="fa fa-unsorted"></i>
            </span>
          </td>
          <td>
            <input type="hidden" :name="`product[product_settings_attributes][${index}][type]`" :value="item.type" />
            <input type="hidden" :name="`product[product_settings_attributes][${index}][position]`" :value="index+1">
            <input type="hidden" :name="`product[product_settings_attributes][${index}][id]`" :value="item.id">
            <template v-if="item.type ==='ProductCodeSetting'">
              <Code
                :options="options.text_block_sources"
                :languages="languages"
                :value="item"
                :index="index"
              />
            </template>
            <template v-else-if="item.type ==='ProductBarcodeSetting'">
              <Barcode
                :options="options.barcode_formats"
                :sources="options.barcode_sources"
                :value="item"
                :index="index"
              />
            </template>
            <template v-else-if="item.type ==='ProductUrlSetting'">
              <Link
                :languages="languages"
                :value="item"
                :index="index"
              />
            </template>
          </td>
          <td class="text-right" valign="center">
            <button
                class="btn btn-sm btn-outline-danger"
                :disabled="values.length <= 1"
                @click="(e) => remove(e, item)">Remove</button>
          </td>
        </tr>
      </draggable>
    </table>

    <div class="mt-3 mx-3">
      <div class="dropdown">
        <button class="btn btn-primary dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
          Add new Code Section
        </button>
        <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
          <template v-for="kind in Object.entries(mapping)">
            <span class="dropdown-item" @click="addNew(kind[0])">{{kind[1]}}</span>
          </template>
        </div>
      </div>
    </div>

    <template v-for="(id, index) in toDelete">
      <input type="hidden" :name="`product[product_settings_attributes][${index + values.length}][id]`" :value="id" />
      <input type="hidden" :name="`product[product_settings_attributes][${index + values.length}][_destroy]`" value="true" />
    </template>

    <pre v-if="debug">{{values}}</pre>
  </div>
</template>

<script>
import Code from './Code.vue';
import Barcode from './Barcode.vue';
import Link from './Link.vue';
import draggable from 'vuedraggable'
import {sortBy} from 'lodash';
export default {
  components: {
    draggable,
    Code,
    Barcode,
    Link,
  },
  props: {
    availableLanguages: {
      required: true,
      type: Array, // of languages
    },
    availableData: {
      required: Object,
      type: Array, // of options
    },
    productSettings: {
      required: true,
      type: Object // of default settings
    },
  },
  data() {
    return {
      debug: false,
      mapping: {
        ProductCodeSetting: 'Text Block',
        ProductBarcodeSetting: 'Barcode',
        ProductUrlSetting: 'Link',
      },
      codeTypeModel: 'ProductCodeSetting',

      values: sortBy(JSON.parse(this.productSettings), 'position'),
      languages: JSON.parse(this.availableLanguages),
      options: JSON.parse(this.availableData),

      toDelete: [],
    };
  },

  mounted() {
    this.values = this.values.map(item => ({
      rid: this.newId(),
      ...item,
    }));
  },

  methods: {
    newId() {
      return Math.random().toString(32).substring(2);
    },

    addNew(type) {
      const rid = this.newId()
      this.values.push({
        rid,
        id: '',
        type,
        position: null,
        source: null,
        barcode_format: null,
        human_readable: null,
        link: null,
        pull_from_redemption_url: null,
        properties: [],
      });
    },

    remove(e, item) {
      e.preventDefault();
      e.stopPropagation();

      if (item.id) {
        this.toDelete.push(item.id);
      }

      this.values = this.values.filter(v => v.rid !== item.rid);
    },
  }
};
</script>
