<template>
  <div class="action-wrapper">
    <p>* All fields are required</p>
    <div class="loader" v-if="isLoading"></div>
    <form @submit.prevent="submitForm">
      <div class="row">
        <div class="col-sm-12">
          <div class="form-group mr-0">
            <label for="d_promotion">Promotion *</label>
            <select id="d_promotion" v-model="model.promotion_id" class="form-control">
              <option v-for="promotion in promotionOptions" :value="promotion.id">{{ promotion.name }}</option>
            </select>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-sm-6">
          <div class="form-group mr-0">
            <label for="d_bonus_products_ids">Sponsoring product *</label>
            <template v-if="model.promotion_id && !sponsoringProductsList.length">
              <div class="alert alert-warning">No sponsoring products</div>
            </template>
            <template v-else>
              <select id="d_sponsoring_products_ids" v-model="model.sponsoring_products" required :disabled="!model.promotion_id" class="form-control">
                <option v-for="product in sponsoringProductsList" :value="product.id">{{ product.upc }}</option>
              </select>
            </template>
          </div>
        </div>
        <div class="col-sm-6">
          <div class="form-group mr-0">
            <label for="d_bonus_products_ids">Bonus product *</label>
            <template v-if="model.promotion_id && !bonusesProductsList.length">
              <div class="alert alert-warning">No bonus products</div>
            </template>
            <template v-else>
              <select id="d_bonus_products_ids" v-model="model.bonus_products" required :disabled="!model.promotion_id" class="form-control">
                <option v-for="product in bonusesProductsList" :value="product.id">{{ product.upc }}</option>
              </select>
            </template>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-sm-6">
          <div class="form-group mr-0">
            <label for="d_email" class="d-block">Customer Email Address *</label>
            <input type="email" id="d_email" v-model="model.email" required :disabled="!model.promotion_id" class="form-control"/>
          </div>
        </div>
        <div class="col-sm-4">
          <div class="form-group mr-0">
            <label for="d_serial" class="d-block">Card Number *</label>
            <input type="text" id="d_serial" v-model="model.serial" required :disabled="!model.promotion_id" class="form-control"/>
          </div>
        </div>
        <div class="col-sm-2">
          <div class="form-group mr-0">
            <label for="d_amount" class="d-block">Sponsor Product Denom *</label>
            <input type="number" id="d_amount" min="0" step="0.01" v-model="model.amount" required :disabled="!model.promotion_id" class="form-control"/>
          </div>
        </div>
      </div>

      <div class="form-group mr-0">
        <label for="d_justification">Justification *</label>
        <textarea v-model="model.justification" id="d_justification" required :disabled="!model.promotion_id" class="form-control" placeholder="Enter details about the redemption"/>
      </div>

      <div class="form-group mt-4">
        <button class="btn btn-primary mr-2" type="submit" :disabled="disabled">Send Request</button>
        <button class="btn btn-info" type="button" @click="$emit('onCancel')">Cancel</button>
      </div>
    </form>
  </div>
</template>

<script>
export default {
  props: {
    formData: {
      type: Object,
      required: true,
    }
  },

  data() {
    return {
      model: {
        promotion_id: null,
        bonus_products: null,
        sponsoring_products: null,
        email: '',
        serial: '',
        amount: '',
        justification: '',
      },
      isLoading: false,
    };
  },

  computed: {
    disabled() {
      if (this.isLoading) return true;
      const values = Object.values(this.model);
      return values.length !== values.filter((item) => !!item).length;
    },

    headers() {
      return {
        'X-CSRF-Token': document.querySelector("meta[name='csrf-token']")?.content,
        'authenticity_token': document.querySelector("input[name='authenticity_token']")?.value,
      }
    },

    promotionOptions() {
      if (!this.formData) return [];

      return this.formData.map((item) => {
        return {
          id: item.id,
          name: item.slug,
        };
      });
    },

    bonusesProductsList() {
      if (this.model.promotion_id) {
        return this.formData.find((item) => item.id === this.model.promotion_id).bonus_products;
      }

      return [];
    },

    sponsoringProductsList() {
      if (this.model.promotion_id) {
        return this.formData.find((item) => item.id === this.model.promotion_id).sponsoring_products;
      }

      return [];
    },
  },

  methods: {
    clearModel() {
      this.model = {
        promotion_id: this.model.promotion_id,
        bonus_products: null,
        sponsoring_products: null,
        email: '',
        serial: '',
        amount: '',
        justification: '',
      };
    },

    submitForm() {
      const url = `${location.protocol}//${location.host}${location.pathname}.json`;
      const data = {
        promotion_id: this.model.promotion_id,
        bonus_product_ids: [this.model.bonus_products],
        sponsoring_product_ids: [this.model.sponsoring_products],
        email: this.model.email,
        serial_number: this.model.serial,
        amount: this.model.amount,
        justification: this.model.justification,
      }

      this.isLoading = true;
      this.$http.post(
          url,
          data,
          {
            headers: this.headers,
          }).then(
          response => {
            const { data: { redemption_successful, redemption_error } } = response;

            this.$emit('onSuccess', redemption_error);
            if (redemption_successful) {
              this.clearModel();
            }
          },
          error => {
            this.$emit('onError', error.response);
          }
      ).finally(() => {
        this.isLoading = false;
      });
    }
  }
}
</script>
