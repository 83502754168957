<template>
<div class="my-3">
  <notification
      v-if="message"
      :successText="!hasError ? message : ''"
      :failureText="hasError ? message : ''"
      @close="handleClose"
  />

  <Loader v-if="loading"/>
  <template v-else-if="fields && fields.length">
    <div class="position-relative">
      <!-- search form -->
    <template v-if="step === 0">
      <h4>Search Redemption</h4>
      <p class="font-weight-bold mb-4">Please fill out the fields below to search for redemptions.</p>

      <form action="" @submit.prevent="searchRedemption">
        <template v-for="f in fields">
          <template v-if="f.type==='promotion_select'">
            <promo-select
                :value="model.promotion_id"
                :options="promotions"
                :disabled="sending || submitted"
                @change="changePromotion"
            />
          </template>
          <template v-else>
            <field :key="f.id"
                   :params="{ ...f, disabled: sending || submitted }"
                   :value="model[f.id]"
                   :merchant="model.merchant"
                   @change="value => model[f.id] = value"
            />
          </template>
        </template>

        <div class="form-group row my-2 mr-0">
          <div class="col-9 offset-3 pr-0">
            <template v-if="!submitted">
              <button type="submit"
                      class="btn btn-primary"
                      :disabled="sending">
                <template v-if="sending">
                  <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                  Searching...
                </template>
                <template v-else>
                  Search
                </template>
              </button>
            </template>

            <template v-else>
              <button class="btn btn-info mr-2"
                      type="button"
                      @click.prevent="goTo(2)">
                Submit Support Ticket
              </button>

              <button class="btn btn-outline-secondary mr-2"
                      type="reset"
                      @click.prevent="clearFields">
                Clear Form
              </button>
            </template>
          </div>
        </div>
      </form>
    </template>

<!--    resend email form-->
    <template v-else-if="step === 1">
      <h4>Search Results</h4>

      <form action="" @submit.prevent="() => { return false }">
        <template v-for="f in fields">
          <template v-if="f.type==='promotion_select'">
            <promo-select
                :value="model.promotion_id"
                :options="promotions"
                :disabled="true"
            />
          </template>
          <template v-else-if="f.id !== 'extra_email'">
            <field :key="f.id"
                   :params="{...f, disabled: f.disabled || sending }"
                   :value="model[f.id]"
                   @change="value => model[f.id] = value"
            />
          </template>
        </template>

        <!--template v-if="multipleRedemptions"-->
        <div class="col-9 offset-3 px-0">
          <h3>{{model.entities.length}} Redemption{{multipleRedemptions ? 's': ''}} found</h3>
        </div>
        <!--/template-->
        <template v-for="(item, idx) in model.entities">
          <div :class="`form-group row my-2 mr-0 py-4 pr-3 ${!(idx % 2) ? 'bg-light' : ''}`"
               :key="item.redemption_id">

            <div class="col-9 offset-3 pr-0">
              <template v-if="multipleRedemptions">
                <h5>Redemption #{{idx + 1}}</h5>
              </template>
              <template v-if="!fields.find(f => f.id=== 'promotion_id')">
                <p class="mb-0">Project: <strong v-text="item.project"/></p>
                <p v-if="item.description" v-text="item.description"/>
              </template>

              <email-history
                class="my-4"
                :data="item"
                :list="item.email_history"
              />

              <div class="my-4">
                <field :key="fields.find(f => f.id === 'extra_email')"
                       :params="{ ...fields.find(f => f.id === 'extra_email'), disabled: sending }"
                       :flat="true"
                       :value="item.extra_email"
                       @change="value => item.extra_email = value"
                >
                  <template v-slot:append>
                    <div class="ml-2">
                      <button class="btn btn-primary text-nowrap"
                              @click.prevent="resendEmail(idx)"
                              :disabled="sending">
                              {{ item.orders_history.length ? 'Re-send Email' : 'Re-send Bonus Email'  }}
                      </button>
                    </div>
                  </template>
                </field>
              </div>

              <template v-if="item.redemption_id && item.orders_history.length">
                <orders-history
                  class="my-4"
                  :list="item.orders_history"
                  />
              </template>

              <div>
                <button class="btn btn-info mr-2"
                        type="button"
                        @click.prevent="() => { prefillSubmitTicketForm(idx); goTo(2) } ">
                  Submit Support Ticket
                </button>

                <button class="btn btn-outline-secondary mr-2"
                        type="reset"
                        :disabled="sending"
                        @click="goTo(0, true)">
                  Clear Form
                </button>
              </div>
            </div>
          </div>
        </template>
      </form>
    </template>

<!--submit ticket form-->
    <template v-else-if="step === 2">
      <back-button @click="goTo((model.entities && model.entities[redemptionContext] && model.entities[redemptionContext].redemption_id) ? 1 : 0)"/>

      <h4>Submit Support Ticket</h4>
      <p class="mb-4">Please complete the form below to submit a support ticket.</p>

      <form action="" @submit.prevent="submitTicket">
        <template v-for="f in fields">
          <template v-if="f.type==='promotion_select'">
            <promo-select
                :value="model.promotion_id"
                :options="promotions"
                :disabled="!!(model.entities && model.entities[redemptionContext] && model.entities[redemptionContext].redemption_id)"
                @change="changePromotion"
            />
          </template>
          <template v-else>

          <field :key="f.id"
                 :params="{...f, disabled: sending }"
                 :value="model[f.id]"
                 @error="onError"
                 @change="value => { model[f.id] = value }"
          />
          </template>
        </template>

        <div class="form-group row my-2 mr-0">
          <div class="col-9 offset-3 pr-0">
            <button type="submit"
                    class="btn btn-primary mr-2"
                    :disabled="sending">
              <template v-if="sending">
                <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                Submitting...
              </template>
              <template v-else>
                Submit Support Ticket
              </template>
            </button>

            <button class="btn btn-outline-secondary mr-2"
                    type="reset"
                    :disabled="sending"
                    @click="clearFields">
              Clear Form
            </button>
          </div>
        </div>
      </form>
    </template>

      <div v-if="debug" class="my-4">
      <pre>model: {{model}}</pre>
      <pre>fields: {{fields}}</pre>
      <pre>promotions: {{promotions}}</pre>
      <pre>selected: {{selected}}</pre>
      <pre>role: {{role}}</pre>
    </div>
  </div>
  </template>
</div>
</template>

<script>
import { pick, cloneDeep } from 'lodash';

import BackButton from "./backButton";
import EmailHistory from "./emailHistory.vue";
import Field from "./field.vue";
import Loader from '../admin/shared_components/Loader.vue';
import Notification from "../notification.vue";
import OrdersHistory from "./ordersHistory";
import PromoSelect from "./promoSelect.vue";

export default {
  components: {
    BackButton,
    EmailHistory,
    Field,
    Loader,
    Notification,
    OrdersHistory,
    PromoSelect,
  },

  props: {
    selected: {
      required: false,
      type: Number,
    },
    promotions: {
      required: true,
      type: Object,
    },
  },

  data() {
    return {
      debug: false,
      step: 0,

      loading: false,
      sending: false,

      model: {
        promotion_id: this.selected ? this.selected - 0 : null,
      },

      message: '',
      hasError: false,
      // i.e. options for select
      extraFields: {},

      submitted: false,
      dataFields: [],

      redemptionContext: 0,
    };
  },

  computed: {
    fields() {
      let list = this.dataFields?.length ? cloneDeep(this.dataFields[this.step]) : [];

      if (list.length) {
        list = list.map((field) => {
          if (this.extraFields[field.id]) {
            Object.keys(this.extraFields[field.id]).forEach((key) => {
              field[key] = this.extraFields[field.id][key];
            });
          }
          return field;
        });
      }

      return list;
    },

    headers() {
      return {
        'X-CSRF-Token': document.querySelector("meta[name='csrf-token']") && document.querySelector("meta[name='csrf-token']").content,
        'authenticity_token': document.querySelector("input[name='authenticity_token']") && document.querySelector("input[name='authenticity_token']").value,
      }
    },

    multipleRedemptions() {
      return this.model?.entities?.length > 1;
    }
  },

  mounted() {
    this.fetchFields();
  },

  methods: {
    goTo(step, clearFields) {
      this.step = step;

      this.submitted = false;
      this.hasError = false;
      this.message = '';

      if (clearFields) {
        this.clearFields()
      }
    },

    clearFields() {
      this.submitted = false;
      const ignoredKeys = ['promotion_id'];
      const arrays = ['entities'];

      Object.keys(this.model).forEach(key => {
        if (!ignoredKeys.includes(key)) {
          this.model[key] = arrays.includes(key) ? [] : undefined;
        }
      });
    },

    prefillSubmitTicketForm(context) {
      const keys = ['email', 'customer_email', 'message', 'receipt'];
      this.redemptionContext = context;

      keys.forEach(key => {
        this.model[key] = undefined;
      });
    },

    handleClose() {
      this.message = null;
      this.hasError = false;
    },

    changePromotion(value) {
      this.model.promotion_id = value;
      this.fetchModelNumbers();
    },

    onError({error, message}) {
      this.hasError = true;
      this.message = message || (error.data && error.data.message) || "Something went wrong. Please try again";

      this.hideNotification();
    },

    onSuccess(message) {
      this.message = message || "Success";
      this.hideNotification();
    },

    hideNotification(timeout) {
      setTimeout(() => {
        this.handleClose();
      }, timeout || 5 * 1000);
    },

    fetchFields() {
      this.loading = true;

      this.$http.get('/data', {
        headers: this.headers,
      }).then(response => {
        this.dataFields = response.data;
      }, (error) => {
        this.onError({error});
      }).finally(() => {
        this.loading = false;
      });
    },

    fetchModelNumbers() {
      const { promotion_id } = this.model;
      this.$http.get('/find_model_numbers',{
        headers: this.headers,
        params: { promotion_id },
      }).then(response => {
        const { model_numbers } = response.data;
        this.extraFields = {
          ...this.extraFields,
          model_number: {
            options: model_numbers.sort((a, b) => (a < b ? -1 : 1)),
          }
        }
      }, (error) => {
        this.onError({error});
      });
    },

    resendEmail(idx) {
      this.sending = true;
      this.hasError = false;
      this.submitted = false;

      const params = {
        redemption_id: this.model.entities[idx].redemption_id,
        email: this.model.entities[idx].extra_email,
        model_number: this.model.entities[idx].model_number,
      };

      this.$http.post('/resend_bonus_email',
          params,
          {
            headers: this.headers
          })
          .then(() => {
            this.submitted = true;
            this.onSuccess('Email resent successfully');

          }, (error) => {
            this.onError({error});
          }).finally(() => {
            this.sending = false;
          });
    },

    submitTicket() {
      this.sending = true;
      this.hasError = false;
      this.submitted = false;

      let data = new FormData();

      const keys = this.fields.map(item => item.id);
      keys.push('redemption_id');

      const entity = this.model.entities && this.model.entities[this.redemptionContext]

      keys.forEach((key) => {
        const value = this.model[key] || (entity && entity[key]);

        if (value) {
          data.append(key, value)
        }
      });

      this.$http({
        method: 'post',
        url: '/submit_ticket',
        data,
        headers: {
          ...this.headers,
          "Content-Type": "multipart/form-data",
        }
      }).then(() => {
          this.submitted = true;
          let message = 'The ticket has been submitted successfully';
          this.onSuccess(message);
        }, (error) => {
          this.onError({error});
        }).finally(() => {
          this.sending = false;
        });
    },

    searchRedemption() {
      this.sending = true;
      this.hasError = false;
      this.submitted = false;

      const params = pick(this.model, ['email', 'serial_number', 'promotion_id', 'model_number']);

      this.$http.get('/find_redemption', {
        headers: this.headers,
        params,
      }).then(response => {
        this.submitted = true;
        this.model = {
          ...this.model,
          entities: response.data.map((one) => {
            const {
              redemption, email_history, project, merchant, promotion_description, orders_history
            } = one;

            const emailHistory = email_history.sort((a, b) => new Date(a.sent_at).getTime() - new Date(b.sent_at).getTime());
            const ordersHistory = orders_history.sort((a, b) => new Date(a.updated_at).getTime() - new Date(b.updated_at).getTime());

            return {
              email_history: emailHistory,
              orders_history: ordersHistory,
              bonus_amount: redemption.bonus_amount,
              created_at: redemption.created_at,
              model_number: redemption.custom_fields && redemption.custom_fields.model_number,
              costco_member_number: redemption.custom_fields && redemption.custom_fields.costco_member_number,
              redemption_id: redemption.id,
              extra_email: emailHistory.length ? emailHistory[emailHistory.length - 1].to : redemption.email,
              project,
              merchant,
              description: promotion_description,
            }
          }),
        };

        // move to next step
        this.goTo(1);

      }, (error) => {
        const { status } = error.response;
        let message;

        if (status === 404) {
          message = 'Redemption not found. You can submit support ticket' ;
          this.submitted = true;
        }

        this.onError({error, message});
      }).finally(() => {
        this.sending = false;
      });
    },
  }
}

</script>
