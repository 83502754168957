<template>
  <div v-change-outside.jquery="{ name: 'changedProductType', handler: changedProductType }">
    <div v-change-outside.jquery="{ name: 'changedBranch', handler: changedBranch }">
      <template v-if="presale">
        <div class="row">
          <div class= "col-sm-12">
            <input type="checkbox" id="checkbox_a" v-model="fieldsNormalized.approved_for_release">
            <input type="hidden"
                  :value="fieldsNormalized.approved_for_release"
                  name="product[approved_for_release]"
                  id="approved_for_release"/>
            <label for="checkbox_a" class="no-select mr-2">Approved for release</label>
          </div>

          <div class="col-sm-6">
            <div class="form-group">
              <label :for="'street_date'">
                  Street date
              </label>

              <input type="hidden"
                    :value="fieldsNormalized.street_date"
                    name="product[street_date]"
                    id="street_date"/>

              <datepicker :value="fieldsNormalized.street_date && fieldsNormalized.street_date.replace(/T.*$/,'')"
                          @input="(value) => changeDate(value, 'street_date')"
                          format="dd/MM/yyyy"
                          :bootstrap-styling="true"
                          :clear-button="true"
                          :disabled-dates="disabledDates['street_date']"
                          placeholder="Choose Date"
                          input-class="form-control date-picker-input"/>

              <span v-if="errors['street_date']" class="text-danger small">{{errors['street_date']}}</span>
            </div>

            <div class="form-group">
              <label class="mb-1 col-form-label" for="street_timezone">Street timezone</label>

              <select name="product[street_timezone]"
                      id="street_timezone"
                      class="custom-select"
                      @change.prevent="(event) => changeTimezone(event.target.value, 'street_timezone')"
                      v-model="fieldsNormalized.street_timezone">
                <option v-for="item in timezoneList"
                        :selected="item.value === fieldsNormalized.street_timezone"
                        :key="item.value"
                        :value="item.value"
                        v-text="item.label"/>

              </select>
              <span v-if="errors['street_timezone']" class="text-danger small">{{errors['street_timezone']}}</span>
            </div>
          </div>

          <div class="col-sm-6">
            <div class="form-group">
              <label for="end_date">
                End date
              </label>

              <input type="hidden"
                    :value="fieldsNormalized.end_date"
                    name="product[end_date]"
                    id="end_date"/>

              <datepicker :value="fieldsNormalized.end_date && fieldsNormalized.end_date.replace(/T.*$/,'')"
                          @input="(value) => changeDate(value, 'end_date')"
                          format="dd/MM/yyyy"
                          :bootstrap-styling="true"
                          :clear-button="true"
                          :disabled-dates="disabledDates['end_date']"
                          placeholder="Choose Date"
                          input-class="form-control date-picker-input"/>

              <span v-if="errors['end_date']" class="text-danger small">{{errors['end_date']}}</span>
            </div>
            <div class="form-group">
              <label class="mb-1 col-form-label" for="end_timezone">End timezone</label>

              <select name="product[end_timezone]"
                      id="end_timezone"
                      class="custom-select"
                      @change.prevent="(event) => changeTimezone(event.target.value, 'end_timezone')"
                      v-model="fieldsNormalized.end_timezone">
                <option v-for="item in timezoneList"
                        :selected="item.value === fieldsNormalized.end_timezone"
                        :key="item.value"
                        :value="item.value"
                        v-text="item.label"/>
              </select>
              <span v-if="errors['end_timezone']" class="text-danger small">{{errors['end_timezone']}}</span>
            </div>
          </div>

        </div>
      </template>
    </div>
  </div>
</template>

<script>
  import Datepicker from 'vuejs-datepicker';
  import getTimezoneList from '../../../utils/timezoneSelectOptions';

  export default {

    props: {
      fields: {
        required: true,
        type: String,
      },
      displayMessage: {
        required: false,
        type: [String, Object],
      },
      presale: {
        required: false,
        type: Boolean,
        default: false,
      },
    },

    components: {
      Datepicker
    },

    data() {
      return {
        defaultTZ: 'Etc/UTC',
        fieldsNormalized: JSON.parse(this.fields),
        timezoneList: getTimezoneList(),
        errors: JSON.parse(this.displayMessage),
        minDate: new Date(this.$moment().add(-1, 'y').startOf('day').format()),
      }
    },

    mounted() {
      this.updateDefaultTZ();

      this.changedProductType();
      this.initDateTimes();
      this.initTimezones();
    },

    computed: {
      timezones() {
        return Object.keys(this.fieldsNormalized).filter(key => key.includes('timezone'));
      },

      dateTimes() {
        return Object.keys(this.fieldsNormalized).filter(key => key.includes('date'));
      },

      disabledDates() {
        return {
          street_date: {
            to: this.minDate,
          },
          end_date: {
            to: this.minDate
          }
        }
      },
    },

    methods: {
      updateDefaultTZ(refreshFields) {
        const $tz = $('#product_branch_id option:selected');
        if ($tz && $tz.data('timezone')) {
          const tz = $tz.data('timezone');

          this.defaultTZ = tz;

          if (refreshFields) {
            this.timezones.forEach(key => {
              this.fieldsNormalized[key] = tz;
              this.updateDateTimeWithTimezone(tz, key);
            });
          }
        }
      },

      changedProductType (e, el, extras) {
        this.presale = $('#product_type').val() === 'PresaleProduct'
      },

      initTimezones() {
        this.timezones.forEach(key => {
          let value = this.fieldsNormalized[key];
          if (!value) {
            this.fieldsNormalized[key] = this.defaultTZ;
          }
        });

        const fields = this.timezones.map(key => this.fieldsNormalized[key]);

        this.oneTz = fields.every(item => item === fields[0]);
      },

      initDateTimes() {
        this.dateTimes.forEach(key => {
          let value = this.fieldsNormalized[key];
          if (value) {
            this.changeDate(value, key, true);
          }
        });
      },

      changedBranch (e) {
        const targetId = 'product_branch_id';

        if (e.target.id === targetId) {
          this.updateDefaultTZ(true)
        }
      },

      changeDate(value, fieldName, useFullTime) {
        const key = fieldName.replace(/date$/, 'timezone');
        const tz = this.fieldsNormalized[key];
        let date = this.$moment(value).format();

        if (!useFullTime) {
          date = date.replace(/T.*$/,'');
        }

        if (this.fieldsNormalized.hasOwnProperty(fieldName)) {
          if (value) {
            this.fieldsNormalized[fieldName] = this.$moment.tz(date, tz).format()
          } else {
            this.fieldsNormalized[fieldName] = null;
            this.fieldsNormalized[key] = this.defaultTZ;
          }
        }
      },

      changeTimezone(value, fieldName) {
        this.fieldsNormalized[fieldName] = value;
        this.updateDateTimeWithTimezone(value, fieldName);
      },

      updateDateTimeWithTimezone(value, fieldName) {
        const key = fieldName.replace(/timezone$/, 'date');
        if (this.fieldsNormalized[key] !== null) {
          const date = this.fieldsNormalized[key].replace(/T.*$/,'');

          this.fieldsNormalized[key] = this.$moment.tz(date, value).format();
        }
      },
    }
  }
</script>
