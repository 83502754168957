<template>
  <div class="alert alert-dismissible fade show" :class="alertStyle" role="alert">
    <button type="button" class="close" data-dismiss="alert" aria-label="Close" @click="$emit('close')">
      <span aria-hidden="true">&times;</span>
    </button>
    <strong v-if="failureText.length > 0">{{failureText}}</strong>
    <strong v-if="successText.length > 0">{{successText}}</strong>
  </div>
</template>

<script>
export default {
  name: "notification",
  props: ["successText", 'failureText'],
  computed: {
    alertStyle: function(){
      return {
        'alert-danger': this.failureText.length > 0,
        'alert-success': this.successText.length > 0
      }
    }
  }
};
</script>
