<template>
  <div class="modal show">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header d-flex justify-content-between">
          <h4 class="mb-0">Save Preset</h4>
          <button type="button" class="close" data-dismiss="alert" aria-label="Close" @click="hideModal">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <div class="row">
            <div :class="saveToProject ? 'col' : 'col-md-6'">
              <h4>Project</h4>
              <v-select :options="projects" v-model="selectedProject"></v-select>
            </div>
            <div class="col-md-6" v-if="!saveToProject">
              <h4>Promotion</h4>
              <v-select :options="promotions" v-model="selectedPromotion"></v-select>
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <button class="btn btn-success" @click="savePreset" :disabled="disabledSave">Save</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "saveModal",
  props: ["saveToProject", 'promos', 'currentProject', 'currentPromotion'],
  data: function() {
    return {
      selectedProject: this.currentProject,
      selectedPromotion: this.currentPromotion && this.currentPromotion !== null ? this.currentPromotion : '',
      promotionsData: []
    };
  },
  mounted: function(){
    this.promotionsData = this.promos;
  },
  computed: {
    projects() {
      return Object.keys(this.promotionsData)
    },
    promotions() {
      const list = this.promotionsData[this.selectedProject];
      return list && list.length ? list.filter(item => !!item) : [];
    },
    disabledSave() {
      return !(this.saveToProject && this.selectedProject.length > 0 || !this.saveToProject && this.selectedPromotion.length > 0)
    }
  },
  methods:{
    hideModal(){
      this.$emit('hideModal')
    },
    savePreset(){
      this.$emit('savePreset', [this.selectedProject, this.saveToProject ? '' : this.selectedPromotion])
    }
  }
};
</script>

<style scoped>
.modal-dialog{
  max-width: 1000px;
}
.modal.show {
  background-color: rgba(0, 0, 0, 0.8);
}
</style>
