<template>
  <selector-base
    label="Partners"
    field-name="user[partner_ids]"
    empty-text="No Partners in the project(s)"
    options-endpoint="/find_partners.json"
    visible-for-role="vendor_support"
    :values="partners"
    :validation-error="error"
  ></selector-base>
</template>

<script>
import SelectorBase from './SelectorBase';

export default {
  props: {
    partners: {
      required: false,
      type: Array,
      default: () => ([]),
    },
    error: {
      required: false,
      type: String,
    },
  },

  components: {
    'selector-base': SelectorBase,
  },
}
</script>
