<template>
  <div id="stepsList" class="col p-0 step-container">
      <div v-if="!readOnly" class="col p-0 add-step">
          <button class="btn btn-outline-success add-step-btn" @click.prevent="addStep">Add Step</button>
          <button class="btn btn-info save-flow-btn" @click.prevent="saveFlow">Save Flow</button>
          <div id="notificationContainer" class="alert"></div>
      </div>
      <div class="col p-0" id="stepsContainer">
          <step v-for="step in steps"
                :key="step"
                :stepInfo="stepsFlow[step-1]"
                @removeStep="removeStep"
                :title="readOnly ? '' :'Drag step to the change order'"
                :enableLegacyBlocks="enableLegacyBlocks"
                :blocksData="blocksData"
                :readOnly="readOnly"
                :showLinkToBonusCodes="showLinkToBonusCodes"
                :enableBonusCodesLink="enableBonusCodesLink"
                :projectName="projectName"></step>

      </div>
  </div>
</template>

<script>
import step from './step'

export default {
  name: 'stepslist',
  components: {step},
  props: ['stepsCounter'],
    data: function() {
        return {
            stepCounter: '',
            stepsFlow: [],
            steps: 0,
            blocksData: [],
            enableLegacyBlocks: false,
            readOnly: true,
            showLinkToBonusCodes: false
        }
    },
    computed:{
        legacyBlocksList: function(){
          let legacyBlocks = [];
          this.blocksData.forEach((block) => { if (block.hasOwnProperty('disabled')){
            legacyBlocks.push(block.blockClass);
          }});
          return legacyBlocks;
        }
    },
    created: function() {
        const blocksDataUrl = location.pathname.replace('rules', 'blocks_data');
        $.ajax(blocksDataUrl).done((response) => {
            this.blocksData = response.blocksData;
            this.getStepsFlow();
        });
    },
    methods: {
        getStepsFlow: function(){
          const stepsUrl = location.pathname.replace('rules', 'steps');
          $.ajax(stepsUrl).done((response) => {
              this.stepsFlow = response.steps;
              this.steps = this.stepsFlow.length;
              this.readOnly = response.readOnly;
              this.showLinkToBonusCodes = response.showLinkToBonusCodes;
              this.enableBonusCodesLink = response.enableBonusCodesLink;
              this.projectName = response.projectName;
              this.checkLegacyBlocks(true);
              });
            this.steps = this.stepsFlow.length;
        },
        checkLegacyBlocks: function(created){
          let currentBlockNames = [];
          created ? currentBlockNames = this.stepsFlow.map(step => { return step.blockClass }) : currentBlockNames = this.$children.map(step => { return step.blockClass });
          this.enableLegacyBlocks = currentBlockNames.some( blockName => this.legacyBlocksList.includes(blockName));
          if(!this.enableLegacyBlocks){
            this.blocksData = this.blocksData = this.blocksData.filter(block => { return !block.hasOwnProperty('disabled')})
          }
        },
        removeStep: function(stepIndex) {
            this.stepsFlow.splice(stepIndex, 1);
        },
        addStep: function() {
            this.steps += 1;
        },
        saveFlow: function() {
            const stepsUrl = location.pathname.replace('rules', 'steps');
            let steps = [];
            let stepsList = Array.from($('#stepsContainer')[0].children);

            for (let i = 0; i < this.$children.length; i++) {
              let stepComponent = this.$children[i].$children[0];
                if (stepComponent === undefined) continue;
                const el = stepComponent.$parent.$el;
                steps[stepsList.indexOf(el)] = this.$children[i].$children[0].step;
            }

            this.checkLegacyBlocks();

            let $form = $('<form>', {
              action: stepsUrl,
              method: 'post'
            });
            let formData = {
              authenticity_token: $('meta[name="csrf-token"]').attr('content'),
              steps: JSON.stringify(steps)
            }

            $.each(formData, (name, value) => {
              $('<input>').attr({
                type: "hidden",
                name:  name,
                value: value
              }).appendTo($form);
            });

            $form.appendTo('body').submit();

            // $.ajax({
            //     url: stepsUrl,
            //     method: 'POST',
            //     headers: {'X-CSRF-Token': $('meta[name="csrf-token"]').attr('content')},
            //     data: {
            //         steps: steps
            //     },
            //     success: () => {
            //         $("#notificationContainer")
            //             .text('Steps have been saved successfully!')
            //             .removeClass('alert-danger')
            //             .addClass('alert-success')
            //             .fadeTo(2000, 500)
            //             .slideUp(500, function() {
            //                 $("#notificationContainer").slideUp(500);
            //             });
            //         this.checkLegacyBlocks();
            //         $('.stepselector, .stepselector.dropup').selectpicker('refresh');
            //     },
            //     error: function() {
            //         $("#notificationContainer")
            //             .text('Error occured while saving steps!')
            //             .removeClass('alert-success')
            //             .addClass('alert-danger')
            //             .fadeTo(2000, 500).slideUp(500, function() {
            //                 $("#notificationContainer").slideUp(500);
            //             });
            //     }
            // })
        }
    }
}
</script>
