<template>
  <div class="cardIsValidated col-lg-7" id="cardValidator">
      <div class="row field-row mb-2 align-items-center">
        <div class="col-lg-4">
          <div class="field-title">Field Validated:</div>
        </div>
        <div class="col-lg-8">
          <select class=" fieldVal" data-width="100%" @change="setFieldValidated">
            <option v-for="fieldOption in initialStepData.stepOptions.fieldValidated"
                    :value="fieldOption">
              {{fieldTitle(fieldOption)}}
            </option>
          </select>
        </div>
      </div>
      <div class="row field-row mb-2 align-items-center">
        <div class="col-lg-4">
          <div class="field-title">Validation Service:</div>
        </div>
        <div class="col-lg-8">
          <select class=" valService" data-width="100%" @change="setValService">
            <option v-for="fieldOption in initialStepData.stepOptions.validationService" :value="fieldOption">{{fieldOption}}</option>
          </select>
        </div>
      </div>
  </div>
</template>

<script>
  import { getLabel } from './fieldsDescription';

  export default {
    name: 'cardValidator',
    props: ['stepsObj', 'stepOptions', 'blockClass'],
    data: function() {
      return {
        stepName: 'Card Is Validated',
        newBlockClass: '',
        blockClass: this.newBlockClass,
        newOptions: {
          fieldValidated: '',
          validationService: 'GCA'
        },
        validatorsMap: {}
      }
    },
    computed: {
      fieldTitle: () => (value) => {
        return getLabel(value);
      },

      initialStepData: function(){
        return this.stepsObj.filter(step => { return step.blockClass == 'Block::LocalValidator' })[0];
      },
      step: function() {
        if (this.newBlockClass.length > 0) {
          this.newBlockClass = Object.entries(this.validatorsMap).filter(validator =>{ return Object.values(validator)[1] == this.newOptions.validationService })[0][0];
          this.blockClass = this.newBlockClass;
          return {
            blockClass: this.newBlockClass,
            options: {
              fieldValidated: this.newOptions.fieldValidated,
              validationService: this.newOptions.validationService
            }
          }
        }
      }
    },
    mounted: function() {
      this.newBlockClass = this.blockClass;
      if (Object.keys(this.stepOptions).length > 0) {
        this.newOptions = this.stepOptions;
      }

      this.validatorsMap = this.initialStepData.stepOptions.validationService;

      $(this.$el).find('select.fieldVal').selectpicker('val', this.newOptions.fieldValidated);
      $(this.$el).find('select.valService').selectpicker('val', this.newOptions.validationService);
    },
    methods: {
      setFieldValidated: function(event) {
        this.newOptions.fieldValidated = event.target.selectedOptions[0].value;
      },
      setValService: function(event) {
        this.newOptions.validationService = event.target.selectedOptions[0].value;
        this.newOptions.blockClass = this.step.blockClass;
      }
    }
  }
</script>
