<template>
<div class="col-lg-7" id="userInput">
        <div class="descContainer" v-if="stepName === 'User Inputs Information'">
            <div class="row field-row mb-2 align-items-center">
                <div class="col-lg-4">
                    <div class="field-title">Main Fields</div>
                </div>
                <div class="col-lg-8">
                    <select multiple data-width="100%" @change="setMain" class="mainf">
                        <option v-for="fieldOption in initialStepData.stepOptions.mainFields">{{fieldOption}}</option>
                    </select>
                </div>
            </div>
            <div class="row field-row mb-2 align-items-center">
                <div class="col-lg-4">
                    <div class="field-title">Additional Fields</div>
                </div>
                <div class="col-lg-8">
                    <select multiple data-width="100%" @change="setAdditional" class="addf">
                        <option v-for="fieldOption in initialStepData.stepOptions.additionalFields">{{fieldOption}}</option>
                    </select>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
  export default{
    name: 'userInput',
    props: ['stepsObj', 'stepOptions', 'blockClass'],
    data: function() {
        return {
            stepName: 'User Inputs Information',
            newOptions: {
                mainFields: [],
                additionalFields: []
            }
        }
    },
    mounted: function() {
        if (Object.keys(this.stepOptions).length > 0) {
            this.newOptions = this.stepOptions;
        }

        $(this.$el).find('select.mainf').selectpicker();
        $(this.$el).find('select.addf').selectpicker();
        $(this.$el).find('select.addf').selectpicker('val', this.newOptions.additionalFields);
        $(this.$el).find('select.mainf').selectpicker('val', this.newOptions.mainFields);
    },
    computed: {
        additionalFields: function() {
            return this.newOptions.additionalFields
        },
        initialStepData: function(){
          return this.stepsObj.filter(step => { return step.blockClass == this.blockClass })[0];
        },
        step: function() {
            if (this.blockClass.length > 0) {
                return {
                    blockClass: this.blockClass,
                    options: {
                        additionalFields: this.newOptions.additionalFields,
                        mainFields: this.newOptions.mainFields
                    }
                }
            }
        }
    },
    methods: {
        setMain: function(event) {
            this.newOptions.mainFields = [];
            for (var i = 0; i < event.target.selectedOptions.length; i++) {
                this.newOptions.mainFields.push(event.target.selectedOptions[i].value);
            }
        },
        setAdditional: function(event) {
            this.newOptions.additionalFields = [];
            for (var i = 0; i < event.target.selectedOptions.length; i++) {
                this.newOptions.additionalFields.push(event.target.selectedOptions[i].value);
            }
        }
    }
  }
</script>
