<template>
  <div>
    <template v-if="title">
      <label class="col-form-label check_boxes optional" v-text="title"/>
    </template>
    <template v-if="options && options.length">
      <input type="hidden" :name="`${fieldName}[]`" value="" />
      <multiselect
          v-model="model"
          :multiple="true"
          :close-on-select="closeOnSelect"
          :label="label"
          :track-by="trackBy"
          :options="options">
      </multiselect>

      <template v-for="m in model">
        <input type="hidden" :name="`${fieldName}[]`" :key="`${prefix}__${m.id}`" :value="m[trackBy]" />
      </template>
    </template>
  </div>
</template>

<script>
import Multiselect from 'vue-multiselect'

export default {
  props: {
    title: {
      required: false,
      type: String,
    },
    label: {
      required: false,
      type: String,
      default: 'name',
    },
    trackBy: {
      required: false,
      type: String,
      default: 'id',
    },
    options: {
      required: true,
      type: Array, // of objects
    },
    value: {
      required: true,
      type: Array // of keys(props.trackBy)
    },
    fieldName: {
      required: true,
      type: String,
    },
    closeOnSelect: {
      required: false,
      type: Boolean,
    }
  },

  components: {
    Multiselect,
  },

  data() {
    return {
      model: [],
      prefix: Math.random().toString(32).substring(2),
    };
  },

  mounted() {
    this.fillModel();
  },

  methods: {
    fillModel() {
      this.prefix = Math.random().toString(32).substring(2);

      const idx = this.value.map((model) => model?.id || model).filter(model => !!model);
      this.model = this.options.filter((item) => idx.includes(item.id));
    },
  },

  watch: {
    options() {
      this.fillModel();
    },
    value() {
      this.fillModel();
    },
  }
}
</script>
