<template>
  <div class="col-md-11">
    <notification
        v-if="successText.length > 0 || failureText.length > 0"
        :successText="successText"
        :failureText="failureText">
    </notification>
    <save-modal
        v-if="activateModal"
        :currentProject="currentProject"
        :currentPromotion="currentPromotion"
        :saveToProject="saveToProject"
        :promos="promoData"
        @savePreset="savePreset"
        @hideModal="activateModal = false">
    </save-modal>
    <presets-selector :presets="presets" @setCurrentPresetInfo="setCurrentPresetInfo" @resetStyles="resetStyles"
                      :initialPreset="initialPreset"></presets-selector>
    <div class="row col" v-if="presets && styleSettings !== null">
      <editor-form
          :styleSettings="styleSettings"
          :currentPromotion="currentPromotion"
          @stylesChanged="stylesChanged"
          @showSaveModal="showSaveModal"
          @undoChanges="undoChanges">
      </editor-form>
      <preview-window :styleSettings="styleSettings" ref="preview"></preview-window>
    </div>
  </div>
</template>

<script>
  import axios from "axios";

  import presetsSelector from "./presetsSelector";
  import editorForm from "./editorForm";
  import previewWindow from "./previewWindow";
  import saveModal from "./saveModal";
  import notification from '../../notification'

  export default {
    name: "styleEditor",
    components: {
      "presets-selector": presetsSelector,
      "editor-form": editorForm,
      "preview-window": previewWindow,
      "save-modal": saveModal,
      notification
    },
    data() {
      return {
        DEFAULT_PLACEHOLDER_COLOR: '#A9A9A9',
        presets: [],
        initialPresets: [],
        currentProject: 'BonusCardRewards',
        currentPromotion: null,
        initialPreset: 'BonusCardRewards',
        saveToProject: false,
        activateModal: false,
        promoData: {},
        successText: '',
        failureText: '',
        styleSettings: null
      };
    },
    created() {
      this.getPresets();
    },
    computed: {
      XCSRFToken: () => document.querySelector('meta[name="csrf-token"]').getAttribute("content"),
      presetData() {
        return {
          stylesheet: {
            projectName: this.selectedProject,
            promotionSlug: '',
            styleSettings: this.styleSettings
          }
        }
      }
    },
    watch: {
      activateModal() {
        if (!this.activateModal) {
          this.saveToProject = false
        }
      },

      presets() {
        this.updateStyleSettings()
      },
      currentProject() {
        this.updateStyleSettings()
      },
      currentPromotion() {
        this.updateStyleSettings()
      }
    },
    methods: {
      getPresets() {
        axios({
          method: "get",
          url: window.location.href.split('?')[0] + ".json",
          headers: {"X-CSRF-Token": this.XCSRFToken}
        })
          .then(response => {
            this.presets = response.data;
            this.updateStyleSettings();
            this.initialPresets = Object.freeze(JSON.parse(JSON.stringify(response.data)));
            axios({
              method: "get",
              url: '/admin/projects/promotions.json',
              headers: {
                "X-CSRF-Token": this.XCSRFToken
              }
            })
              .then(response => this.promoData = response.data)
          })
          .catch(error => console.error(error));
      },
      setCurrentPresetInfo(presetInfo) {
        this.currentProject = presetInfo.currentProject;
        this.currentPromotion = presetInfo.currentPromotion;
      },
      stylesChanged(fontType) {
        if (fontType && fontType.length > 0) {
          this.styleSettings["text"]["font"] = fontType
        }
      },
      updateStyleSettings() {
        this.styleSettings = this.presets.find(preset => preset.projectName === this.currentProject && preset.promotionSlug === this.currentPromotion).styleSettings;
        if (this.styleSettings && this.styleSettings.input) {
            this.styleSettings.input = {
              ...this.styleSettings.input,
              placeholderColor: this.styleSettings.input.placeholderColor || this.DEFAULT_PLACEHOLDER_COLOR
            };
        }
      },
      undoChanges() {
        this.presets = JSON.parse(JSON.stringify(this.initialPresets))
      },
      resetStyles() {
        axios({
          method: "delete",
          url: "/admin/stylesheets.json",
          data: {promotionSlug: this.currentPromotion},
          headers: {"X-CSRF-Token": this.XCSRFToken}
        })
          .then(() => {
            this.fireNotification(true, 'Preset was deleted successfully!');
            this.currentPromotion = '';
            this.getPresets()
          })
          .catch(() => this.fireNotification(false, 'Failed to delete preset!'));
      },
      showSaveModal(saveToProject) {
        this.saveToProject = saveToProject;
        this.activateModal = true;
      },
      fireNotification(isSuccessful, notificationText) {
        if (isSuccessful) {
          this.successText = notificationText;
          setTimeout(() => {
            this.successText = ''
          }, 5000);
        } else {
          this.failureText = notificationText;
          setTimeout(() => {
            this.failureText = ''
          }, 5000);
        }
      },
      savePreset(selectedProperties) {
        this.presetData.stylesheet.projectName = selectedProperties[0];
        this.presetData.stylesheet.promotionSlug = selectedProperties[1];
        this.initialPreset = this.presetData.stylesheet.promotionSlug == null || this.presetData.stylesheet.promotionSlug == '' ? this.presetData.stylesheet.projectName : `${this.presetData.stylesheet.promotionSlug}, project: ${this.presetData.stylesheet.projectName}`
        this.activateModal = false;

        axios({
          method: "patch",
          url: "/admin/stylesheets.json",
          data: this.presetData,
          headers: {"X-CSRF-Token": this.XCSRFToken}
        })
          .then(() => {
              this.getPresets();
              this.fireNotification(true, 'Styles have been successfully saved!')
            }
          )
          .catch(() => {
            this.fireNotification(false, 'Failed to save preset!')
          });
      }
    }
  };
</script>

<style scoped>
  .col-md-11 {
    transition: all 0.5s ease;
  }
</style>
