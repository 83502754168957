<template>
  <div class="modal-footer">
    <button class="btn btn-secondary" @click="hideModal">Cancel</button>
    <button class="btn btn-success" @click="acceptChanges" :disabled="!changesAllowed">Save</button>
  </div>
</template>

<script>
export default {
  name: "modalFooter",
  props: {
    changesAllowed: { type: String, required: false, default: true }
  },
  methods: {
    hideModal: function() {
      this.$emit("hideModal");
    },
    acceptChanges: function() {
      this.$emit("acceptChanges");
    }
  }
};
</script>

<style scoped>
.btn-success:disabled {
  opacity: 0.3;
  cursor: not-allowed;
}
</style>
