<template>
  <div class="data-table">
    <h2 v-if="title">{{ title }}</h2>

    <notification
      v-if="message"
      :successText="!hasError ? message : ''"
      :failureText="hasError ? message : ''"
    >
    </notification>

    <div class="action-wrapper">
      <div class="d-flex mb-3 justify-content-between">
        <div class="d-flex justify-content-start align-items-center">

          <statusCounter v-for="(value, key) in statuses"
                         :status="key"
                         :key="key"
                         :selectedStatus="selectedStatus"
                         @statusFilter="onFilterStatus"
                         :promosAmount="value"/>
        </div>

        <div>
          <div>
            <label for="external_only" class="my-0">
              <input
                  ref="externalOnlyRef"
                  v-model="externalOnly"
                  type="checkbox"
                  name="external_only"
                  id="external_only">
              Show <b>external promotions</b> only
            </label>
          </div>
          <div>
            <i class="dtr-promo-preview">Italicized promotions are in the preview mode</i>
          </div>
        </div>
        <!--div class="d-flex align-items-center">
          <label for="displayMode">Display items:</label>
          <select name="displayMode"
                  id="displayMode"
                  v-model="displayMode">
            <option value="">All</option>
            <option value="active">Active only</option>
            <option value="passive">Preview</option>
          </select>
        </div-->

      </div>
      <div class="row">
        <div class="col col-12">
          <ul class="data-table-columns">
            <li v-for="item in columnsList" :key="item">
              <input
                :value="item"
                :id="item"
                type="checkbox"
                v-model="visibleColumns"
                :disabled="disabledColumns.indexOf(item) !== -1 || isLoading"
                @change="applyColumnDefinition(true)"
              />
              <label :for="item">{{ columnsListNames[item] }}</label>
            </li>
          </ul>
        </div>
      </div>

    <div class="loader" v-if="isLoading"></div>
  </div>


    <div>
      <ag-grid-vue
          style="width: 100%; height: 76vh"
          class="ag-theme-balham"
          @grid-ready="onGridReady"
          :gridOptions="gridOptions"
          :columnDefs="columnDefs"
          :animateRows="true"
          :multiSortKey="'ctrl'"
          :rowClassRules="rowClassRules"
          :suppressDragLeaveHidesColumns="true"
          :enableCellTextSelection="true"
          :rowData="rowDataFiltered"
      />
    </div>
  </div>
</template>

<script>
import { AgGridVue } from "ag-grid-vue";
import axios from "axios";
import qs from "qs";
import { setTimeout } from "timers";
import notification from "../notification";
import {isEqual} from "lodash";
import statusCounter from '../admin/dashboard/statusCounter';
import {filterParams} from "../../utils/dataTable";

export default {
  name: "DataTableProducts",
  props: {
    title: { type: String, required: false },
    url: { type: String, required: true },
    projectId: { type: [String], required: true },
    deleted: { type: [String], required: true }
  },
  data() {
    return {
      hasError: false,
      isLoading: false,
      message: null,
      gridOptions: {},
      columnDefs: null,
      rowData: null,
      rowClassRules: null,
      divider: 3,
      gridCurrentState: {},
      statuses: {active: 0, claim: 0, support: 0, presale: 0},

      externalOnly: false,
      selectedStatus: null,
      displayMode: '',
      columnsList: [
        "description",
        "merchant_names",
        "branch_name",
        "slug",
        "success_failure",
        "promotion_period",
        "rules_count",
        "languages",
        "actions",
        "external_platform"
      ],
      columnsListNames: {
        branch_name: "Branch Name",
        merchant_names: "Merchant",
        description: "Description",
        slug: "Slug",
        success_failure: "Success/Failure",
        promotion_period: "Promotional period(UTC)",
        rules_count: "Rules",
        languages: "Languages",
        actions: "Actions",
        external_platform: "External Platform"
      },
      visibleColumns: [
        "description",
        "merchant_names",
        "branch_name",
        "slug",
        "success_failure",
        "promotion_period",
        "actions"
      ],
      disabledColumns: [
        "description",
        "merchant_names",
        "branch_name",
        "slug",
        "actions"
      ]
    };
  },

  components: {
    AgGridVue,
    notification,
    statusCounter
  },

  beforeMount() {
    this.gridCurrentState = {...this.gridState};

    this.gridOptions.defaultColDef = {
      resizable: true
    };

    this.rowClassRules = {
      "dtr-promo-active": (param) => (param.data?.status === 'active'),
      "dtr-promo-claim": (param) => (param.data?.status === 'claim'),
      "dtr-promo-support": (param) => (param.data?.status === 'support'),
      "dtr-promo-presale": (param) => (param.data?.status === 'presale'),

      "dtr-promo-external": (param) => (!!param.data?.external_platform),
      "dtr-promo-preview": (param) => (param.data?.preview)
    };

    this.applySavedState(['visibleColumns']);
  },

  mounted() {
    this.gridApi = this.gridOptions.api;
    this.subscribeEvents();
  },

  methods: {
    onFilterStatus(status) {
      if (status && (this.selectedStatus !== status)) {
        this.selectedStatus = status;
      } else {
        this.selectedStatus = null;
      }
    },

    updateStatusFilter() {
      const statuses = ['active', 'support', 'claim', 'presale'];
      const rows = this.rowData ? this.gridApi.rowModel.rowsToDisplay : [];

      statuses.forEach(status => {
        const byStatus = (rows && rows.length) ? rows.filter(row => row.data.status === status): [];
        this.statuses[status] = byStatus.length;
      });
    },

    subscribeEvents() {
      this.gridOptions.onGridReady = (e) => {
        this.externalOnly = this.$refs.externalOnlyRef.checked;
        this.columnApi = e.columnApi;
        this.gridApi = e.api;
      }

      this.gridOptions.onModelUpdated = () => {
        this.updateStatusFilter();
      };

      this.gridOptions.onFilterChanged = (grid) => {
        const filter = grid.api.getFilterModel();
        if (!isEqual(filter, this.gridCurrentState.filter)) {
          this.popSavedState({
            filter,
          });
        }
      };

      this.gridOptions.onSortChanged = (grid) => {
        const sort = grid.columnApi.getColumnState()
            .filter(item => !!item.sort)
            .map(item => ({colId: item.colId, sort: item.sort, sortIndex: item.sortIndex}));

        if (!isEqual(sort, this.gridCurrentState.sort)) {
          this.popSavedState({
            sort
          });
        }
      };

      this.gridOptions.onFirstDataRendered = () => {
        this.applySavedState(['filter', 'sort'], true);
      };

      this.gridOptions.onCellClicked = (cell)  => {
        const $parent = cell.event.target.closest('span');
        const {colId} = cell.column;

        switch (colId.replace(/_?\d+$/,'')) {
          case "partner_name":
          case "branch_name":
          case "merchant_names":
            if ($parent && $parent.classList.contains('cell-filter-clickable')) {
              this.applyFilters(colId, $parent.textContent);
            }
            break;

          case "actions":
            if ($parent && $parent.dataset && $parent.dataset["vueAction"]) {
              this.removeRow($parent.dataset);
            }
            break;

          default:
            break;
        }
      };

      window.addEventListener("resize", () => {
        let w = window.innerWidth;
        setTimeout(() => {
          if (window.innerWidth === w) {
            this.sizeToFit();
          }
        }, 300);
      });
    },

    applySavedState(props, initial) {
      props.forEach(prop => {
        const values = this[initial ? 'gridState' : 'gridCurrentState'][prop];
        if (values) {
          switch (prop) {
            case 'filter':
              this.gridApi.setFilterModel(this.gridCurrentState[prop]);
              this.gridApi.onFilterChanged();
              break;

            case 'sort':
              this.columnApi.applyColumnState({state: this.gridCurrentState[prop]});
              this.gridApi.onSortChanged();
              break;

            case 'visibleColumns':
              this.visibleColumns = this.gridCurrentState[prop];
              break;
          }
        }
      });
    },

    applyFilters(filterBy, text) {
      if (filterBy && text) {
        let filterInstance = this.gridApi.getFilterInstance(filterBy);

        filterInstance.setModel({
          type: "contains",
          filter: text
        });

        this.gridApi.onFilterChanged();
      }
    },

    popSavedState(state) {
      this.gridCurrentState = {
        ...this.gridCurrentState,
        ...state
      };
      location.hash = qs.stringify(this.gridCurrentState);
    },

    removeRow(dataset) {
      dataConfirmModal.confirm({
        backdrop: true,
        keyboard: false,
        show: true,
        title: dataset.title || "Delete",
        text: dataset.vueConfirm || "Are you sure you want to continue?",
        commit: "Yes",
        cancel: "Back",
        onConfirm: () => {
          axios({
            headers: {
              "X-CSRF-Token": $('meta[name="csrf-token"]').attr("content")
            },
            method: dataset.vueMethod || "delete",
            url: dataset.vueUrl
          }).then(
            () => {
              this.rowData = this.rowData.filter(
                item => item.id !== dataset.id - 0
              );

              this.updateCount();
              this.onSuccess("Promotion removed successfully.");
            },
            error => {
              this.onError(error);
            }
          );
        },
        onCancel: function() {
          return false;
        }
      });
    },

    applyTooltips() {
      $('[data-toggle="tooltip"]').tooltip();
    },

    applyPlaceholders() {
      $('input[ref="eFloatingFilterText"], input[ref="eInput"]').each(function() {
        $(this).attr("placeholder", "Filter...");
      });
    },

    clearFilterSelections() {
      const $list = $('input[ref="eFloatingFilterText"], input[ref="eInput"]');
      setTimeout(() => {
        // to make sure the after reset filters
        $list.each(function() {
          if (!$(this).val()) {
            $(this).removeAttr("disabled");
          }
        });
      }, 600);
    },

    sizeToFit() {
      if (this.visibleColumns.length <= this.columnsList.length / 2 + 3) {
        this.gridApi.sizeColumnsToFit();
      }
    },

    applyColumnDefinition() {
      this.columnDefs = this.columns;
      this.popSavedState({visibleColumns: this.visibleColumns});

      setTimeout(() => {
        this.applyTooltips();
        this.applyPlaceholders();
        this.sizeToFit();
      }, 1);
    },

    onGridReady() {
      let params = {};
      const isDeleted = Boolean(this.deleted - 0);
      if (isDeleted) {
        params.deleted = isDeleted;
      }

      this.isLoading = true;

      axios({
        method: "get",
        url: this.requestUrl,
        params
      }).then(
        response => {
          this.fh = response.data.filters;
          this.applyColumnDefinition();
          this.rowData = response.data.list;
        },
        error => {
          this.onError(error);
        }
      ).finally(() => {
        this.isLoading = false;
      });
    },

    actionCellRenderer(params) {
      const id = params.data.id;
      let res = "";

      params.value.forEach(action => {
        switch (action.toUpperCase()) {
          case "READ":
            res += `<a class="icon-action" data-toggle="tooltip" data-placement="top" data-title="Show" href="/admin/projects/${this.projectId}/promotions/${id}" data-original-title="" title=""><i class="fa fa-eye" aria-hidden="true"></i></a>`;
            break;
          case "UPDATE":
            res += `<a class="icon-action"  data-toggle="tooltip" data-placement="top" data-title="Edit" href="/admin/projects/${this.projectId}/promotions/${id}/edit" data-original-title="" title=""><i class="fa fa-pencil" aria-hidden="true"></i></a>`;
            break;
          case "DELETE":
            res += `<span class="icon-action" data-vue-confirm="Are you sure you want to remove selected item?" data-toggle="tooltip" data-placement="top" data-title="Delete" rel="nofollow" data-vue-action="delete" data-id="${id}" data-vue-url="/admin/projects/${this.projectId}/promotions/${id}.json" title=""><i class="fa fa-trash text-danger" aria-hidden="true"></i></span>`;
            break;
        }
      });
      return res;
    },

    promoPeriodCellRenderer(params) {
      if (params && params.value) {
        return params.value.map(dateTime => (this.$moment.utc(dateTime).format('ll'))).join('-');
      }
    },

    successFailureCellRenderer(params) {
      if (params && params.value) {
        return params.value.join('/');
      }
    },

    langCellRenderer(params) {
      if (params && params.value) {
        return params.value.map(item => {
          let res = '';
          if (item.default) {
            res = `<strong title="${item.description}">${item.name}</strong>`
          } else {
            res = `<span title="${item.description}">${item.name}</span>`
          }
          return res;
        }).join(', ');
      }
    },

    descriptionCellRenderer(params) {
      if (params && params.data) {
        return `${params.value || ''}`
      }
    },
    slugCellRenderer(params) {
      if (params && params.data) {
        const link =  params.data.preview_url && `<a data-toggle="tooltip" data-placement="top" data-title="Open promotion" target="_blank" href="${params.data.preview_url}" target="_blank"><i class="fa fa-external-link" aria-hidden="true"></i></a>`;
        return `${params.value} ${link || ''}`;
      }
    },

    merchantsCellRenderer(params) {
      return params && params.value.map(item => `<span class="cell-filter-clickable">${item}</span>`).join(", ");
    },


    onError(error) {
      this.hasError = true;
      this.message =
        (error.data && error.data.message) ||
        "Something went wrong. Please try again";

      this.hideNotification();
    },

    onSuccess(message) {
      this.message = message || "Success.";
      this.hideNotification();
    },

    hideNotification(timeout) {
      setTimeout(() => {
        this.message = null;
        this.hasError = false;
      }, timeout || 5000);
    },
    updateCount() {
      const $el = $(".btn-deleted > span");
      $el.text(($el.text() || 0) - 0 + 1);
    },

    getFilter(filterName, options) {
      const filter = this.fh.find(item => (item.key === filterName));

      return {
        filter: options?.filterType || "agTextColumnFilter",
        floatingFilter: true,
        filterParams: filterParams({
          type: filter.type,
          list: filter.value,
          suppressAndOrCondition: filter.unique || false,
        }),
      }
    }
  },

  computed: {
    requestUrl() {
      return `${window.location.origin}${this.url}`;
    },

    gridState() {
      return location.hash ? qs.parse(location.hash.substring(1)) : {}
    },

    columns() {
      let list = [];
      const optionsList = {
        branch_name: {
          headerName: this.columnsListNames["branch_name"],
          field: "branch_name",
          cellRenderer: (data) => (`<span class="cell-filter-clickable">${data && data.value}</span>`),
          width: 130,
          maxWidth: 130,
          sortable: true,
          ...this.getFilter('branch_name'),
        },
        merchant_names: {
          headerName: this.columnsListNames["merchant_names"],
          field: "merchant_names",
          cellClass: ['ag-cell-auto'],
          autoHeight: true,
          wrapText: true,
          cellRenderer: this.merchantsCellRenderer,
          ...this.getFilter('merchant_names'),
        },
        description: {
          headerName: this.columnsListNames["description"],
          minWidth: 180,
          field: "description",
          filter: "agTextColumnFilter",
          cellClass: ['ag-cell-auto'],
          autoHeight: true,
          wrapText: true,
          cellRenderer: this.descriptionCellRenderer,
          sortable: true,
          ...this.getFilter('description'),
        },
        actions: {
          headerName: this.columnsListNames["actions"],
          field: "actions",
          width: 107,
          minWidth: 107,
          maxWidth: 107,
          cellRenderer: this.actionCellRenderer,
          classClass: "actions",
          pinned: "right"
        },
        slug: {
          headerName: this.columnsListNames["slug"],
          field: "slug",
          width: 180,
          maxWidth: 250,
          cellRenderer: this.slugCellRenderer,
          sortable: true,
          ...this.getFilter("slug")
        },
        success_failure: {
          headerName: this.columnsListNames["success_failure"],
          field: "success_failure",
          cellClass: ['ag-cell-center'],
          maxWidth: 150,
          cellRenderer: this.successFailureCellRenderer
        },
        promotion_period: {
          headerName: this.columnsListNames["promotion_period"],
          field: "promotion_period",
          cellClass: ['ag-cell-center', 'ag-cell-auto'],
          autoHeight: true,
          wrapText: true,
          cellRenderer: this.promoPeriodCellRenderer,
        },
        rules_count: {
          headerName: this.columnsListNames["rules_count"],
          field: "rules_count",
          cellClass: ['ag-cell-center'],
          width: 100,
          maxWidth: 100,
          sortable: true
        },
        languages: {
          headerName: this.columnsListNames["languages"],
          field: "languages",
          cellClass: ['ag-cell-auto'],
          autoHeight: true,
          wrapText: true,
          width: 120,
          maxWidth: 120,
          cellRenderer: this.langCellRenderer
        },
        external_platform: {
          headerName: this.columnsListNames["external_platform"],
          field: "external_platform",
          cellClass: ['ag-cell-center', 'ag-cell-auto'],
          autoHeight: true,
          wrapText: true
        },

      };

      this.columnsList.forEach(item => {
        if (this.visibleColumns.indexOf(item) !== -1) {
          list.push(optionsList[item] || []);
        }
      });
      return list;
    },

    rowDataFiltered() {
      // by status
      let filtered = (this.selectedStatus && this.rowData) ? this.rowData.filter(row=> row.status === this.selectedStatus) : this.rowData;

      // by external platform
      if (filtered?.length && this.externalOnly) {
        filtered = filtered.filter(row => !!row.external_platform);
      }

      // const byMode = this.displayMode ? byStatus.filter(row => {
      //   if (this.displayMode === 'passive') {
      //     return row.preview
      //   }  else {
      //     return !row.preview;
      //   }
      // }) : byStatus;
      //
      return filtered;
    }
  }
};
</script>
