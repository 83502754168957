<template>
  <div v-change-outside.jquery="{ name: 'changeUserFields', handler: changeUserFields }">
    <div v-if="roleMatches" class="form-group check_boxes optional user_project_ids">
      <label class="col-form-label check_boxes optional">{{label}}</label>
      <template v-if="hintText">
        <small class="text-muted">{{hintText}}</small>
      </template>
      <template v-if="projects.length && branches.length">
        <div v-if="loading" class="font-italic">
          Please wait. Loading...
        </div>
        <template v-else-if="error">
          <span class="text-danger" v-text="error"/>
        </template>
        <div v-else-if="options.length">
          <v-multiselect
              :field-name="fieldName"
              :value="model"
              :options="options"/>
        </div>
        <template v-else>
          <span class="text-warning">{{emptyText}}</span>
        </template>
      </template>
      <template v-else>
        <span class="text-warning">Select project(s) and branch(es) before</span>
      </template>
      <template v-if="validationError">
        <small class="invalid-feedback">{{validationError}}</small>
      </template>
    </div>
    <template v-else>
      <input type="hidden" :name="`${fieldName}[]`" value="" />
    </template>
  </div>
</template>

<script>
export default {
  props: {
    values: {
      required: false,
      type: Array,
      default: () => ([]),
    },
    label: {
      required: true,
      type: String,
    },
    hintText: {
      required: false,
      type: String,
    },
    fieldName: {
      required: true,
      type: String,
    },
    emptyText: {
      required: true,
      type: String,
    },
    optionsEndpoint: {
      required: true,
      type: String,
    },
    visibleForRole: {
      required: true,
      type: String,
    },
    validationError: {
      required: false,
      type: String,
    },
  },

  data() {
    return {
      error: null,
      loading: false,
      projects: [],
      branches: [],
      role: '',
      options: [],
      model: [],
    };
  },

  mounted() {
    this.getRole();
    this.fillProjects();
    this.fillBranches();
    this.fetchOptions();
    this.model = [...this.values].map((item) => item - 0);
  },

  computed: {
    XCSRFToken: () => document.querySelector('meta[name="csrf-token"]').getAttribute("content"),
    roleMatches() {
      return this.role === this.visibleForRole;
    }
  },

  methods: {
    fillProjects() {
      this.projects = Array.from($('input[name*="project_ids"]:checked'))
          .map((el) => el.value - 0);
    },

    fillBranches() {
      this.branches = $('select[name*="user[branch_ids][]"]').val()
          .map((value) => value - 0);
    },

    getRole() {
      this.role = $('input[name*="user[role]"]:checked').val();
    },

    fetchOptions() {
      if (!this.roleMatches) {
        return;
      }

      if (!this.projects.length || !this.branches.length) {
        this.options = [];
        return;
      }

      const searchParams = new URLSearchParams();
      this.projects.forEach((id) => searchParams.append('project_ids[]', id));
      this.branches.forEach((id) => searchParams.append('branch_ids[]', id));
      const path = `${this.optionsEndpoint}?${searchParams}`;

      this.loading = true;
      this.error = null;

      this.$http.get(path, { headers: { 'X-CSRF-Token': this.XCSRFToken }})
        .then(({data}) => {
          this.options = data.options;
        }, () => {
          this.error = 'Something went wrong during request. Please try again.';
        }).finally(() => {
          this.loading = false;
        });
    },

    changeUserFields() {
      this.fillProjects();
      this.fillBranches();
      this.getRole();

      this.$nextTick(() => {
        this.fetchOptions();
      })
    }
  }
}
</script>
