<template>
  <div class="setting-section card card-bordered p-0 pt-3 pl-3 pb-2 mt-3">
    <h5>Input</h5>
    <div class="row">
      <label for="inputBackground" class="col-sm-4 col-form-label">Background Color</label>
      <chrome-picker v-model="settings.backgroundColor" @input="settings.backgroundColor = `rgba(${settings.backgroundColor.rgba.r}, ${settings.backgroundColor.rgba.g}, ${settings.backgroundColor.rgba.b},${settings.backgroundColor.rgba.a})`"></chrome-picker>
    </div>
    <div class="row mt-5">
      <label for="inputBorder" class="col-sm-4 col-form-label">Border Color</label>
      <chrome-picker v-model="settings.borderColor" @input="settings.borderColor = `rgba(${settings.borderColor.rgba.r}, ${settings.borderColor.rgba.g}, ${settings.borderColor.rgba.b},${settings.borderColor.rgba.a})`"></chrome-picker>
    </div>
    <div class="row mt-5">
      <label for="inputPlaceholder" class="col-sm-4 col-form-label">Placeholder Color</label>
      <chrome-picker v-model="settings.placeholderColor" @input="settings.placeholderColor = `rgba(${settings.placeholderColor.rgba.r}, ${settings.placeholderColor.rgba.g}, ${settings.placeholderColor.rgba.b},${settings.placeholderColor.rgba.a})`"></chrome-picker>
    </div>
  </div>
</template>

<script>
import { Chrome } from 'vue-color'

export default {
  name: 'inputSettings',
  props: ['settings'],
  components: { 'chrome-picker' : Chrome }
}
</script>

<style scoped>
  .vc-chrome{
    width: 60%;
    display: inherit;
  }

  .vc-chrome-saturation-wrap{
    padding-bottom: 40%;
  }
</style>
