const labels = {
  CODE128: 'Code 128 (11-digit UPC + VAN19)',
  IABWithCheckDigit: 'IAB (full UPC + VAN19)'
};

const getLabel = (label) => {
  return labels[label] || label;
}

export default labels;

export {
  labels,
  getLabel,
}
